/* eslint-disable */
import React, { lazy, ReactHTML, ReactHTMLElement, useEffect } from "react";
import Page from "../components/Page";
// import ReactHlsPlayer from "react-hls-player";
import { IonButton } from "@ionic/react";

type TestProps = {};

// lazy import IonButton from "@ionic/react"
const Test = ({}: TestProps) => {
  let url =
    "https://api.put.io/v2/files/915767659/hls/media.m3u8?oauth_token=SXFPP5YJ6OCATVLCZDSA&max_subtitle_count=-1&original=0";
  // const playerRef = React.useRef<any>();
  // var player = videojs("video");

  // function playVideo() {
  //   playerRef.current.play();
  // }

  // function pauseVideo() {
  //   playerRef.current.pause();
  // }

  // function toggleControls() {
  //   playerRef.current.controls = !playerRef.current.controls;
  // }

  return (
    <Page title="Test" center={true} showTabbar={true} isNavButtons={true}>
      {/* <video
        style={{
          width: "100%",
          height: 300,
        }}
        // ref={playerRef}
        id="video"
        // src={url}
        className="video-js vjs-default-skin"
        controls
        preload="auto"
        data-setup="{}"
        // onLoad={() => console.log("onLoad")}
      >
        <source
          src={`https://api.put.io/v2/files/915767659/hls/media.m3u8?oauth_token=SXFPP5YJ6OCATVLCZDSA&max_subtitle_count=-1&original=0`}
          type="application/x-mpegURL"
        />
      </video> */}

      <IonButton onClick={() => {}}>Play</IonButton>
    </Page>
  );
};

export default Test;
