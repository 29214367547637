/* eslint-disable */
import { ShowsStyles } from "../app/interfaces";
export function showCardStyle(
  ShowCardStyle: string,
  large?: boolean
): ShowsStyles {
  return ShowCardStyle === "grid"
    ? GridStyle(large)
    : ShowCardStyle === "card"
    ? CardStyle()
    : DefaultStyle();
}


function CardStyle(): ShowsStyles {
  return {
    _showCard: {
      // textAlign: "center",
      // display: "inline-block",
      width: "inherit",
      height: "max-content",
    },
    get showCard() {
      return this._showCard;
    },
    set showCard(value) {
      this._showCard = value;
    },
    _showTitle: {
      padding: ".5vh",
      opacity: 1,
    },
    get showTitle() {
      return this._showTitle;
    },
    set showTitle(value) {
      this._showTitle = value;
    },
    _showImg: {
      // position: 'absolute',
      top: 0,
      borderRadius: 10,
      maxHeight: "35vh",
      height: "26vmax",
      width: "100%",
      objectFit: "cover",
    },
    get showImg() {
      return this._showImg;
    },
    set showImg(value) {
      this._showImg = value;
    },
    _showDate: {
      // position: "relative",
    },
    get showDate() {
      return this._showDate;
    },
    set showDate(value) {
      this._showDate = value;
    },
    _showRating: {
      // position: "relative",
      color: "gold",
    },
    get showRating() {
      return this._showRating;
    },
    set showRating(value) {
      this._showRating = value;
    },
  };
}

function GridStyle(large: boolean | undefined): ShowsStyles {
  return {
    _showCard: {
      display: "inline-block",
      textAlign: "center",
      marginBottom: "5px",
      marginTop: "10px",
      marginRight: "5px",
      marginLeft: "5px",
      borderRadius: 10,
    },
    get showCard() {
      return this._showCard;
    },
    set showCard(value) {
      this._showCard = value;
    },
    _showTitle: large
      ? {
          padding: ".5vh",
        }
      : {
          padding: ".5vh",
          width: "13vmax",
          clear: "both",
          whiteSpace: "nowrap",
          overflow: "hidden",
        },
    get showTitle() {
      return this._showTitle;
    },
    set showTitle(value) {
      this._showTitle = value;
    },
    _showImg: {
      borderRadius: 10,

      height: "20vmax",
      objectFit: "cover",
    },
    get showImg() {
      return this._showImg;
    },
    set showImg(value) {
      this._showImg = value;
    },
    _showDate: {
      fontSize: "7px",
    },
    get showDate() {
      return this._showDate;
    },
    set showDate(value) {
      this._showDate = value;
    },
    _showRating: {
      color: "gold",
    },
    get showRating() {
      return this._showRating;
    },
    set showRating(value) {
      this._showRating = value;
    },
  };
}

function DefaultStyle(): ShowsStyles {
	return {
	  _showCard: {},
	  get showCard() {
		return this._showCard;
	  },
	  set showCard(value) {
		this._showCard = value;
	  },
	  _showDate: {},
	  get showDate() {
		return this._showDate;
	  },
	  set showDate(value) {
		this._showDate = value;
	  },
	  _showImg: {},
	  get showImg() {
		return this._showImg;
	  },
	  set showImg(value) {
		this._showImg = value;
	  },
	  _showRating: {},
	  get showRating() {
		return this._showRating;
	  },
	  set showRating(value) {
		this._showRating = value;
	  },
	};
  }
  