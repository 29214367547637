import { Page } from "../components";

type Props = {};

const Privacy = (props: Props) => {
	return (
		<Page title="Privacy Policy">
			<div
				style={{
					padding: "2%",
				}}
			>
				<h1>Privacy Policy</h1>

				<p>
					<strong>Effective Date:</strong> August 1, 2019
				</p>

				<h2>Welcome to the iWatchTV App</h2>
				<p>
					Your privacy is important to us, and we are committed to protecting
					your personal information. This Privacy Policy explains how we
					collect, use, disclose, and safeguard your information when you use
					the iWatchTV App (the "App"). By accessing or using the App, you
					consent to the practices described in this Privacy Policy.
				</p>

				<h3>1. Information We Collect</h3>
				<p>
					<strong>a. Information You Provide Voluntarily:</strong> When you use
					our App, you may provide us with certain information voluntarily. This
					may include your name, email address, and any comments or feedback you
					submit to us.
				</p>
				<p>
					<strong>b. Automatically Collected Information:</strong> We may
					automatically collect certain information about your device and usage
					of the App. This may include your IP address, browser type, operating
					system, and usage patterns.
				</p>

				<h3>2. Use of Information</h3>
				<p>We use the information we collect for the following purposes:</p>
				<p>
					<strong>a. To Provide and Improve Our Services:</strong> We may use
					your information to provide, maintain, and improve our App, including
					delivering the content you access and customizing your experience.
				</p>
				<p>
					<strong>b. To Communicate with You:</strong> We may use your email
					address to respond to your inquiries, provide updates about our
					services, and send you important notices.
				</p>
				<p>
					<strong>c. To Monitor and Analyze Usage:</strong> We may use
					automatically collected information to monitor and analyze how users
					interact with our App, including to identify trends, gather
					demographic information, and track user activity.
				</p>

				<h3>3. Disclosure of Information</h3>
				<p>We may share your information in the following circumstances:</p>
				<p>
					<strong>a. With Third-Party Service Providers:</strong> We may share
					your information with third-party service providers who assist us in
					operating the App, such as hosting, advertising, and analytics
					services.
				</p>
				<p>
					<strong>b. With Advertisers:</strong> We may share aggregated and
					non-identifying information with advertisers and advertising networks
					for the purpose of delivering targeted advertisements on our App.
				</p>
				<p>
					<strong>c. For Legal Purposes:</strong> We may disclose your
					information when required by law, in response to legal process, or to
					protect our rights, privacy, safety, or property, or that of our users
					or the public.
				</p>

				<h3>4. Cookies and Tracking Technologies</h3>
				<p>
					We use cookies and similar tracking technologies to collect and store
					information about your use of our App. You can manage your cookie
					preferences through your browser settings.
				</p>

				<h3>5. Third-Party Links</h3>
				<p>
					Our App may contain links to third-party websites and services that
					are not owned or controlled by us. We are not responsible for the
					privacy practices of these third parties. We encourage you to review
					the privacy policies of these third-party websites and services.
				</p>

				<h3>6. Security</h3>
				<p>
					We take reasonable measures to protect your information from
					unauthorized access, use, disclosure, or alteration. However, please
					be aware that no method of transmitting information over the internet
					or storing data is completely secure.
				</p>

				<h3>7. Changes to this Privacy Policy</h3>
				<p>
					We may update this Privacy Policy from time to time to reflect changes
					in our practices or for other operational, legal, or regulatory
					reasons. We will notify you of any material changes by posting the
					updated Privacy Policy on our App.
				</p>

				<h3>8. Contact Us</h3>
				<p>
					If you have any questions or concerns about this Privacy Policy or our
					data practices, please contact us at <a href="mailto:thrifts-ingot0j@icloud.com" type="email">thrifts-ingot0j@icloud.com.</a>.
				</p>

				<p>
					By using our App, you agree to the terms and conditions of this
					Privacy Policy. If you do not agree with any aspect of this Privacy
					Policy, please do not use our App.
				</p>

				<p>Thank you for using the iWatchTV App!</p>
			</div>
			{/* <div style={{ height: 20 }} />
			<div
				style={{
					padding: "2%",
				}}
			>
				<h2>Privacy Policy</h2>
				<br />
				<h6>Effective Date: August 1, 2019</h6>
				<p>
					Welcome to the iWatchTV App (the "App"). Your privacy is important to
					us, and we are committed to protecting your personal information. This
					Privacy Policy explains how we collect, use, disclose, and safeguard
					your information when you use our App. By accessing or using our App,
					you consent to the practices described in this Privacy Policy.
				</p>
				<br />
				<h3>1. Information We Collect</h3>
				<p>
					a. <b>Information You Provide Voluntarily:</b> When you use our App,
					you may provide us with certain information voluntarily. This may
					include your name, email address, and any comments or feedback you
					submit to us.
				</p>
				<p>
					b. <b>Automatically Collected Information:</b> We may automatically
					collect certain information about your device and usage of the App.
					This may include your IP address, browser type, operating system, and
					usage patterns.
				</p>
				<br />
				<h3>2. Use of Information</h3>
				<p>We use the information we collect for the following purposes:</p>
				<p>
					a. <b>To Provide and Improve Our Services:</b> We may use your
					information to provide, maintain, and improve our App, including
					delivering the content you access and customizing your experience.
				</p>
				<p>
					b. <b>To Communicate with You:</b> We may use your email address to
					respond to your inquiries, provide updates about our services, and
					send you important notices.
				</p>
				<p>
					c. <b>To Monitor and Analyze Usage:</b> We may use automatically
					collected information to monitor and analyze how users interact with
					our App, including to identify trends, gather demographic information,
					and track user activity.
				</p>
				<br />
				<h3>3. Disclosure of Information</h3>
				<p>We may share your information in the following circumstances:</p>
				<p>
					a.<b> With Third-Party Service Providers:</b> We may share your
					information with third-party service providers who assist us in
					operating the App, such as hosting, advertising, and analytics
					services.
				</p>
				<p>
					b. <b>With Advertisers:</b> We may share aggregated and
					non-identifying information with advertisers and advertising networks
					for the purpose of delivering targeted advertisements on our App.
				</p>
				<p>
					c. <b>For Legal Purposes:</b> We may disclose your information when
					required by law, in response to legal process, or to protect our
					rights, privacy, safety, or property, or that of our users or the
					public.
				</p>
				<br />
				<h3>4. Cookies and Tracking Technologies</h3>
				<p>
					We use cookies and similar tracking technologies to collect and store
					information about your use of our App. You can manage your cookie
					preferences through your browser settings.
				</p>
				<br />
				<h3>5. Third-Party Links</h3>
				<p>
					Our App may contain links to third-party websites and services that
					are not owned or controlled by us. We are not responsible for the
					privacy practices of these third parties. We encourage you to review
					the privacy policies of these third-party websites and services.
				</p>
				<br />
				<h3>6. Security</h3>
				<p>
					We take reasonable measures to protect your information from
					unauthorized access, use, disclosure, or alteration. However, please
					be aware that no method of transmitting information over the internet
					or storing data is completely secure.
				</p>
				<br />
				<h3>7. Changes to this Privacy Policy</h3>
				<p>
					We may update this Privacy Policy from time to time to reflect changes
					in our practices or for other operational, legal, or regulatory
					reasons. We will notify you of any material changes by posting the
					updated Privacy Policy on our App.
				</p>
				<br />
				<h3>8. Contact Us</h3>
				<p>
					If you have any questions or concerns about this Privacy Policy or our
					data practices, please contact us at [Contact Email]. By using our
					App, you agree to the terms and conditions of this Privacy Policy. If
					you do not agree with any aspect of this Privacy Policy, please do not
					use our App. Thank you for using the iWatchTV App!
				</p>
			</div> */}
		</Page>
	);
};

export default Privacy;
