import { Device } from "@capacitor/device";
import { useEffect, useState } from "react";

export function getDeviceInfo(setDeviceInfo?: any) {
  get();
  async function get() {
    const info = await Device.getInfo();
    setDeviceInfo(info);
  }
}

export const useDeviceInfo = () => {
  const [deviceInfo, setDeviceInfo] = useState<any>();
  useEffect(() => {
    getDeviceInfo(setDeviceInfo);
  }, []);
  return deviceInfo;
};
