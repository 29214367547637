import { Plugins } from "@capacitor/core";
import "@capacitor-community/http";
import { useEffect, useState } from "react";

export const getMovieDetails = async (id: string) => {
  const { Http } = Plugins;
  const ret = await Http.request({
    method: "GET",
    url: `https://api.themoviedb.org/3/movie/${id}?api_key=046fdb0d753c6903e673934705cb553f&append_to_response=videos,images&include_image_language=en,null`,
  });
  return ret;
};

export const useMovieDetails = (id: string) => {
  const [info, setInfo] = useState<any>();
  useEffect(() => {
    getMovieDetails(id).then((res) => {
      setInfo(res.data);
    });
  }, [id]);
  return info;
};
