import React, { CSSProperties } from "react";

export function loginPageStyles(): { h1?: React.CSSProperties | undefined; img?: React.CSSProperties | undefined; logInContainer: CSSProperties; } {
  return {
    h1: {
      fontFamily: "FredokaOne",
    },
    img: {
      width: "40%",
    },
    logInContainer: {
      padding: "0",
      height: "100%",
      textAlign: "center",
    },
  };
}
