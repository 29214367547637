/* eslint-disable */
import { IonIcon, IonCardSubtitle, IonCardTitle, IonCard } from "@ionic/react";
import React, { Suspense, useEffect, useState } from "react";
import { star } from "ionicons/icons";
import { getJson, randomNum } from "../../util";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ScrollContainer from "react-indiana-drag-scroll";
import { thumbSliderStyle } from "../../styles/thumbSliderStyle";
import { Spinner } from "../Spinner";

const Thumbnails: any = ({
  sliderData,
  url,
  path,
}: ThumbnailsProps): JSX.Element => {
  var image;
  let sliderStyle = thumbSliderStyle();
  return (
    <Suspense fallback={<Spinner width="auto" height="auto" />}>
      {sliderData !== undefined
        ? sliderData.map(
            ({
              title,
              name,
              id,
              vote_average,
              first_air_date,
              release_date,
              poster_path,
              backdrop_path,
              profile_path,
              popularity,
            }) => {
              poster_path === null && backdrop_path !== null
                ? (image = backdrop_path || profile_path)
                : poster_path === null && backdrop_path === null
                ? (image = null)
                : (image = poster_path || profile_path);
              return image !== null ? (
                <ThumbCard
                  key={id}
                  id={id}
                  sliderStyle={sliderStyle}
                  path={path}
                  image={image}
                  release_date={release_date || first_air_date}
                  vote_average={vote_average}
                />
              ) : null;
            }
          )
        : null}
    </Suspense>
  );
};

const ThumbSlider: React.FC<ThumbSliderProps> = ({
  url,
  path,
  title,
  sessionName,
  width,
  height,
}) => {
  const [sliderData, setSliderData] = useState([]);
  const [movies, setMovies] = React.useState([]);
  // const history = useHistory();
  let itemName = sessionName + "_results";

  useEffect(() => {
    if (url.includes("&page=1")) {
      getJson(url)
        .then((res) => res)
        .then((res: any) => {
          setMovies(res.results);
        });
    } else {
      if (url.includes("_credits?")) {
        getJson(url)
          .then((res) => res)
          .then((res: any) => {
            setMovies(movies?.concat(res.cast));
            // localStorage.setItem(itemName, JSON.stringify(res.cast));
          });
      } else {
        getJson(url)
          .then((res) => res)
          .then((res: any) => {
            setMovies(movies?.concat(res.results));
            // localStorage.setItem(itemName, JSON.stringify(res.results));
          });
      }
    }
  }, [url]);

  useEffect(() => {
    setSliderData(movies);
  }, [movies]);

  // const history = useHistory();
  return url !== "" ? (
    sliderData.length > 0 ? (
      <div style={{ marginTop: "5vh" }}>
        <IonCardSubtitle class="ion-padding" style={{}}>
          {title}
        </IonCardSubtitle>
        <ScrollContainer
          style={{ width: width, height: height }}
          className="ion-padding"
        >
          <div
            className="scroller"
            style={{ marginTop: "-5vh", display: "-webkit-inline-flex" }}
          >
            <Thumbnails
              url={url}
              path={path}
              sessionName={sessionName}
              sliderData={sliderData}
            />
          </div>
        </ScrollContainer>
      </div>
    ) : null
  ) : null;
};

export default ThumbSlider;

const ThumbCard = ({
  id,
  sliderStyle,
  path,
  image,
  release_date,
  vote_average,
}: ThumbCardProps): JSX.Element | null => {
  const history = useHistory();
  return (
    <div key={id + randomNum(1, 9999)} style={{ height: "max-content" }}>
      <div className="thumbCard" style={sliderStyle?.thumbCard}>
        <IonCard
          className="clickable_hover"
          style={{ textDecoration: "none" /* marginTop: 15  */ }}
          onClick={() => {
            history.push(`${path}/${id}`);
          }}
        >
          <img
            style={sliderStyle?.thumbImg}
            className="thumbImg"
            src={"https://image.tmdb.org/t/p/w300/" + image}
            alt={id}
          />
          <div className="thumbDiv" style={sliderStyle?.thumbDiv}>
            <h6 className="thumbDate" style={sliderStyle?.thumbDate}>
              {moment(release_date).format("MMM DD, YYYY")}
            </h6>

            <h6 className="thumbVote" style={sliderStyle?.thumbVote}>
              <IonIcon icon={star} />
              {vote_average}
            </h6>
          </div>
        </IonCard>
      </div>
    </div>
  );
};
interface ThumbSliderProps {
  url: string;
  path?: string;
  title?: string;
  sessionName?: any;
  width?: string;
  height?: string;
}
interface ThumbCardProps {
  id: any;
  sliderStyle?: {
    thumbCard?: React.CSSProperties | undefined;
    thumbImg?: React.CSSProperties | undefined;
    thumbDiv?: React.CSSProperties | undefined;
    thumbDate?: React.CSSProperties | undefined;
    thumbVote?: React.CSSProperties | undefined;
  };
  // history: any;
  path: any;
  image: any;
  release_date: any;
  vote_average: any;
}
interface ThumbnailsProps {
  url?: any;
  path?: any;
  // sessionName?: any;
  sliderData?: any[];
}
