import { FetchProps } from '../app/interfaces';
// Must import the package once to make sure the web support initializes
import '@capacitor-community/http';

import { Plugins } from '@capacitor/core';

// Example of a GET request
export const fetchData = async ({ url, items, more, setResults, setError }: FetchProps) =>
	Plugins.Http
		.request({
			method: 'GET',
			url: url
		})
		.then((res: any) => res.data)
		.then(async (res: any) => {
			if (res || res.results || res.results.length > 0) {
				let state = more ? [ ...items, ...res.results ] : [ ...res.results ];
				setResults(state);
				setError(false);
			} else {
				setError(true);
			}
		})
		.catch((err: any) => console.error(err));

export const doGetPromise = async ({ url, items, setResults, setError, more }: FetchProps) =>
	Plugins.Http
		.request({
			method: 'GET',
			url: url
		})
		.then((res: any) => res.data)
		.then(async (res: any) => {
			if (res) {
				return res;
			} else {
				return 'error';
			}
		})
		.catch((err: any) => {return err});

// export async function fetchData({
//   url,
//   items,
//   more,
//   setState1,
//   setState2,
// }: FetchProps) {
//   const res: Response = await fetch(url);
//   more
//     ? res
//         .json()
//         .then(async (res) => {
//           if (res && res.results && res.results.length > 0) {
//             setState2([...items, ...res.results]);
//             setState1(false);
//           } else {
//             setState1(true);
//           }
//         })
//         .catch((err) => console.error(err))
//     : res.json().then(async (res) => {
//         if (res && res.results && res.results.length > 0) {
//           setState2([...res.results]);
//           setState1(false);
//         } else {
//           setState1(true);
//         }
//       });
// }
