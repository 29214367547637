/* eslint-disable */
import React, { RefObject, useEffect, useMemo, useRef, useState } from "react";
import {
	IonButton,
	IonCard,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonGrid,
	IonIcon,
	IonRow,
	IonSlide,
	IonSlides,
} from "@ionic/react";
import { Formatter as formatter } from "../util/useFormatter";
import moment from "moment";
import { movieInfoStyle } from "../styles/movieInfoStyle";
import VoteStars from "./VoteStars";
import { createMarkUp } from "../util/createMarkUp";
import { showInfoStyle } from "../styles/showInfoStyle";
import ScrollContainer from "react-indiana-drag-scroll";
import { filterOutline } from "ionicons/icons";
// import { getJson } from "../util";
// import { arrowDownCircleOutline } from "ionicons/icons";
// import DownloadLink from "react-download-link";
// import * as ClearBit from "company-domain-search-api";
// import { Clappr } from "./exports";

// import ReactHlsPlayer from "react-hls-player";
import {
	MediaCommunitySkin,
	MediaOutlet,
	MediaPlayer,
	MediaPoster,
} from "@vidstack/react";

interface SubtitleType {
	date: string;
	downloads: number;
	encoding: string;
	filename: string;
	format: string;
	fps: number;
	id: string;
	lang: string;
	langcode: string;
	score: number;
	url: string;
	utf8: string;
	vtt: string;
}
type SubtitleList = {
	ar: SubtitleType[];
	bg: SubtitleType[];
	bn: SubtitleType[];
	bs: SubtitleType[];
	cs: SubtitleType[];
	da: SubtitleType[];
	de: SubtitleType[];
	el: SubtitleType[];
	en: SubtitleType[];
	es: SubtitleType[];
	et: SubtitleType[];
	fa: SubtitleType[];
	fi: SubtitleType[];
	fr: SubtitleType[];
	gl: SubtitleType[];
	he: SubtitleType[];
	hi: SubtitleType[];
	hr: SubtitleType[];
	hu: SubtitleType[];
	id: SubtitleType[];
	is: SubtitleType[];
	it: SubtitleType[];
	ja: SubtitleType[];
	ko: SubtitleType[];
	lt: SubtitleType[];
	lv: SubtitleType[];
	mk: SubtitleType[];
	ml: SubtitleType[];
	ms: SubtitleType[];
	nl: SubtitleType[];
	no: SubtitleType[];
	pb: SubtitleType[];
	pl: SubtitleType[];
	pt: SubtitleType[];
	ro: SubtitleType[];
	ru: SubtitleType[];
	sk: SubtitleType[];
	sl: SubtitleType[];
	sq: SubtitleType[];
	sr: SubtitleType[];
	sv: SubtitleType[];
	th: SubtitleType[];
	tr: SubtitleType[];
	uk: SubtitleType[];
	vi: SubtitleType[];
	ze: SubtitleType[];
	zh: SubtitleType[];
	zt: SubtitleType[];
};
const MovieInfoandPlayers = ({
	details,
	extraObjects,
	movieSrc,
	trailerSrc,
	trailers,
	watchProviders,
}: {
	details: any;
	extraObjects?: any;
	movieSrc?: string;
	trailerSrc?: string;
	setMovieSrc?: any;
	trailers?: any;
	watchProviders?: any;
}) => {
	let release_date = moment(details?.release_date).format("MM-DD-YYYY");
	var today: any = new Date();
	var dd = String(today.getDate()).padStart(2, "0");
	var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
	var yyyy = today.getFullYear();
	today = mm + "-" + dd + "-" + yyyy;
	let released = today > release_date;

	useEffect(() => {
		// console.log(trailers?.map((trailer: any) => trailer.key));
	}, [trailers]);

	let flatrate: any = watchProviders?.US ? watchProviders?.US.flatrate : [];
	let rent: any = watchProviders?.US ? watchProviders?.US.rent : [];
	let buy: any = watchProviders?.US ? watchProviders?.US.buy : [];

	// let link = watchProviders?.US.link && watchProviders?.US.link;

	// function fetchProvidersDomain(name: string): any {
	//   var result: { domain: string } = {
	//     domain: "",
	//   };
	//   getJson("http://localhost:5000/name2domain?companyName=" + name)
	//     .then((res) => res.data)
	//     .then((res) => {
	//       return (result.domain += res.domain);
	//     })
	//     .catch((err) => {
	//       return (result.domain += err);
	//     });
	//   return result;
	// }

	return (
		<div style={movieInfoStyle.movieInfo}>
			<ImagesNPlayers
				details={details}
				movieSrc={movieSrc}
				trailerSrc={trailerSrc}
				trailers={trailers}
				extraObjects={extraObjects}
			/>
			<InfoText details={details} />

			<div style={{ textAlign: "left" }}>
				{released && flatrate?.length > 0 && (
					<IonCardSubtitle className="ion-padding">Streaming</IonCardSubtitle>
				)}
				<ScrollContainer>
					<div style={{ display: "inline-flex" }}>
						{released && flatrate?.length > 0
							? flatrate?.map((provider: any, i: number) => {
									return (
										<IonCard
											// href={domain}
											// href={link}
											key={i}
											style={{ width: "80px", height: "max-content" }}
										>
											<img
												style={{ width: "80px", height: "80px" }}
												src={
													"https://image.tmdb.org/t/p/original/" +
													provider.logo_path
												}
											/>

											<IonCardSubtitle className="ion-text-center">
												{provider.provider_name}
											</IonCardSubtitle>
										</IonCard>
									);
							  })
							: null}
					</div>
				</ScrollContainer>
			</div>

			<div style={{ textAlign: "left" }}>
				{released && rent?.length > 0 && (
					<IonCardSubtitle className="ion-padding">Rent</IonCardSubtitle>
				)}
				<ScrollContainer>
					<div style={{ display: "inline-flex" }}>
						{released && rent?.length > 0
							? rent?.map((provider: any, i: number) => {
									return (
										<IonCard
											// href={link}
											key={i}
											style={{ width: "80px", height: "max-content" }}
										>
											<img
												style={{ width: "80px", height: "80px" }}
												src={
													"https://image.tmdb.org/t/p/original/" +
													provider.logo_path
												}
											/>

											<IonCardSubtitle className="ion-text-center">
												{provider.provider_name}
											</IonCardSubtitle>
										</IonCard>
									);
							  })
							: null}
					</div>
				</ScrollContainer>
			</div>

			<div style={{ textAlign: "left" }}>
				{released && buy?.length > 0 && (
					<IonCardSubtitle className="ion-padding">Buy</IonCardSubtitle>
				)}
				<ScrollContainer>
					<div style={{ display: "inline-flex" }}>
						{released && buy?.length > 0
							? buy?.map((provider: any, i: number) => {
									return (
										<IonCard
											// href={link}
											key={i}
											style={{ width: "80px", height: "max-content" }}
										>
											<img
												style={{ width: "80px", height: "80px" }}
												src={
													"https://image.tmdb.org/t/p/original/" +
													provider.logo_path
												}
											/>

											<IonCardSubtitle className="ion-text-center">
												{provider.provider_name}
											</IonCardSubtitle>
										</IonCard>
									);
							  })
							: null}
					</div>
				</ScrollContainer>
			</div>
		</div>
	);
};

export default MovieInfoandPlayers;

const VideoPlayer: React.FC<{
	src?: string;
	poster?: string;
	tracks?: any[];
}> = ({ src, poster, tracks }) => {
	return (
		<></>
		// <MediaPlayer
		// 	title="Sprite Fight"
		// 	src={src}
		// 	poster={poster}
		// 	thumbnails="https://media-files.vidstack.io/sprite-fight/thumbnails.vtt"
		// 	aspectRatio={16 / 9}
		// 	crossorigin=""
		// >
		// 	<MediaOutlet>
		// 		<MediaPoster alt="Girl walks into sprite gnomes around her friend on a campfire in danger!" />
		// 		{tracks?.map((track: any) => (
		// 			<track
		// 				src={track.src}
		// 				key={track.src}
		// 				label={track.label}
		// 				kind="subtitles"
		// 				srcLang={track.srcLang}
		// 				default
		// 			/>
		// 		))}
		// 	</MediaOutlet>
		// 	<MediaCommunitySkin />
		// </MediaPlayer>
	);
};

function ImagesNPlayers({
	extraObjects,
	details,
	trailerSrc,
	movieSrc,
	trailers,
}: {
	details: any;
	extraObjects: any;
	trailerSrc: any;
	movieSrc: string | undefined;
	trailers: any;
}) {
	let { flipPlayer, playerSelected, setOpenLoading } = extraObjects;
	// let trailer = `<iframe src="${trailerSrc}" allowfullscreen style="width:100%;height:40vh;max-height: 40vh;border-radius: 10px"; frameborder="0"  />`;

	useEffect(() => {}, [details?.id]);

	const videoPlayerRef: RefObject<HTMLVideoElement> =
		useRef<HTMLVideoElement>(null);

	useEffect(() => {
		if (videoPlayerRef.current) {
			videoPlayerRef.current?.addEventListener("loadeddata", (e) => {
				setOpenLoading(false);
			});
		}
		() => {
			videoPlayerRef.current?.removeEventListener("playing", (e) => {
				// console.log(e);
			});
		};
	}, []);

	// const [subtitlesArray, setSubtitlesArray] = useState<SubtitleType[]>([]);
	// const [subtitles, setSubtitles] = useState<any[]>([]);

	// const subtitlesUrl: any = "https://iwatchtvapi.deno.dev/subtitles";
	// useEffect(() => {
	// 	if (details?.imdb_id) {
	// 		fetch(subtitlesUrl, {
	// 			method: "POST",
	// 			headers: {
	// 				"Content-Type": "application/json",
	// 			},
	// 			body: JSON.stringify({
	// 				tmdb_id: details?.id,
	// 			}),
	// 		})
	// 			.then((res) => res.json())
	// 			.then((res) => {
	// 				console.log(res);
	// 				setSubtitles(res);
	// 			})
	// 			.catch((err) => console.log(err));
	// 	}
	// }, [details?.imdb_id]);

	// useEffect(() => {
	// 	console.log(subtitlesArray);
	// }, [subtitlesArray]);

	// const getSubtitles = () =>
	// 	Object.values(subtitles as any).map((item: any) => {
	// 		return item[0];
	// 	});

	// useEffect(() => {
	// 	if (subtitles.length > 0) {
	// 		console.log(subtitles);
	// 	}
	// }, [subtitles]);

	// useEffect(() => {
	// 	videoPlayerRef.current?.addEventListener("loadeddata", (e) => {
	// 		console.log(e);
	// 	});
	// 	() => {};
	// }, []);

	const draggableSize = useRef();

	useEffect(() => {
		console.log(draggableSize);
	}, [draggableSize]);

	const [videoHeight, setVideoHeight] = useState<any>("60vh");

	return (
		<div style={movieInfoStyle.BackDropBox}>
			{!flipPlayer ? (
				<div>
					<img
						style={movieInfoStyle.movieBackDrop}
						src={
							details?.backdrop_path === null
								? "https://www.kubookstore.com/sca-dev-vinson/img/no_image_available.jpeg"
								: "https://image.tmdb.org/t/p/original/" +
								  details?.backdrop_path
						}
						alt=""
					/>
					<img
						style={movieInfoStyle.moviePoster}
						src={
							details?.poster_path === null
								? "https://pngimage.net/wp-content/uploads/2018/06/image-not-available-png-8.png"
								: "https://image.tmdb.org/t/p/w400/" + details?.poster_path
						}
						alt=""
					/>
				</div>
			) : playerSelected === "trailer" ? (
				// <div dangerouslySetInnerHTML={createMarkUp(trailer)} />

				<IonSlides
					options={{
						slidesPerView: 2,
					}}
					style={{ borderRadius: "10px", height: "40vh", width: "100%" }}
				>
					{trailers?.map((src: any, index: number) => {
						return (
							<IonSlide style={{ borderRadius: "15px" }} key={index}>
								<div
									style={{ borderRadius: "15px", width: "100%" }}
									dangerouslySetInnerHTML={createMarkUp(
										`<iframe src="${src}" allowfullscreen style="width:100%;height:40vh;max-height: 40vh;border-radius: 10px"; frameborder="0"  />`
									)}
								></div>
							</IonSlide>
						);
					})}
				</IonSlides>
			) : (
				// <VideoPlayer
				// 	src={movieSrc}
				// 	poster={
				// 		"https://image.tmdb.org/t/p/original/" + details?.backdrop_path
				// 	}
				// 	tracks={subtitlesArray}
				// />
				<>
					<video
						ref={videoPlayerRef}
						// src={movieSrc}
						id="player-wrapper player"
						preload="auto"
						crossOrigin="anonymous"
						className="video-player video-js vjs-default-skin"
						autoPlay={true}
						playsInline
						controls
						onPlaying={() => {
							setOpenLoading(false);
						}}
						onPlay={() => {
							setOpenLoading(false);
						}}
						poster={
							"https://image.tmdb.org/t/p/original/" + details?.backdrop_path
						}
						style={{ borderRadius: "10px", height: videoHeight, width: "100%" }}
					>
						<track
							key={details?.id}
							label={"English"}
							srcLang={"eng"}
							kind="captions"
							src={`https://iwatchtvapi.deno.dev/subtitles?tmdb_id=${details?.id}&language=eng`}
							default={true}
							defaultChecked={true}
						/>
						<track
							key={details?.id}
							label={"Español"}
							srcLang={"esp"}
							kind="captions"
							src={`https://iwatchtvapi.deno.dev/subtitles?tmdb_id=${details?.id}&language=esp`}
						/>
						<track
							key={details?.id}
							label={"Portuguese"}
							srcLang={"por"}
							kind="captions"
							src={`https://iwatchtvapi.deno.dev/subtitles?tmdb_id=${details?.id}&language=por`}
						/>
						<track
							key={details?.id}
							label={"Italiano"}
							srcLang={"ita"}
							kind="captions"
							src={`https://iwatchtvapi.deno.dev/subtitles?tmdb_id=${details?.id}&language=ita`}
						/>
						{/* {subtitles?.map((sub: any) => (
							<track
								key={sub.url}
								label={sub?.language}
								kind="captions"
								srcLang={sub.language_code}
								src={sub.url + "?format=webvtt"}
								default={sub.language_code === "eng"}
								defaultChecked={sub.language_code === "eng"}
							></track>
						))} */}

						<source title="en" lang="en" src={movieSrc}></source>
					</video>
					<DraggableDiv
						callback={(e: any) => {
							const position = e.clientY;
							if (position !== 0) setVideoHeight(position);
						}}
					/>
				</>
			)}
		</div>
	);
}

const DraggableDiv = ({ callback }: { callback: any }) => {
	const handleDrag = (event: any) => {
		callback(event);
	};
	const handleDragEnd = (event: any) => {
		callback(event);
	};
	return (
		<div
			className="draggable"
			style={{
				width: "100vw",
				cursor: "grab",
				height: "10px",
				opacity: 1,
				backgroundColor: "transparent",
			}}
			draggable={true}
			onDrag={handleDrag}
			onDragEnd={handleDragEnd}
		>
			<IonIcon size="large" icon={filterOutline} />
		</div>
	);
};

function InfoText({ details }: { details: any }) {
	return (
		<IonGrid>
			<IonRow>
				<IonCol size="12">
					<IonCardTitle style={{}}>{details?.title}</IonCardTitle>
				</IonCol>
				<IonCol size="12">
					<b style={{}}>Rating: </b>
					{details?.vote_average} <VoteStars vote={details?.vote_average} />
				</IonCol>
				<IonCol size="12">
					<b style={{}}> </b>
					{details?.tagline}
				</IonCol>
				<IonCol size="12" sizeMd="6" sizeLg="2">
					<b style={{ display: "block" }}>IMDB: </b>
					{details?.imdb_id}
				</IonCol>
				<IonCol sizeXs="6" sizeMd="6" sizeLg="2">
					<b style={{ display: "block" }}>Status:</b>
					{details?.status}
				</IonCol>
				<IonCol sizeXs="6" sizeMd="3" sizeLg="2">
					<b style={{ display: "block" }}>Budget:</b>
					{formatter.format(details?.budget)}
				</IonCol>
				<IonCol sizeXs="6" sizeMd="3" sizeLg="2">
					<b style={{ display: "block" }}>Length:</b>
					{details?.runtime} mins
				</IonCol>
				<IonCol sizeXs="6" sizeMd="3" sizeLg="2">
					<b style={{ display: "block" }}>Revenue:</b>
					{formatter.format(details?.revenue)}
				</IonCol>
				<IonCol size="12" sizeMd="3" sizeLg="2">
					<b style={{ display: "block" }}>Release Date:</b>
					{moment(details?.release_date).format("MM-DD-YYYY")}
				</IonCol>
				<IonCol size="12" style={{ padding: "20px" }}>
					<div className="Overview" style={showInfoStyle.InfoOverview}>
						<p style={{}} className="ion-padding-top">
							{details?.overview}
						</p>
					</div>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
}

const DefaultText = ({ details }: { details: any }) => {
	return (
		<div style={movieInfoStyle.movieInfoText}>
			<IonCardTitle style={{}}>{details?.title}</IonCardTitle>
			<p style={{}}>
				<b style={{}}>IMDB: </b>
				{details?.imdb_id}
			</p>
			<p style={{}}>
				<b style={{}}>Release Date:</b>
				{moment(details?.release_date).format("MM-DD-YYYY")}
			</p>
			<p style={{}}>
				<b style={{}}>Status:</b>
				{details?.status}
			</p>
			<p style={{}}>
				<b style={{}}>Length:</b>
				{details?.runtime} mins
			</p>
			<p style={{}}>
				<b style={{}}>Budget:</b>
				{formatter.format(details?.budget)}
			</p>
			<p style={{}}>
				<b style={{}}>Revenue:</b>
				{formatter.format(details?.revenue)}
			</p>
			<p>
				<b style={{}}>Rating:</b>
				{details?.vote_average}
				<VoteStars vote={details?.vote_average} />
			</p>
			<div className="movieOverview" style={movieInfoStyle.movieInfoOverview}>
				<p style={{}} className="ion-padding-horizontal">
					{details?.overview}
				</p>
			</div>
		</div>
	);
};
