import React from 'react'
import { IonIcon } from "@ionic/react";
import { star, starHalf, starOutline } from "ionicons/icons";

interface VoteStarsProps {
  vote: number
}

const VoteStars = ({ vote }:VoteStarsProps): JSX.Element => (
  <>
    <IonIcon
      style={{ color: "gold" }}
      icon={vote >= 1 ? star : vote >= 0.5 ? starHalf : starOutline}
    ></IonIcon>
    <IonIcon
      style={{ color: "gold" }}
      icon={vote >= 2 ? star : vote >= 1.5 ? starHalf : starOutline}
    ></IonIcon>
    <IonIcon
      style={{ color: "gold" }}
      icon={vote >= 3 ? star : vote >= 2.5 ? starHalf : starOutline}
    ></IonIcon>
    <IonIcon
      style={{ color: "gold" }}
      icon={vote >= 4 ? star : vote >= 3.5 ? starHalf : starOutline}
    ></IonIcon>
    <IonIcon
      style={{ color: "gold" }}
      icon={vote >= 5 ? star : vote >= 4.5 ? starHalf : starOutline}
    ></IonIcon>
    <IonIcon
      style={{ color: "gold" }}
      icon={vote >= 6 ? star : vote >= 5.5 ? starHalf : starOutline}
    ></IonIcon>
    <IonIcon
      style={{ color: "gold" }}
      icon={vote >= 7 ? star : vote >= 6.5 ? starHalf : starOutline}
    ></IonIcon>
    <IonIcon
      style={{ color: "gold" }}
      icon={vote >= 8 ? star : vote >= 7.5 ? starHalf : starOutline}
    ></IonIcon>
    <IonIcon
      style={{ color: "gold" }}
      icon={vote >= 9 ? star : vote >= 8.5 ? starHalf : starOutline}
    ></IonIcon>
    <IonIcon
      style={{ color: "gold" }}
      icon={vote >= 10 ? star : vote >= 9.5 ? starHalf : starOutline}
    ></IonIcon>
  </>
);
export default VoteStars;
