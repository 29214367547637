import {
  YoutubeVideoPlayer,
  YoutubeVideoPlayerOriginal,
} from "@ionic-native/youtube-video-player";
import {
  VideoPlayer,
  VideoOptions,
  VideoPlayerOriginal,
} from "@ionic-native/video-player";

import { CSSProperties } from "react";

export function openYoutubeVideo(key: string, iframSize?: CSSProperties) {
  let youtube: YoutubeVideoPlayerOriginal = YoutubeVideoPlayer;
  youtube.openVideo(key);
}

export function openVideo(url: string) {
  let options: VideoOptions = {
    volume: 1.0,
  };
  let videoPlayer: VideoPlayerOriginal = VideoPlayer;
  videoPlayer.play(url, options);
}
