/* eslint-disable */
import React, { CSSProperties, useEffect, useState } from "react";
import {
	IonCard,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonGrid,
	IonPopover,
	IonRow,
} from "@ionic/react";
import moment from "moment";
import { showInfoStyle } from "../styles/showInfoStyle";
import VoteStars from "./VoteStars";
import { getlength } from "../util/getlength";
import { createMarkUp } from "../util/createMarkUp";
import ScrollContainer from "react-indiana-drag-scroll";

const ShowInfoandPlayers = ({
	details,
	extraObjects,
	showSrc,
	trailerSrc,
	setShowSrc,
	watchProviders,
}: {
	details: any;
	extraObjects?: any;
	showSrc?: string;
	trailerSrc?: string;
	setShowSrc?: any;
	watchProviders?: any;
}) => {
	// let API_KEY = process.env.REACT_APP_TMDB_API_KEY;
	let CLIENT_ID = process.env.REACT_APP_PUTIO_CLIENT_ID;
	// let TOKEN = process.env.REACT_APP_PUTIO_TOKEN;
	// const ads = AdMobFunctions(false);
	let youtubeVideo = trailerSrc;
	let { flipPlayer, playerSelected, setOpenLoading, setFlipPlayer } =
		extraObjects;
	let trailer = `<iframe src="${youtubeVideo}" allowfullscreen style="width:100%;height:40vmax;max-height: 40vh;border-radius: 15px" frameborder="0"  />`;

	const [seasons, setSeasons] = useState<any>();
	const [episodes, setEpsiodes] = useState<any[]>();
	const [seasonSelected, setSeasonSelected] = useState<any>({
		season_number: 1,
	});
	const [episodeSelected, setEpisodeSelected] = useState<number | undefined>(1);
	const [poster, setPoster] = useState<string>();

	let flatrate: any = watchProviders?.US ? watchProviders?.US.flatrate : [];
	let rent: any = watchProviders?.US ? watchProviders?.US.rent : [];
	let buy: any = watchProviders?.US ? watchProviders?.US.buy : [];

	useEffect(() => {
		// console.log(watchProviders);
	}, []);

	useEffect(() => {
		// setEpisodeSelected(1);
		const episodes: any[] = [];
		for (let i = 0; i < seasonSelected?.episode_count; i++) {
			episodes.push(i + 1);
		}
		setEpsiodes(episodes);
	}, [seasonSelected]);

	useEffect(() => {
		let seasons: any[] = details?.seasons;
		setSeasons(seasons);
		setPoster(details?.backdrop_path);
	}, [details]);

	useEffect(() => {
		if (seasonSelected?.id) {
			setPoster(seasonSelected?.poster_path);
			if (episodeSelected !== 0 && episodeSelected) {
				setOpenLoading(true);
				let Season =
					getlength(seasonSelected?.season_number) === 1
						? "0" + seasonSelected?.season_number
						: seasonSelected?.season_number;
				let Episode =
					getlength(episodeSelected) === 1
						? "0" + episodeSelected
						: episodeSelected;
				// let url = `https://us-central1-iwatchtv.cloudfunctions.net/streamNdownload/stream?client_id=6368&token=LD5UM2RPM7EG4YZ4X2XD&type=tv&tmdb_id=${details?.id}&s=${Season}&e=${Episode}`;
				// let url = `https://us-central1-iwatchtv.cloudfunctions.net/streamNdownload/stream?client_id=6368&token=LD5UM2RPM7EG4YZ4X2XD&type=tv&tmdb_id=${details?.id}&s=${Season}&e=${Episode}`;
				let url = `https://us-central1-iwatchtv.cloudfunctions.net/streamApi/stream`; //?type=tv&tmdb_id=${details?.id}&s=${Season}&e=${Episode}`;
				console.log(url);
				const headers = new Headers({
					"Content-Type": "application/json",
				});
				const body = JSON.stringify({
					type: "tv",
					tmdb_id: details?.id,
					s: Season,
					e: Episode,
					password: "xx00xx00",
				});

				Promise.all([
					fetch(url, {
						method: "POST",
						headers,
						body,
					}),
				])
					.then((res) => res[0].json())
					.then((res) => {
						console.log(res[0]);
						setShowSrc(res[0]?.url);
					})
					.catch((err) => console.log(err));
			}
		}
	}, [episodeSelected]);

	useEffect(() => {
		// if (seasonSelected) setSeasonSelected("");
		if (showSrc) setShowSrc(undefined);
		if (flipPlayer) setFlipPlayer(!flipPlayer);
		if (episodeSelected) setEpisodeSelected(undefined);
	}, [location.pathname]);

	let backdrop_image =
		"https://image.tmdb.org/t/p/original/" + details?.backdrop_path;
	const style: {
		backdrop: CSSProperties;
		poster: CSSProperties;
		posterCol: CSSProperties;
	} = {
		backdrop: {
			width: "100%",
			height: "35vmax",
			borderRadius: "10px",
			backgroundImage: `url(${backdrop_image})`,
			backgroundRepeat: "no-repeat",
			backgroundPosition: "center top",
			backgroundSize: "cover",
			marginBottom: "50px",
		},
		poster: {
			borderRadius: "10px",
			width: "auto",
			height: "23vmax",
		},
		posterCol: {
			marginTop: 50,
		},
	};
	// const [color, setColor] = useState<string>();
	return (
		<div style={showInfoStyle.Info}>
			<div style={showInfoStyle.BackDropBox}>
				{!flipPlayer ? (
					<>
						<img
							style={showInfoStyle.BackDrop}
							src={
								details?.backdrop_path === null
									? "https://www.kubookstore.com/sca-dev-vinson/img/no_image_available.jpeg"
									: "https://image.tmdb.org/t/p/original/" +
									  details?.backdrop_path
							}
							alt=""
						/>
						<img
							style={showInfoStyle.Poster}
							src={
								details?.poster_path === null
									? "https://pngimage.net/wp-content/uploads/2018/06/image-not-available-png-8.png"
									: "https://image.tmdb.org/t/p/w400/" + details?.poster_path
							}
							alt=""
						/>
					</>
				) : playerSelected === "trailer" ? (
					<div dangerouslySetInnerHTML={createMarkUp(trailer)} />
				) : (
					<>
						<video
							ref={extraObjects?.videoRef}
							id="video-player"
							playsInline
							controls
							autoPlay
							onPlay={() => setOpenLoading(false)}
							src={showSrc}
							poster={"https://image.tmdb.org/t/p/original/" + poster}
							style={{ borderRadius: "10px", height: "40vh", width: "100%" }}
						/>

						<div>
							<h3>Seasons</h3>
						</div>
						<div
							style={{
								width: "100%",
								display: "flex",
								flexWrap: "wrap",
								overflowX: "scroll",
								scrollBehavior: "smooth",
								padding: "10px",
							}}
						>
							{seasons
								?.filter((s: any) => (s.season_number !== 0 ? s : null))
								.map((season: any, index: number) => {
									let backdrop_image =
										"https://image.tmdb.org/t/p/original/" +
										season?.poster_path;
									return (
										<React.Fragment key={index}>
											<div
												className="no-scrollbar"
												style={{
													flex: "1 1 100px",
													height: "50px",
													minWidth: "100px",
													maxWidth: "100px",
													width: "100px",
													margin: "5px",
													justifyContent: "center",
													alignItems: "center",
													display: "flex",
													cursor: "pointer",
													borderRadius: "10px",
													border: backdrop_image ? "1px solid" : "none",
													backgroundImage: `url(${backdrop_image})`,
													opacity:
														seasonSelected.season_number ===
														season.season_number
															? 1
															: 0.5,
													backgroundBlendMode: "overlay",
													backgroundSize: "cover",
													backgroundPosition: "center",
													backgroundRepeat: "no-repeat",
													fontSize: "1.2rem",
													fontWeight: "bold",
													textAlign: "center",
													color: "white",
												}}
												id={season?.season_number}
												onClick={() => setSeasonSelected(season)}
												key={index}
											>
												{season?.season_number}
											</div>
											<IonPopover
												className=""
												showBackdrop={false}
												translucent={true}
												trigger={season?.season_number}
												// isOpen={
												// 	seasonSelected.season_number === season.season_number
												// }
											>
												<div
													style={{
														width: "100%",
														display: "block",
														flexWrap: "wrap",
														overflowX: "scroll",
														scrollBehavior: "smooth",
														padding: "10px",
													}}
												>
													{episodes?.map((episode: any, index: number) => {
														return (
															<React.Fragment key={index}>
																<div
																	className="no-scrollbar"
																	style={{
																		flex: "1 1 100px",
																		height: "50px",
																		maxWidth: "100px",
																		width: "100px",
																		minWidth: "100px",
																		margin: "5px",
																		justifyContent: "center",
																		alignItems: "center",
																		border: "1px solid",
																		display: "flex",
																		cursor: "pointer",
																		borderRadius: "10px",
																		opacity:
																			episodeSelected === episode ? 1 : 0.5,
																		fontSize: "1.2rem",
																		fontWeight: "bold",
																		textAlign: "center",
																		// color: "white",
																	}}
																	onClick={() => setEpisodeSelected(episode)}
																	key={index}
																>
																	{episode}
																	{/* {season?.name} */}
																</div>
															</React.Fragment>
														);
													})}
												</div>
											</IonPopover>
											{/* <div
												className={`popup-episode-slider ${
													seasonSelected.season_number === season.season_number
														? "hide"
														: "hide"
												}`}
											>
												
											</div> */}
										</React.Fragment>
									);
								})}
						</div>
						{/* {episodes ? (
							<div>
								<h4>Episodes</h4>
							</div>
						) : null} */}

						{/* <IonGrid style={{ width: "100%", height: "auto" }}>
              <div>
                <IonRow>
                  <IonCol size="1.5" />
                  <IonCol size="4">
                    <IonCardSubtitle>Seasons</IonCardSubtitle>
                    <IonSelect
                      // onClick={() => ads.hideBanner()}
                      interfaceOptions={{
                        header: "Seasons",
                        subHeader: "Select a season",
                      }}
                      interface="action-sheet"
                      placeholder="Select One"
                      onIonChange={(e) => {
                        // ads.resumeBanner();
                        setSeasonSelected(e.detail.value);
                      }}
                      value={seasonSelected}
                    >
                      {seasons?.map((season: any, index: number) => (
                        <IonSelectOption value={season} key={index}>
                          {season?.name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonCol>
                  <IonCol size="1" />
                  <IonCol size="4">
                    <IonCardSubtitle>Episodes</IonCardSubtitle>
                    <IonSelect
                      // onClick={() => ads.hideBanner()}
                      interfaceOptions={{
                        header: "Seasons",
                        subHeader: "Select a season",
                      }}
                      value={episodeSelected}
                      interface="action-sheet"
                      placeholder="Select One"
                      onIonChange={(e) => {
                        // ads.resumeBanner();
                        setEpisodeSelected(e.detail.value);
                      }}
                    >
                      {episodes?.map((episode: any, index: number) => (
                        <IonSelectOption value={episode} key={index}>
                          Episode {episode}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonCol>
                  <IonCol size="1.5" />
                </IonRow>
              </div>
            </IonGrid> */}
					</>
				)}
			</div>
			<InfoText details={details} />

			<div style={{ textAlign: "left" }}>
				{flatrate?.length > 0 && (
					<IonCardSubtitle className="ion-padding">Streaming</IonCardSubtitle>
				)}
				<ScrollContainer>
					<div style={{ display: "inline-flex" }}>
						{flatrate?.length > 0
							? flatrate?.map((provider: any, i: number) => {
									return (
										<IonCard
											// href={domain}
											// href={link}
											key={i}
											style={{ width: "80px", height: "max-content" }}
										>
											<img
												style={{ width: "80px", height: "80px" }}
												src={
													"https://image.tmdb.org/t/p/original/" +
													provider.logo_path
												}
											/>

											<IonCardSubtitle className="ion-text-center">
												{provider.provider_name}
											</IonCardSubtitle>
										</IonCard>
									);
							  })
							: null}
					</div>
				</ScrollContainer>
			</div>

			<div style={{ textAlign: "left" }}>
				{rent?.length > 0 && (
					<IonCardSubtitle className="ion-padding">Rent</IonCardSubtitle>
				)}
				<ScrollContainer>
					<div style={{ display: "inline-flex" }}>
						{rent?.length > 0
							? rent?.map((provider: any, i: number) => {
									return (
										<IonCard
											// href={link}
											key={i}
											style={{ width: "80px", height: "max-content" }}
										>
											<img
												style={{ width: "80px", height: "80px" }}
												src={
													"https://image.tmdb.org/t/p/original/" +
													provider.logo_path
												}
											/>

											<IonCardSubtitle className="ion-text-center">
												{provider.provider_name}
											</IonCardSubtitle>
										</IonCard>
									);
							  })
							: null}
					</div>
				</ScrollContainer>
			</div>

			<div style={{ textAlign: "left" }}>
				{buy?.length > 0 && (
					<IonCardSubtitle className="ion-padding">Buy</IonCardSubtitle>
				)}
				<ScrollContainer>
					<div style={{ display: "inline-flex" }}>
						{buy?.length > 0
							? buy?.map((provider: any, i: number) => {
									return (
										<IonCard
											// href={link}
											key={i}
											style={{ width: "80px", height: "max-content" }}
										>
											<img
												style={{ width: "80px", height: "80px" }}
												src={
													"https://image.tmdb.org/t/p/original/" +
													provider.logo_path
												}
											/>

											<IonCardSubtitle className="ion-text-center">
												{provider.provider_name}
											</IonCardSubtitle>
										</IonCard>
									);
							  })
							: null}
					</div>
				</ScrollContainer>
			</div>
		</div>
	);
};

export default ShowInfoandPlayers;

function InfoText({ details }: { details: any }) {
	return (
		<IonGrid>
			<IonRow>
				<IonCol size="12">
					<IonCardTitle style={{}}>
						{details?.title || details?.name}
					</IonCardTitle>
				</IonCol>
				<IonCol size="12">
					<b style={{}}> </b>
					{details?.tagline}
				</IonCol>

				<IonCol size="6">
					<b style={{ display: "block" }}>First Episode: </b>
					{moment(details?.first_air_date).format("MMM DD, YYYY")}
				</IonCol>
				<IonCol size="6">
					<b style={{ display: "block" }}>Last Episode: </b>
					{moment(details?.last_air_date).format("MMM DD, YYYY")}
				</IonCol>
				<IonCol size="6">
					<b>Seasons: </b>
					{details?.number_of_seasons}
				</IonCol>
				<IonCol size="6">
					<b>Episodes: </b>
					{details?.number_of_episodes}
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol size="12">
					<b style={{}}>Status: </b>
					{details?.status}
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol size="12">
					<b style={{}}>Rating: </b>
					{details?.vote_average}
					<VoteStars vote={details?.vote_average} />
				</IonCol>
				<IonCol size="12">
					<div className="Overview" style={showInfoStyle.InfoOverview}>
						<p style={{}} className="ion-padding-top">
							{details?.overview}
						</p>
					</div>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
}
