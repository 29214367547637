import { IonLoading } from "@ionic/react";
import React from "react";

export function Loader({
  openLoading,
  setOpenLoading,
  time,
}: {
  openLoading: boolean;
  setOpenLoading: React.Dispatch<React.SetStateAction<boolean>>;
  time?: number;
}) {
  return (
    <IonLoading
      cssClass="video-player-loading"
      isOpen={openLoading}
      onDidDismiss={() => setOpenLoading(false)}
      message={"Please wait..."}
      // backdropDismiss={true}
      duration={time ? time : 25000}
    />
  );
}
