/* eslint-disable */
import React, { ReactElement } from 'react'
import { Page } from '../components'

interface SupportProps {
    
}

function Support({}: SupportProps): ReactElement {
    return (
        <Page title="Support" center={true} showTabbar={true} isNavButtons={true}>
            
            
        </Page>
    )
}

export default Support
