import { Plugins } from "@capacitor/core";
import "@capacitor-community/http";

export const getCast = async (id: string, type?: 'tv'|'movie'|string) => {
  let api_key = process.env.REACT_APP_TMDB_API_KEY
  const { Http } = Plugins;
  const ret = await Http.request({
    method: "GET",
    url: `https://api.themoviedb.org/3/${type}/${id}/credits?api_key=${api_key}&append_to_response=videos,images&include_image_language=en,null`,
  });
  return ret;
};

