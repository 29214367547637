/* eslint-disable */
import { IonFab, IonFabButton, IonIcon, isPlatform } from "@ionic/react";
import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps, useLocation, useParams } from "react-router";
import {
	ProductionCompanies,
	Page,
	ThumbSlider,
	Loader,
	YoutubeFAB,
	CastAvatarSlider,
	ShowInfoandPlayers,
	Cast,
} from "../components";
import {
	getJson,
	getShowDetails,
	doGetPromise,
	getWatchProviders,
} from "../util";
import { play, close } from "ionicons/icons";

const ShowDetails: React.FC<RouteComponentProps> = (): JSX.Element => {
	let API_KEY = process.env.REACT_APP_TMDB_API_KEY;
	const { id } = useParams<{ id: string }>();
	const [show, setShowDetails] = useState<any>();
	const [companies, setCompanies] = useState([] as Array<any>);
	const [genres, setGenres] = useState([] as Array<any>);
	const [src, setSrc] = useState<string>();
	const [openLoading, setOpenLoading] = useState<boolean>(false);
	const [playerSelected, setPlayerSelected] = useState<string>("");
	const [quality, setQuality] = useState<string>("");
	const [flipPlayer, setFlipPlayer] = useState<boolean>(false);
	const [key, setKey] = useState<string>("");
	const [error, setError] = useState<any>(null);
	const [youtubeVideo, setYoutubeVideo] = useState<string>("");
	const [providers, setProviders] = useState<any>();

	const getTrailerUrl = `https://api.themoviedb.org/3/tv/${id}/videos?api_key=${API_KEY}&language=en&append_to_response=videos,images&include_image_language=en,null`;

	const title = show?.name;

	// const stream_720 = `https://us-central1-iwatchtv.cloudfunctions.net/stream/stream?client_id=${CLIENT_ID}&token=${'SXFPP5YJ6OCATVLCZDSA'}&type=tv&tmdb_id=${id}&s=720p`;
	// const stream_1080 = `https://us-central1-iwatchtv.cloudfunctions.net/stream/stream?client_id=${CLIENT_ID}&token=${'SXFPP5YJ6OCATVLCZDSA'}&type=tv&tmdb_id=${id}&s=1080p`;
	// const stream_720 = `https://us-central1-iwatchtv.cloudfun/tions.net/streaming?client_id=${CLIENT_ID}&token=${'SXFPP5YJ6OCATVLCZDSA'}&type=tv&tmdb_id=${id}&s=720p`;
	// const stream_1080 = `https://us-central1-iwatchtv.cloudfunc/tions.net/streaming?client_id=${CLIENT_ID}&token=${'SXFPP5YJ6OCATVLCZDSA'}&type=tv&tmdb_id=${id}&s=1080p`;

	const isPlayingVideo = flipPlayer && playerSelected === "movie" && src !== "";
	const isPlayingTrailer = flipPlayer && playerSelected === "trailer";

	// const play720 = () => setSDSource(stream_720, setSrc, setOpenLoading);
	// const play1080 = () => setHDSource(stream_1080, setSrc, setOpenLoading);
	const playerCleanUp = () => setQuality("");

	const isMobile = useMemo(
		() => isPlatform("ipad") || isPlatform("ios") || isPlatform("android"),
		[]
	);

	// initial
	useEffect(() => {
		const delayedCloseLoader = setTimeout(() => setOpenLoading(false), 500);
		() => clearTimeout(delayedCloseLoader);
	}, []);

	//  movie information retrieval
	useEffect(() => {
		getShowDetails(id).then((r) => (r.status === 200 ? setShow(r) : null));
		getWatchProviders(id, "tv").then((r) => {
			// console.log(r);
			r.status === 200 ? setProviders(r.data.results) : null;
		});
	}, [id]);

	// detect quality of video selected by user
	// useEffect(
	//   () =>
	//     // quality === "SD" ? play720() : quality === "HD" ? play1080() : () => {},
	//   [quality]
	// );

	// detect video source open modal
	useEffect(() => {
		if (src) {
			setOpenLoading(true);
			setFlipPlayer(true);
			setPlayerSelected("movie");
		}
	}, [src]);

	useEffect(() => {
		getYoutubeTrailers({ url: getTrailerUrl, setKey });
	}, [id]);

	useEffect(() => {
		if (key) {
			setYoutubeVideo(
				`https://www.youtube.com/embed/${key}?autoplay=1&mute=0&playsinline=1&fullscreen=1`
			);
		}
	}, [key]);

	function setShow({ data }: { data: any }) {
		setCompanies(data.production_companies);
		setGenres(data.genres);
		setShowDetails(data);
	}

	async function getYoutubeTrailers({
		url,
		setKey,
	}: {
		url: string;
		setKey?: any;
	}) {
		await doGetPromise({
			url: url,
		})
			.then((res) => {
				if (res.results[0]) {
					return res.results[0];
				}
			})
			.then((res) => {
				setKey(res.key);
			})
			.catch((err) => setError(err));
	}

	useEffect(() => {
		setOpenLoading(true);
		setTimeout(() => {
			setOpenLoading(false);
		}, 1000);
	}, []);

	const videoRef = React.createRef<HTMLVideoElement>();

	useEffect(() => {
		if (src !== undefined) {
			videoRef.current?.load();
      setOpenLoading(false);
		}
	}, [src]);

	return (
		<Page title={title} searchBar={false} backButton={true} isNavButtons={true}>
			<Loader
				openLoading={openLoading}
				setOpenLoading={setOpenLoading}
				// time={12000}
			/>
			<div>
				<ShowInfoandPlayers
					details={show}
					showSrc={src}
					setShowSrc={setSrc}
					trailerSrc={youtubeVideo}
					extraObjects={{
						flipPlayer,
						youtubeVideo,
						playerSelected,
						setOpenLoading,
						setFlipPlayer,
						videoRef,
					}}
					watchProviders={providers}
				/>
				{/* {collection.length > 0 ? (
            <CollectionList Collection={collection} />
          ) : null} */}
				<CastAvatarSlider
					size={150}
					label="Cast"
					type="cast"
					url={`https://api.themoviedb.org/3/tv/${id}/credits?api_key=${API_KEY}&append_to_response=videos,images&include_image_language=en,null`}
				/>
				<CastAvatarSlider
					size={120}
					label="Crew"
					type="crew"
					url={`https://api.themoviedb.org/3/tv/${id}/credits?api_key=${API_KEY}&append_to_response=videos,images&include_image_language=en,null`}
				/>
				<ThumbSlider
					sessionName={id + "similar"}
					title="Similar"
					path="/tv"
					url={`https://api.themoviedb.org/3/tv/${id}/similar?api_key=${API_KEY}&language=en-US&page=1`}
				/>
				<ThumbSlider
					sessionName={id + "recommendations"}
					title="Recommendations"
					path="/tv"
					url={`https://api.themoviedb.org/3/tv/${id}/recommendations?api_key=${API_KEY}&language=en-US&page=1`}
				/>
				{companies.length > 0 ? <ProductionCompanies data={companies} /> : null}
			</div>
			{!isPlayingTrailer && !isPlayingVideo && (
				<IonFab style={{ top: 200 }} vertical="top" horizontal="end">
					<IonFabButton
						onClick={() => {
							setPlayerSelected("movie");
							setFlipPlayer(!flipPlayer);
						}}
					>
						<IonIcon size="large" icon={flipPlayer ? close : play} />
					</IonFabButton>
				</IonFab>
			)}
			<YoutubeFAB
				playerConfig={{
					flipPlayer,
					setFlipPlayer,
					setPlayerSelected,
					playerSelected,
				}}
				fabStyle={{
					top: 120,
				}}
				iframStyle={{ width: "100%", height: "40vh" }}
				position={{ vertical: "top", horizontal: "end" }}
			/>
			{/* <ModalVideoPlayer
        title={title}
        id={id}
        src={src}
        openModal={openModal}
        setOpenModal={setOpenModal}
        setOpenLoading={setOpenLoading}
        otherCallbacks={playerCleanUp}
        onDismiss={() => {}}
        onPresent={() => {}}
        extraObject={{ youtubeVideo, flipPlayer }}
      /> */}
		</Page>
	);
	function setHDSource(
		stream_1080: string,
		setSrc: React.Dispatch<React.SetStateAction<string | undefined>>,
		setOpenLoading: React.Dispatch<React.SetStateAction<boolean>>
	) {
		// setFlipPlayer(true);
		setOpenLoading(true);
		getJson(stream_1080)
			.then((r) => r)
			.then((r) => setSrc(r?.stream_url));
	}

	function setSDSource(
		stream_720: string,
		setSrc: React.Dispatch<React.SetStateAction<string | undefined>>,
		setOpenLoading: React.Dispatch<React.SetStateAction<boolean>>
	) {
		// setFlipPlayer(true);
		setOpenLoading(true);
		getJson(stream_720)
			.then((r) => r)
			.then((r) => setSrc(r?.stream_url));
	}
};

export default ShowDetails;
