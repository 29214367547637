import React, { FC, ReactElement } from "react";
import { IonIcon, IonLabel, IonTabBar, IonTabButton } from "@ionic/react";
import { filmOutline, easelOutline, homeOutline, chatbubbleEllipsesSharp, chatbubbleEllipsesOutline } from "ionicons/icons";

interface TabbarProps {
	showTabbar?: boolean;
}

const Tabbar: FC<TabbarProps> = ({ showTabbar }): ReactElement => {
	return showTabbar ? (
		<IonTabBar className="MobileTabbar ion-no-border" translucent slot="bottom">
			<IonTabButton className="ion-no-border" tab="home" href="/home">
				<IonIcon icon={homeOutline} />
				<IonLabel>Home</IonLabel>
			</IonTabButton>
			<IonTabButton className="ion-no-border" tab="movies" href="/movies">
				<IonIcon icon={filmOutline} />
				<IonLabel>Movies</IonLabel>
			</IonTabButton>
			<IonTabButton className="ion-no-border" tab="shows" href="/shows">
				<IonIcon icon={easelOutline} />
				<IonLabel>TV Shows</IonLabel>
			</IonTabButton>
			<IonTabButton className="ion-no-border" tab="contact" href="/contact">
				<IonIcon icon={chatbubbleEllipsesOutline} />
				<IonLabel>Contact Us</IonLabel>
			</IonTabButton>
		</IonTabBar>
	) : (
		<></>
	);
};
export default Tabbar;
