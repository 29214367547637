import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React, { FC } from "react";
import ThumbSlider from "../Sliders/ThumbSlider";

export const ModalVideoPlayer: FC<{
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  title: any;
  setOpenLoading: React.Dispatch<React.SetStateAction<boolean>>;
  src?: string | undefined;
  id: string;
  presentingElement?: any;
  onDismiss: () => void;
  onPresent: () => void;
  otherCallbacks: (e?: any) => void | undefined;
  extraObject?: any;
}> = ({
  openModal,
  setOpenLoading,
  setOpenModal,
  id,
  src,
  title,
  presentingElement,
  otherCallbacks,
  extraObject,
  onDismiss,
  onPresent,
}): JSX.Element => {
  return (
    <IonModal
      backdropDismiss={false}
      keyboardClose={true}
      animated={true}
      isOpen={openModal}
      showBackdrop={true}
      className="video-player-modal"
      swipeToClose={false}
      presentingElement={presentingElement}
      onDidDismiss={() => {
        setOpenModal(false);
        otherCallbacks();
      }}
    >
      <IonHeader translucent={true} className="ion-no-border">
        <IonToolbar>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setOpenModal(false)}>
              <IonIcon size="large" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader
          translucent={true}
          collapse="condense"
          className="ion-no-border"
        >
          <IonToolbar>
            <IonTitle size="large">{title}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div
          style={{
            // border: '1px black solid',
            position: "relative",
            width: "100%",
            height: "30vh",
            top: "2vh",
          }}
        >
          <video
            playsInline
            controls
            autoPlay
            onPlay={() => setOpenLoading(false)}
            src={src}
            width="100%"
            height="100%"
            style={{ borderRadius: "10px" }}
          />
        </div>

        <ThumbSlider
          width="100%"
          // height="30vmax"
          sessionName={id + "more-like-this"}
          title="More like this..."
          path="/movie"
          url={`https://api.themoviedb.org/3/trending/movie/week?api_key=046fdb0d753c6903e673934705cb553f`}
        />
      </IonContent>
    </IonModal>
  );
};
