/* eslint-disable */
import React, { createContext, useEffect, useState } from "react";
import { Redirect, Route } from "react-router";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import { Routes } from "./routes";
import { getDeviceInfo, Firebase } from "../util";
// import * as fb from "firebase";
import "../styles";
import "../fonts";
import { StatusBar, Style } from "@capacitor/status-bar";
// import { signInPopup } from "../services/firebase";

export const hideStatusBar = async () => {
	await StatusBar.hide();
};

export const showStatusBar = async () => {
	await StatusBar.show();
};

export const toggleStatusBar = async (toggle: boolean) => {
	if (toggle) await showStatusBar();
	else await hideStatusBar();
};

export const toggleStatusBarStyleDark = async () => {
	await StatusBar.setStyle({
		style: darkModeMatchMedia() ? Style.Dark : Style.Light,
	});
};

export const setStatusBarStyleDark = async () => {
	await StatusBar.setStyle({ style: Style.Dark });
};

export const setStatusBarStyleLight = async () => {
	await StatusBar.setStyle({ style: Style.Light });
};

export function darkModeMatchMedia(): boolean {
	return window.matchMedia("(prefers-color-scheme: dark)").matches;
}

export function usePrefersColorScheme(): any {
	window
		.matchMedia("(prefers-color-scheme: dark)")
		.addListener(async (status) => {
			try {
				await StatusBar.setStyle({
					style: status.matches ? Style.Dark : Style.Light,
				});
			} catch (error) {
				console.log(error);
				console.error(error);
			}
		});
}

type TUser = {};

const App: React.FC<{}> = (): JSX.Element => {
	const onlineStatus = localStorage.getItem("isOnline");
	// const firebase = Firebase;
	const [user, setUser] = useState<TUser>();
	const [deviceInfo, setDeviceInfo] = useState<any>();
	const isOnline = onlineStatus === "online";
	usePrefersColorScheme();

	// signInPopup();

	const [appContext, setAppContext] = useState<{
		isOnline?: boolean;
		currentUser?: any;
		// firebase?: typeof Firebase;
		deviceInfo?: any | undefined;
	}>({
		// firebase: firebase,
		isOnline: user ? true : false,
		currentUser: user,
		deviceInfo: deviceInfo,
	});

	// useEffect(() => firebase().getUser(setUser), []);
	useEffect(() => getDeviceInfo(setDeviceInfo), []);

	const SetAppContext: any = setAppContext;

	useEffect(() => {
		if (user) {
			SetAppContext({
				currentUser: user,
				deviceInfo: deviceInfo,
				isOnline: user ? true : false,
				// firebase: firebase,
			});
			localStorage.setItem("isOnline", "online");
		}
	}, [user]);

	useEffect(() => {
		SetAppContext({
			currentUser: user,
			deviceInfo: deviceInfo,
			isOnline: user ? true : false,
			// firebase: firebase,
		});
	}, [deviceInfo]);

	useEffect(() => {
		if (!user) {
			localStorage.setItem("isOnline", "offline");
		}
		return () => {};
	}, [appContext.isOnline]);

	return (
		<IonApp>
			<AppContext.Provider value={appContext}>				<IonReactRouter>
					<IonRouterOutlet>
						{Routes.map(({ href, component, exact }, i) => (
							<Route
								key={i}
								path={href}
								component={/* !isOnline ? Login : */ component}
								exact={exact}
							/>
						))}
						<Route
							path="/"
							render={() => <Redirect to="/home" />}
							exact={true}
						/>
					</IonRouterOutlet>
				</IonReactRouter>
			</AppContext.Provider>
		</IonApp>
	);
};

export default App;

setupIonicReact({
	mode: "ios",
	swipeBackEnabled: true,
	scrollAssist: false,
	animated: false,
});

const AppContext = createContext({});
