/* eslint-disable */
import { useIonViewWillEnter, IonSpinner } from "@ionic/react";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import { RouteComponentProps, useLocation } from "react-router";
import { ShowCardStyleProps, ShowsStyles } from "../app/interfaces";
import { fetchData, generateYearsList, ScrollToTop } from "../util";
import { showCardStyle } from "../styles/showCardStyle";
import {
  Page,
  SortingToolbar,
  InfiniteScrolling,
  StyleChangeButtons,
  MoreShowCard,
  ShowCard,
} from "../components";

const Shows: React.FC<RouteComponentProps> = (props) => {
  const [items, setItems] = useState<string[]>([]);
  const location = useLocation();
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(
    false
  );
  let state: any = location.state;
  var count = 0;
  const [counter, setCounter] = useState(0);
  const yearList: any = useMemo(() => generateYearsList(2025, 1920), []);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("popularity.desc");
  const [year, setYear] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [genre, setGenre] = useState("");
  let api_key = process.env.REACT_APP_TMDB_API_KEY;

  let style: any = localStorage.getItem("MovieCardStyle") || "grid";
  const [ShowCardStyle, setShowCardStyle] = useState<ShowCardStyleProps>(style);

  const pages = (page: number) => (page > 0 ? "&page=" + page : "");
  const languages = (language: string | null) =>
    language !== "" ? "&language=" + language : "";
  const sorts = (sort: string) => (sort !== "" ? "&sort_by=" + sort : "");
  const realease_year = (year: string) =>
    year !== "" ? "&primary_release_year=" + year : "";
  const companies = (companyId: string) =>
    companyId !== "" ? "&with_companies=" + companyId : "";
  const genres = (genre: string) =>
    genre !== "" ? "&with_genres=" + genre : "";

  let url: string = `https://api.themoviedb.org/3/discover/tv?api_key=${api_key}${sorts(
    sort
  )}${pages(page)}${languages("en-US")}${realease_year(year)}${companies(
    companyId
  )}${genres(
    genre
  )}&append_to_response=videos,images&include_image_language=en,null`;
  const ShowPageStyle: ShowsStyles = useMemo(
    () => showCardStyle(ShowCardStyle),
    []
  );

  useIonViewWillEnter(() => {
    fetchShows();
  });

  useEffect(() => {
    if (page === 1) fetchShows();
    else fetchMoreShows();
  }, [page]);

  useEffect(() => {
    setPage(1);
    fetchShows();
  }, [sort, year, genre, companyId]);

  useEffect(() => {
    if (state?.id !== undefined && state.path.includes("/movie/"))
      setCompanyId(state?.id);
  }, [state]);

  async function fetchShows() {
    await fetchData({
      url: url,
      more: false,
      items: items,
      setError: setDisableInfiniteScroll,
      setResults: setItems,
    });
  }

  async function fetchMoreShows() {
    await fetchData({
      url: url,
      more: true,
      items: items,
      setError: setDisableInfiniteScroll,
      setResults: setItems,
    });
  }

  async function searchNext($event: CustomEvent<void>) {
    setPage(page + 1);
    ($event.target as HTMLIonInfiniteScrollElement).complete();
    setDisableInfiniteScroll(true);
  }

  function clearFilter() {
    setPage(1);
    setSort("popularity.desc");
    setYear("");
    setCompanyId("");
  }

  const isSHOWS = items.length > 0;

  useEffect(() => {
    setCounter(counter + 1);
    if (count === 6) {
      count = 0;
    }
  }, [count]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     ScrollToTop();
  //   }, 500);
  // }, []);

  return (
    <Page title="Shows" center={true} showTabbar={true} isNavButtons={true}>
      <SortingToolbar
        isListAvailable={isSHOWS}
        yearList={yearList}
        setSort={setSort}
        setYear={setYear}
        setCompanyId={setCompanyId}
        setGenre={setGenre}
        sort={sort}
        year={year}
        companyId={companyId}
        genre={genre}
        resetFunction={clearFilter}
        isSticky={false}
        buttons={
          <StyleChangeButtons
            storageKey="MovieCardStyle"
            setCardStyle={setShowCardStyle}
            CardStyle={ShowCardStyle}
          />
        }
      />

      <Suspense
        fallback={
          <div style={{ textAlign: "center" }}>
            <IonSpinner />
          </div>
        }
      >
        {items.flatMap(
          (
            {
              id,
              name,
              title,
              poster_path,
              release_date,
              vote_average,
              backdrop_path,
              overview,
              first_air_date,
            }: any,
            i: number
          ) => {
            if (count === 7) {
              count = 0;
            }
            count++;

            return poster_path !== null ? (
              (count === 1 || count === 8) && ShowCardStyle === "grid" ? (
                <ShowCard
                  ShowCardStyle={ShowCardStyle}
                  key={i}
                  style={showCardStyle(ShowCardStyle, true)}
                  i={i}
                  id={id}
                  overview={overview}
                  name={name}
                  backdrop_path={backdrop_path}
                  poster_path={backdrop_path}
                  first_air_date={first_air_date}
                  vote_average={vote_average}
                />
              ) : (
                <ShowCard
                  ShowCardStyle={ShowCardStyle}
                  key={i}
                  style={showCardStyle(ShowCardStyle)}
                  i={i}
                  id={id}
                  overview={overview}
                  name={name}
                  backdrop_path={backdrop_path}
                  poster_path={poster_path}
                  first_air_date={first_air_date}
                  vote_average={vote_average}
                />
              )
            ) : (
              backdrop_path !== null && (
                <ShowCard
                  ShowCardStyle={ShowCardStyle}
                  key={i}
                  style={showCardStyle(ShowCardStyle)}
                  i={i}
                  id={id}
                  overview={overview}
                  name={name}
                  backdrop_path={backdrop_path}
                  poster_path={backdrop_path}
                  first_air_date={first_air_date}
                  vote_average={vote_average}
                />
              )
            );
          }
        )}
      </Suspense>

      <div style={{ display: disableInfiniteScroll ? "none" : "auto" }}>
        <MoreShowCard page={page} setPage={setPage} style={ShowPageStyle} />
      </div>
      <InfiniteScrolling
        disableInfiniteScroll={disableInfiniteScroll}
        searchNext={searchNext}
      />
    </Page>
  );
};

export default Shows;
