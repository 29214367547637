/* eslint-disable */
import React, { CSSProperties, useContext } from "react";
import { IonIcon, IonText } from "@ionic/react";
import { useHistory } from "react-router";
import { Firebase } from "../../util";
import { logOutOutline } from "ionicons/icons";
const firebaseContext = Firebase();
const DesktopRouteButton = ({
  label,
  location,
  href,
  tab,
  icon,
}: DesktopRouteButtonProps): JSX.Element => {
  const history = useHistory();
  let style: { navButton?: CSSProperties } = {
    navButton: {
      minWidth: "inherit",
      flex: 1,
      direction: "ltr",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
    },
  };
  return (
    <div
      style={{
        cursor: "default",
        textDecoration: "none",
        fontSize: 16,
        paddingRight: 15,
        fontWeight: location.pathname === href ? 800 : 300,
      }}
      onClick={() => {
        if (href === "/login") {
          // if (firebaseContext.auth.currentUser) {
          //   // firebaseContext.doSignOut();
          // } else {
          //   history.push(href);
          // }
        } else {
          history.push(href || "/");
        }
      }}
    >
      {tab ? (
        <div style={style.navButton}>
          <IonIcon
            color={location.pathname === href ? "primary" : "dark"}
            style={{
              fontSize: location.pathname === href ? 21 : 18,
              paddingRight: 3,
            }}
            icon={icon}
          />
          <IonText
            color={location.pathname === href ? "primary" : "dark"}
            style={{ fontSize: location.pathname === href ? 17 : 15 }}
          >
            {/* {href === "/login"
              ? firebaseContext.auth().currentUser
                ? String("Log Out")
                : label
              : label} */}
          </IonText>
        </div>
      ) : (
        <IonIcon
          style={{ fontSize: 20 }}
          // icon={
          //   href === "/login"
          //     ? firebaseContext.auth.currentUser
          //       ? logOutOutline
          //       : icon
          //     : icon
          // }
        />
      )}
    </div>
  );
};

interface DesktopRouteButtonProps {
  location?: any;
  href?: string;
  tab?: string | undefined;
  icon?: string | undefined;
  label?: string;
}

export default DesktopRouteButton;
