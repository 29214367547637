import React from "react";
import moment from "moment";
import { IonCardSubtitle, IonCol, IonGrid, IonRow } from "@ionic/react";

interface PersonDetailsProps {
  personDetails: PersonDetailType;
}

const PersonInfo = ({ personDetails }: PersonDetailsProps) => {
  return (
    <IonGrid style={{ marginBottom: "2%" }}>
      <IonRow>
        <IonCol sizeLg="4" sizeMd="4" sizeSm="4" sizeXs="12">
          <h3 className="ion-padding">{personDetails?.name}</h3>
          <IonCardSubtitle>
            <>Popularity: </> {personDetails?.popularity}
          </IonCardSubtitle>
          <IonCardSubtitle>
            <b>Birthday: </b>
            {moment(personDetails?.birthday).format("MM-DD-YYYY")}
          </IonCardSubtitle>
          {personDetails?.deathday !== null ? (
            <IonCardSubtitle>
              <b>Deathday: </b>
              {moment(personDetails?.deathday).format("MM-DD-YYYY")}
            </IonCardSubtitle>
          ) : null}
          {personDetails?.place_of_birth !== null ? (
            <IonCardSubtitle>
              <b>Birth Place: </b>
              {personDetails?.place_of_birth}
            </IonCardSubtitle>
          ) : null}
        </IonCol>
        <IonCol sizeLg="8" sizeMd="8" sizeSm="8" sizeXs="12">
          <b>Biography</b>
          <div style={{ height: 10 }} />
          <div
            style={{
              width: "100%",
              height: "25vh",
              overflowY: "scroll",
              // marginBottom: '10%',
              paddingLeft: "5vw",
              paddingRight: "5vw",
            }}
          >
            <IonCardSubtitle>{personDetails?.biography}</IonCardSubtitle>
          </div>
        </IonCol>
      </IonRow>

      {personDetails?.biography !== "" ? (
        <div style={{ marginTop: "5%" }}></div>
      ) : null}
    </IonGrid>
  );
};

export default PersonInfo;

interface PersonDetailType {
  adult: boolean;
  also_known_as: string[];
  biography: string;
  birthday: string;
  deathday: string;
  gender: number;
  homepage: string;
  id: number;
  imdb_id: string;
  known_for_department: string;
  name: string;
  place_of_birth: string;
  popularity: number;
  profile_path: string;
  images: {
    profiles: [
      {
        aspect_ratio: number;
        file_path: string;
        height: number;
        iso_639_1: null;
        vote_average: number;
        vote_count: number;
        width: number;
      }
    ];
  };
}
