import { Plugins } from "@capacitor/core";
import "@capacitor-community/http";
import axios from "axios";

export const getJson = async (url: string) => {
  const { Http } = Plugins;
  // const ret2: any = await fetch(url)
  //   .then((res) => res.json())
  //   .then((json) => json);
  const ret: any = await Http.request({
    method: "GET",
    url: url,
  }).then((res: any) => res.data);
  return ret as any;
};

export const GetJson = async (url: string) => {
  // create axios instance 
  const response: any = axios.get(url, {
    timeout: 1000,
    headers: {
      "Content-Type": "application/json",
    },
  });
  response.then((res: any) => {
    return res.data;
  });
};

