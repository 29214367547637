/* eslint-disable */
import React, { CSSProperties, useEffect, useState } from "react";
import { getJson } from "../../util";
import ScrollContainer from "react-indiana-drag-scroll";
import { useHistory } from "react-router";
import { IonCardSubtitle } from "@ionic/react";

export const CastAvatarSlider = ({
  url,
  label,
  size,
  type,
}: {
  url: string;
  label: string;
  size: number;
  type: "crew" | "cast";
}) => {
  const history = useHistory();
  const [cast, setCast] = useState<any[]>([]);
  useEffect(() => {
    getJson(url)
      .then((res) => {
        if (type === "cast") {
          setCast(res?.cast || res?.results);
        } else {
          setCast(res?.crew);
        }
      })
      .catch((err) => console.log(err));
  }, [url]);

  useEffect(() => {
    // console.log(cast);
  }, [cast]);

  let style: {
    imageContainer: CSSProperties;
    image: CSSProperties;
    castSlider: CSSProperties;
    mainSlider: CSSProperties;
  } = {
    castSlider: { display: "inline-flex", padding: 5 },
    imageContainer: {
      width: size,
      height: size,
      borderRadius: "50%",
      objectFit: "cover",
      padding: 5,
    },
    image: {
      width: size,
      height: size,
      borderRadius: "50%",
      objectFit: "cover",
      padding: 5,
    },
    mainSlider: {
      // marginTop: 50,
      textAlign: "center",
    },
  };

  return (
    <div style={style.mainSlider}>
      {cast?.length > 0 ? (
        <IonCardSubtitle className="ion-padding">{label}</IonCardSubtitle>
      ) : null}
      <ScrollContainer vertical={false} horizontal={true}>
        <div style={style.castSlider}>
          {cast?.map((person, index) => {
            let i = index - 1;
            return cast[i]?.id !== person?.id ? (
              <div className="clickable_hover" key={index}>
                <div
                  style={style.imageContainer}
                  onClick={() => history.push("/person/" + person?.id)}
                >
                  <img
                    style={style.image}
                    src={
                      person?.profile_path
                        ? "https://image.tmdb.org/t/p/w200/" +
                          person?.profile_path
                        : "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0a/Gnome-stock_person.svg/1024px-Gnome-stock_person.svg.png"
                    }
                  />
                </div>
                <h1>
                  <IonCardSubtitle style={{fontSize: '15px'}}>{person?.name}</IonCardSubtitle>
                </h1>
                <h1>
                  <IonCardSubtitle style={{fontSize: '10px', color: '#6f6f6f' }}>{person?.character}</IonCardSubtitle>
                </h1>
              </div>
            ) : null;
          })}
        </div>
      </ScrollContainer>
    </div>
  );
};
