/* eslint-disable */
import { IonContent, IonPage } from "@ionic/react";
import React, {
	CSSProperties,
	FC,
	ReactNode,
	useEffect,
	useRef,
	useState,
} from "react";
import { useParams } from "react-router";
import { Loader, SecondToolbar, Tabbar, Toolbar } from ".";
import { MessageModal } from "./MessageModal";
// import AdMobFunctions from "../services/AdMob";
interface PageProps {
	title: string;
	children?: ReactNode;
	center?: boolean;
	showTabbar?: boolean;
	searchBar?: boolean;
	backButton?: boolean;
	isNavButtons?: boolean;
	scrollY?: boolean;
	scrollX?: boolean;
	contentOnly?: boolean;
}

const Page: FC<PageProps> = ({
	title,
	center,
	children,
	showTabbar,
	searchBar,
	backButton,
	isNavButtons,
	scrollY,
	scrollX,
	contentOnly,
}): JSX.Element => {
	let style: { content: CSSProperties } = {
		content: {
			textAlign: center ? "center" : "initial",
		},
	};
	const [openLoading, setOpenLoading] = useState(true);
	const contentRef = useRef<HTMLIonContentElement | null>(null);
	const { id } = useParams<{ id: string }>();
	// const ads = AdMobFunctions(false);
	const scrollToTop = () => {
		contentRef.current && contentRef.current.scrollToTop();
		// console.log("Scrolled");
	};

	useEffect(() => {
		// console.log(document.location.pathname);
		if (
			document.location.pathname === "/home" ||
			document.location.pathname.includes("/movie/") ||
			document.location.pathname.includes("/tv/") ||
			document.location.pathname.includes("/person/")
		) {
			// console.log(id);
			scrollToTop();
		}
	}, [id]);

	// useEffect(() => ads.showBanner(), []);

	useEffect(() => {
		// console.log(document.location.pathname);
		if (
			document.location.pathname.includes("/movie/") ||
			document.location.pathname.includes("/tv/") ||
			document.location.pathname.includes("/person/")
		) {
			// ads.showInterstitial();
		}
	}, []);

	return (
		<IonPage>
			{/* <MessageModal
				open={true}
				closeButton={false}
				title="Website Status"
				message="We recently experienced a DDOS attack and we are currently working on a fix. Please be patient with us as we are working on it. Thank you."
			/> */}
			{!contentOnly ? (
				<Toolbar
					backButton={backButton}
					title={title}
					isNavButtons={isNavButtons}
				/>
			) : (
				<div style={{ height: "5vh" }} />
			)}
			{/* <Loader
        setOpenLoading={setOpenLoading}
        openLoading={openLoading}
        time={800}
      /> */}
			<IonContent
				scrollY={scrollY === true ? true : scrollY === false ? false : true}
				scrollX={scrollX === true ? true : scrollX === false ? false : true}
				ref={contentRef}
				id="content"
				fullscreen
				style={style.content}
				scrollEvents={true}
			>
				{!contentOnly && <SecondToolbar searchBar={searchBar} title={title} />}
				{children}
			</IonContent>
			{!contentOnly && <Tabbar showTabbar={showTabbar} />}
		</IonPage> //# page
	);
};

export default Page;
