import { IonSpinner } from "@ionic/react";
import React, { FC } from "react";

export const Spinner: FC<{ width?: string; height?: string; }> = ({ width, height }) => {
  return (
    <div
      style={{
        textAlign: "center",
        width: width ? width : "auto",
        height: height ? height : "auto",
      }}
    >
      <IonSpinner style={{width: '5rem', height: '5rem'}} />
    </div>
  );
};
