/* eslint-disable */
import React, {
  CSSProperties,
  FC,
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";
import {
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonSlide,
  IonSlides,
  isPlatform,
} from "@ionic/react";
import moment from "moment";
import { calendarClearOutline, star } from "ionicons/icons";
import { getJson, createMarkUp, doGetPromise } from "../../util";
import { Spinner, YoutubeFAB } from "..";
import ScrollContainer from "react-indiana-drag-scroll";
import { useYoutubeTrailer } from "../../util/useYoutubeTrailer";

export const Slider: FC<{ url: string; path: string }> = ({
  url,
  path,
}): JSX.Element => {
  const [sliderData, setSliderData] = useState<any>([]);
  const slideOpts = {
    initialSlide: 0,
    autoplay: {
      delay: "5000",
      disableOnInteraction: true,
    },
    speed: 700,
    loop: true,
  };
  useEffect(() => {
    doGetPromise({ url }).then((r) => {
      let results = r?.results;
      // console.log(results);
      if (results.length > 0) {
        setSliderData(results);
      }
    });
  }, [url]);

  let style: { section: CSSProperties } = {
    section: {
      display: "flex",
      gap: "20px",
      maxWidth: "100vw",
      overflowX: "scroll",
      scrollSnapType: "x",
      // WebkitOverflowScrolling: "touch",
      // scrollPadding: "1rem",
      // padding: "3rem auto 0",
      // borderRadius: "10px",
      scrollbarWidth: "none",
    },
  };

  // let pos = { top: 0, left: 0, x: 0, y: 0 };
  // let x = 0;
  // let y = 0;
  // const ele: any = document.querySelector("div.section");

  // const mouseDownHandler = function (e: any) {
  //   pos = {
  //     // The current scroll
  //     left: ele?.scrollLeft,
  //     top: ele.scrollTop,
  //     // Get the current mouse position
  //     x: e.clientX,
  //     y: e.clientY,
  //   };

  //   document.addEventListener("mousemove", mouseMoveHandler);
  //   document.addEventListener("mouseup", mouseUpHandler);
  // };

  // const mouseMoveHandler = function (e: any) {
  //   // How far the mouse has been moved
  //   const dx = e.clientX - pos.x;
  //   const dy = e.clientY - pos.y;

  //   // Scroll the element
  //   ele.scrollTop = pos.top - dy;
  //   ele.scrollLeft = pos.left - dx;
  // };

  // const mouseUpHandler = function () {
  //   ele.style.cursor = "grab";
  //   ele.style.removeProperty("user-select");

  //   document.removeEventListener("mousemove", mouseMoveHandler);
  //   document.removeEventListener("mouseup", mouseUpHandler);
  // };

  return (
    <Suspense fallback={<Spinner width="auto" height="auto" />}>
      {/* <div className="section">
        {sliderData
          ? sliderData?.map(
              (slide: any, index: any) =>
                slide.backdrop_path && (
                  <div key={index} className="section__item" style={{}}>
                    <IonCardSubtitle>{slide.title}</IonCardSubtitle>
                    <img
                      style={{
                        borderRadius: 15,
                        objectFit: "scale-down",
                        height: "40vh",
                      }}
                      src={
                        "https://image.tmdb.org/t/p/w1280/" +
                        slide.backdrop_path
                      }
                    />
                  </div>
                )
            )
          : null}
      </div> */}

      <IonSlides
        key={sliderData?.map((slide: any) => slide.id).join("_")}
        options={slideOpts}
        pager
        scrollbar={false}
      >
        {sliderData
          ? sliderData?.map((slide: any, index: any) => (
              <Slide key={slide.id} path={path} index={index} slide={slide} />
            ))
          : null}
      </IonSlides>
    </Suspense>
  );
};

const Slide: FC<{
  index: number;
  slide: any;
  path: string;
}> = ({ path, slide, index }): JSX.Element => {
  let {
    id,
    title,
    name,
    poster_path,
    backdrop_path,
    overview,
    popularity,
    vote_average,
    release_date,
    first_air_date,
  } = slide;
  const history = useHistory();
  const [key, setKey] = useState("");
  const [flipPlayer, setFlipPlayer] = useState(false);
  const [youtubeVideo, setYoutubeVideo] = useState("");
  const [playerSelected, setPlayerSelected] = useState("");
  const [error, setError] = useState(null);
  const isMobile = useMemo(
    () => isPlatform("ios") || isPlatform("android") || isPlatform("ipad"),
    []
  );
  const { trailer } = useYoutubeTrailer(id);
  // let api_key = process.env.REACT_APP_TMDB_API_KEY;
  // let url = `https://api.themoviedb.org/3${path}/${id}/videos?api_key=${api_key}&language=en-US`;
  let imageURL =
    "https://image.tmdb.org/t/p/w1280/" + backdrop_path || poster_path;
  let trailerIframe = `<iframe src="${trailer}" style="width:100%;height:40vmax;border-radius: 10px" frameborder="0"  />`;

  // useEffect(() => {
  //   getYoutubeTrailers(url, setKey).then((res) => res);
  // }, [id]);

  // useEffect(() => {
  //   if (key) {
  //     setYoutubeVideo(
  //       `https://www.youtube.com/embed/${key}?autoplay=1&mute=0&playsinline=1`
  //     );
  //   }
  //   return () => {
  //     return;
  //   };
  // }, [key]);



  // async function getYoutubeTrailers(url: string, setKey?: any) {
  //   await doGetPromise({
  //     url: url,
  //   })
  //     .then((res) => res.results[0])
  //     .then((res) => {
  //       setKey(res.key);
  //       return res;
  //     })
  //     .catch((err) => setError(err));
  // }

  var style: ISliderProps = {
    card: {
      width: "100%",
      height: "max-content",
      padding: "5px",
      borderRadius: "10px",
    },
    div2: {
      display: "inline-block",
      overflow: "hidden",
      position: "relative",
      width: "100%",
      borderRadius: "10px",
      height: "40vh",
    },
    img: {
      pointerEvents: "none",
      objectFit: "cover",
      position: "absolute",
      width: "100%",
      borderRadius: "10px",
      height: "40vh",
      zIndex: -1,
    },
    div: {
      width: "100%",
      height: "40vh",
      borderRadius: "10px",
      backgroundImage: `url(${imageURL})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center top",
      backgroundSize: "cover",
    },
    title: {
      height: 100,
      width: "100%",
      whiteSpace: "pre-wrap",
      overflowX: "hidden",
      overflowY: "scroll",
      scrollbarWidth: "none",
    },
    overview: {
      height: 100,
      overflowX: "hidden",
      whiteSpace: "pre-wrap",
      overflowY: "scroll",
      scrollbarWidth: "none",
      // scrollbarColor: "#ffffffff",
    },
    horizontalDiv: {
      display: "inline-flex",
      width: "100%",
      height: "100%",
      WebkitBorderBottomLeftRadius: "10px",
      WebkitBorderBottomRightRadius: "10px",
      // backgroundColor: "rgba(255,255,255,0.45)",
    },
    vote: {
      color: "gold",
    },
    date: {},
    fab: {},
    fabPosition: { vertical: "top", horizontal: "end" },
  };

  return (
    <IonSlide className="clickable_hover" key={index}>
      <YoutubeFAB
        playerConfig={{
          setFlipPlayer: setFlipPlayer,
          flipPlayer: flipPlayer,
          setPlayerSelected: setPlayerSelected,
          playerSelected: playerSelected,
        }}
        fabStyle={style.fab}
        // iframStyle={{ width: "100%", height: "26vmax" }}
        position={{ vertical: "top", horizontal: "end" }}
      />
      <div style={style.card} onClick={() => history.push(path + `/` + id)}>
        <div style={style.div}>
          {/* <img style={style.img} src={imageURL} /> */}
          {!flipPlayer ? (
            <div className="dark-backdrop-div" style={style.horizontalDiv}>
              <IonGrid>
                <IonRow style={{ padding: "3%" }}>
                  <IonCol size="12" sizeLg="5">
                    <div style={style.title}>
                      <IonCardTitle>{title || name}</IonCardTitle>
                    </div>
                  </IonCol>
                  <IonCol size="12" sizeLg="7" style={style.overview}>
                    <IonCardSubtitle className="darkModeText">
                      {overview}
                    </IonCardSubtitle>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="5" style={style.vote}>
                    <IonIcon icon={star} />
                    <b>{vote_average}</b>
                  </IonCol>
                  <IonCol size="2" />
                  <IonCol size="5">
                    <IonIcon icon={calendarClearOutline} />
                    <b>
                      {moment(release_date || first_air_date).format(
                        "MMM DD, YYYY"
                      )}
                    </b>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          ) : (
            youtubeVideo !== "" && (
              <div id="html" dangerouslySetInnerHTML={createMarkUp(trailerIframe)} />
            )
          )}
        </div>
      </div>
    </IonSlide>
  );
};

interface ISliderProps {
  card?: CSSProperties;
  div?: CSSProperties;
  div2?: CSSProperties;
  img?: CSSProperties;
  title?: CSSProperties;
  overview?: CSSProperties;
  horizontalDiv?: CSSProperties;
  vote?: CSSProperties;
  date?: CSSProperties;
  popularity?: CSSProperties;
  fab?: CSSProperties;
  fabPosition?: {
    vertical?: "top" | "bottom" | "center";
    horizontal?: "center" | "end" | "start";
  };
}
