import { CSSProperties } from "react";

export const thumbSliderStyle = () => {
  let style: {
    thumbCard?: CSSProperties;
    thumbImg?: CSSProperties;
    thumbDiv?: CSSProperties;
    thumbDate?: CSSProperties;
    thumbVote?: CSSProperties;
  } = {
    thumbCard: {
      display: "inline-block",
      // padding: "3px",
      width: "max-content",
      height: "max-content",
      textAlign: "center",
      marginTop: "1.5vh",
    },
    thumbImg: {
      width: "auto",
      height: "220px",
      objectFit: "fill",
      borderRadius: "10px",
    },
    thumbDate: {
      // fontSize: '1vmax',
      marginTop: "5px",
    },
    thumbDiv: {},
    thumbVote: {
      // fontSize: '1vmax',
      color: "gold",
      marginTop: "-.5vh",
    },
  };
  return style;
};
