/* eslint-disable */
import React, { ReactElement } from 'react'
import { Page } from '../components'

interface SettingsProps {
    
}

function Settings({}: SettingsProps): ReactElement {
    return (
        <Page title="Settings" center={true} showTabbar={true} isNavButtons={true}>

            
        </Page>
    )
}

export default Settings
