/* eslint-disable */
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { searchOutline } from "ionicons/icons";
import React, { useState } from "react";
import { SearchModal } from "./Modals/SearchModal";

interface SecondToolbarProps {
  title: string;
  searchBar?: boolean;
}

function SecondToolbar({ title, searchBar }: SecondToolbarProps) {
  return (
    <IonHeader className="ion-no-border" translucent={true} collapse="condense">
      <IonToolbar className="translucent">
        <IonTitle size="large">{title}</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
}

export default SecondToolbar;
