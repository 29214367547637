import { Plugins } from "@capacitor/core";
import "@capacitor-community/http";

export const getShowDetails = async (id: string) => {
    const { Http } = Plugins;
    const ret = await Http.request({
      method: "GET",
      url: `https://api.themoviedb.org/3/tv/${id}?api_key=046fdb0d753c6903e673934705cb553f&append_to_response=videos,images&include_image_language=en,null`,
    });
    return ret;
  };

