import React from "react";
import { getApps, initializeApp, getApp } from "firebase/app";
import * as firebaseui from "firebaseui";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/storage";
// import { initializeApp } from "firebase/app";

export const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

function FirebaseFunc() {
	initializeApp(firebaseConfig);
	// const app: any = getApp("default");
	const apps: any = getApps();
  
	// try {
	// 	if (!apps.length!!) {
	// 	} else {
	// 		app();
	// 	}
	// } catch (error) {
	// 	throw error;
	// }

	// const auth: any = app().auth();
	// // const auth2: any = app().auth;
	// const firestore: any = app().firestore();
	// const storage: any = app().storage();
	// const analytics: any = app().analytics();
	// const imagesStorageRef: any = app().storage().ref("images");

	// const getUser = (setUser: any) => {
	// 	auth.onAuthStateChanged((user: any) => {
	// 		if (user) {
	// 			// console.log(user);
	// 			setUser(user);
	// 		} else {
	// 			setUser(null);
	// 		}
	// 	});
	// };
	// const doSignInWithEmailAndPassword = async (
	// 	email: string,
	// 	password: string,
	// 	setRes: any
	// ) => {
	// 	await auth
	// 		.signInWithEmailAndPassword(email, password)
	// 		.then((res: any) => {
	// 			setRes(res.user.email);
	// 			return res;
	// 		})
	// 		.catch((error: any) => {
	// 			setRes(error);
	// 			return error;
	// 		});
	// };
	// const doCreateUserWithEmailAndPassword = (
	// 	user: any,
	// 	email: any,
	// 	password: any,
	// 	setRes: any
	// ) => {
	// 	return auth
	// 		.createUserWithEmailAndPassword(email, password)
	// 		.then((res: any) => {
	// 			setRes(res.user);
	// 			if (res.user) {
	// 				res.user
	// 					.updateProfile({
	// 						displayName: user,
	// 						photoURL:
	// 							"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
	// 					})
	// 					// .then((res) => console.log("Updated", res))
	// 					.catch((error: any) => console.log(error));
	// 				res.user
	// 					.sendEmailVerification()
	// 					// .then((res) => console.log(res))
	// 					.catch((error: any) => console.log(error));
	// 			}
	// 			return res;
	// 		})
	// 		.catch((error: any) => {
	// 			setRes({ error: true, message: error.message, code: error.code });
	// 			// console.log(error);
	// 			return error;
	// 		});
	// };
	// const doSignOut = () => {
	// 	localStorage.removeItem("user");
	// 	sessionStorage.removeItem("authSessionResult");
	// 	localStorage.setItem("isOnline", "offline");
	// 	auth.signOut();
	// };
	// const doPasswordReset = (email: any) => auth.sendPasswordResetEmail(email);
	// const doPasswordUpdate = (password: any) =>
	// 	auth.currentUser.updatePassword(password);
	// const doVerificationEmail = () => {
	// 	var user = auth.currentUser;
	// 	return user
	// 		.sendEmailVerification()
	// 		.then(function (res: any) {
	// 			// console.log(res);
	// 		})
	// 		.catch(function (error: any) {
	// 			console.log(error);
	// 		});
	// };
	// const doUpdatePhotoURL = (pic: any) => {
	// 	return auth.currentUser
	// 		.updateProfile({ photoURL: pic })
	// 		.then((res: any) => {
	// 			return res;
	// 		})
	// 		.catch((error: any) => {
	// 			return error;
	// 		});
	// };

	// const doUpdateEmail = (email: any) => {
	// 	var user = auth.currentUser;
	// 	if (email !== "") {
	// 		user
	// 			.updateEmail(email)
	// 			.then((res: any) => {
	// 				return res;
	// 			})
	// 			.catch((error: any) => {
	// 				return error;
	// 			});
	// 	}
	// };
	// const doReAuthenticateUser = (password: any) => {
	// 	let email = auth.currentUser.email;
	// 	let pass = password;
	// 	const credentials = auth.EmailAuthProvider.credential(email, pass);
	// 	return auth.currentUser
	// 		.reauthenticateWithCredential(credentials)
	// 		.then((res: any) => {
	// 			return res;
	// 		})
	// 		.catch((error: any) => {
	// 			return error;
	// 		});
	// };

	// const doUpdateProfilePic = (password: any, pic: any) => {
	// 	doReAuthenticateUser(password).then((res: any) => {
	// 		if (res) {
	// 			doUpdatePhotoURL(pic)
	// 				.then((res: any) => {
	// 					// console.log(res);
	// 				})
	// 				.catch((error: any) => console.log(error));
	// 		}
	// 	});
	// };

	// const doUpdateDisplayName = (name: any) => {
	// 	return (
	// 		auth.currentUser
	// 			.updateProfile({ displayName: name })
	// 			// .then((res) => console.log(res))
	// 			.catch((error: any) => console.log(error))
	// 	);
	// };

	// const UI = ({ onSignIn }: any) => {
	// 	var ui =
	// 		firebaseui.auth.AuthUI.getInstance() ||
	// 		new firebaseui.auth.AuthUI(auth());
	// 	const uiConfig = {
	// 		callbacks: {
	// 			signInSuccessWithAuthResult: successfullSignIn,
	// 			uiShown: () => onSignIn(),
	// 		},
	// 		signInFlow: "popup",
	// 		signInSuccessUrl: "/",
	// 		signInOptions: [
	// 			auth.EmailAuthProvider.PROVIDER_ID,
	// 			// "apple.com",
	// 			// "google.com",
	// 		],
	// 	};
	// 	let UI = document.getElementById("#firebase-ui");

	// 	if (UI) {
	// 		ui.start("#firebase-ui", uiConfig);
	// 	}

	// 	return <div id="firebase-ui" />;

	// 	function successfullSignIn(authResult: any, redirectUrl: any) {
	// 		localStorage.setItem("user", JSON.stringify(authResult.user));
	// 		sessionStorage.setItem("authSessionResult", JSON.stringify(authResult));
	// 		return true;
	// 	}
	// };

	// return {
	// 	auth,
	// 	firestore,
	// 	storage,
	// 	analytics,
	// 	apps,
	// 	imagesStorageRef,
	// 	getUser,
	// 	doSignInWithEmailAndPassword,
	// 	doCreateUserWithEmailAndPassword,
	// 	doSignOut,
	// 	doPasswordReset,
	// 	doPasswordUpdate,
	// 	doVerificationEmail,
	// 	doUpdateEmail,
	// 	doUpdateProfilePic,
	// 	doUpdateDisplayName,
	// 	UI,
	// };
}

// export default class Firebase {}

const FirebaseContext = React.createContext(FirebaseFunc());
export default FirebaseFunc;
export { FirebaseContext };

export function signOut() {
	// auth().signOut();
	localStorage.removeItem("user");
	sessionStorage.removeItem("authSessionResult");
	localStorage.setItem("isOnline", "offline");
}
