/* eslint-disable */
import { MoviesStyles } from "../app/interfaces";

export function movieCardStyle(
  MovieCardStyle: string,
  large?: boolean
): MoviesStyles {
  return MovieCardStyle === "grid"
    ? GridStyle(large)
    : MovieCardStyle === "card"
    ? CardStyle()
    : BlankStyle();
}

function CardStyle(): MoviesStyles {
  return {
    _movieCard: {
      // textAlign: "center",
      // display: "inline-block",
      width: "inherit",
      height: "max-content",
    },
    get movieCard() {
      return this._movieCard;
    },
    set movieCard(value) {
      this._movieCard = value;
    },
    _movieImg: {
      // position: 'absolute',
      top: 0,
      borderRadius: 10,
      maxHeight: "35vh",
      height: "26vmax",
      width: "100%",
      objectFit: "cover",
    },
    get movieImg() {
      return this._movieImg;
    },
    set movieImg(value) {
      this._movieImg = value;
    },
    _movieTitle: {
      padding: ".5vh",
      opacity: 1,
    },
    get movieTitle() {
      return this._movieTitle;
    },
    set movieTitle(value) {
      this._movieTitle = value;
    },
    _movieDate: {},
    get movieDate() {
      return this._movieDate;
    },
    set movieDate(value) {
      this._movieDate = value;
    },
    _movieRating: {
      color: "gold",
    },
    get movieRating() {
      return this._movieRating;
    },
    set movieRating(value) {
      this._movieRating = value;
    },
    _movieOverview: {},
    get movieOverview() {
      return this._movieOverview;
    },
    set movieOverview(value) {
      this._movieOverview = value;
    },
  };
}

function GridStyle(large: boolean | undefined): MoviesStyles {
  return {
    _movieCard: {
      display: "inline-block",
      textAlign: "center",
      marginBottom: "5px",
      marginTop: "10px",
      marginRight: "5px",
      marginLeft: "5px",
      borderRadius: 10,
    },
    get movieCard() {
      return this._movieCard;
    },
    set movieCard(value) {
      this._movieCard = value;
    },
    _movieTitle: large
      ? {
          padding: ".5vh",
          opacity: 1,
        }
      : {
          width: "13vmax",
          padding: ".5vh",
          clear: "both",
          opacity: 1,
          whiteSpace: "nowrap",
        },
    get movieTitle() {
      return this._movieTitle;
    },
    set movieTitle(value) {
      this._movieTitle = value;
    },
    _movieImg: {
      borderRadius: 10,
      height: "20vmax",
      objectFit: "cover",
    },
    get movieImg() {
      return this._movieImg;
    },
    set movieImg(value) {
      this._movieImg = value;
    },
    _movieDate: {
      fontSize: "7px",
    },
    get movieDate() {
      return this._movieDate;
    },
    set movieDate(value) {
      this._movieDate = value;
    },
    _movieRating: {
      color: "gold",
    },
    get movieRating() {
      return this._movieRating;
    },
    set movieRating(value) {
      this._movieRating = value;
    },
  };
}

function BlankStyle(): MoviesStyles {
  return {
    _movieCard: {},
    get movieCard() {
      return this._movieCard;
    },
    set movieCard(value) {
      this._movieCard = value;
    },
    _movieDate: {},
    get movieDate() {
      return this._movieDate;
    },
    set movieDate(value) {
      this._movieDate = value;
    },
    _movieImg: {},
    get movieImg() {
      return this._movieImg;
    },
    set movieImg(value) {
      this._movieImg = value;
    },
    _movieRating: {},
    get movieRating() {
      return this._movieRating;
    },
    set movieRating(value) {
      this._movieRating = value;
    },
  };
}
