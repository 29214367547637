/* eslint-disable */
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonSpinner,
  isPlatform,
} from "@ionic/react";
import React, {
  FC,
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { logOutOutline } from "ionicons/icons";
import { Page } from "../components";
import { FirebaseContext } from "../util/Firebase";
import { Loader } from "../components/Loaders/Loader";
import { RouteComponentProps, useHistory } from "react-router";
import { loginPageStyles } from "../styles/loginPageStyles";
import lightLogo from "../assets/iwatchtv-light-logo-V2.png";
import darktLogo from "../assets/iwatchtv-dark-logo-V2.png";
import * as firebaseui from "firebaseui";
// import firebase from "firebase";
import "firebase/auth";
import { Spinner } from "../components/Spinner";

export const Login: FC<RouteComponentProps> = (): JSX.Element => {
  const [openLoading, setOpenLoading] = useState(true);
  const [spinner, setSpinner] = useState(true);
  const uiElement: HTMLDivElement | null = document.querySelector(
    "div#firebase-ui"
  );
  const style = loginPageStyles();
  const [start, setStart] = useState(false);
  // const ui =
  //   firebaseui.auth.AuthUI.getInstance() ||
  //   new firebaseui.auth.AuthUI(firebase.auth());

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: successfullSignIn,
      uiShown: () => loadersOff(),
    },
    signInFlow: "popup",
    autoUpgradeAnonymousUsers: true,
    signInSuccessUrl: "/",
    signInOptions: [
      // firebase.auth.EmailAuthProvider.PROVIDER_ID,
      // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
  };

  function successfullSignIn(authResult: any, redirectUrl: any) {
    localStorage.setItem("user", JSON.stringify(authResult.user));
    sessionStorage.setItem("authSessionResult", JSON.stringify(authResult));
    return true;
  }

  function loadersOff() {
    setSpinner(false);
    let timer = setTimeout(() => setOpenLoading(false), 1250);
    return () => clearTimeout(timer);
  }

  useEffect(() => {
    // if (start) ui.start("#firebase-ui", uiConfig);
    return () => {};
  }, [start]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setStart(true);
    }, 1300);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Page
      contentOnly={true}
      title="Login"
      searchBar={false}
      isNavButtons={false}
    >
      {/* <Loader setOpenLoading={setOpenLoading} openLoading={openLoading} /> */}
      <div style={style.logInContainer}>
        {/* <Suspense fallback={<Spinner width="auto" height="auto" />}>
          <WelcomeCardWithLogin config={{ spinner, loadersOff }} />
        </Suspense> */}
      </div>
    </Page>
  );
};

const WelcomeCardWithLogin: FC<{
  config?: { spinner?: boolean; loadersOff?: () => void };
}> = ({ config }): JSX.Element => {
  const firebaseContext = useContext(FirebaseContext);
  const style = loginPageStyles();
  const [hidePics, setHidePics] = useState(false);
  let spinner = config?.spinner;

  const isMobile = useMemo(
    () => isPlatform("ios") || isPlatform("android") || isPlatform("ipad"),
    []
  );

  return (
    <></>
    // <IonCard>
    //   <div>
    //     <IonCardHeader>
    //       <br />
    //       <IonCardTitle
    //         style={{
    //           display: isMobile && hidePics ? "none" : "block",
    //         }}
    //       >
    //         Welcome to{" "}
    //       </IonCardTitle>
    //       <p
    //         className="darkMode"
    //         style={{
    //           paddingTop: "30px",
    //           fontFamily: "csnpwdtNFI",
    //           display: isMobile && hidePics ? "block" : "none",
    //           fontSize: "4rem",
    //         }}
    //       >
    //         iWatchTV
    //       </p>
    //       <br />
    //       <div style={{ display: isMobile && hidePics ? "none" : "block" }}>
    //         <img
    //           className="logo-dark"
    //           style={{ width: "250px" }}
    //           src={darktLogo}
    //         />
    //         <img
    //           className="logo-light"
    //           style={{ width: "250px" }}
    //           src={lightLogo}
    //         />
    //       </div>
    //       <IonCardSubtitle>
    //         Please login to access all of our content
    //       </IonCardSubtitle>
    //     </IonCardHeader>
    //     <IonCardContent>
    //       {!firebaseContext.auth?.currentUser ? (
    //         <>
    //           {/* <appContext.UI onSignIn={config?.loadersOff} /> */}
    //           <div id="firebase-ui"></div>
    //           <IonSpinner style={{ display: spinner ? "auto" : "none" }} />
    //         </>
    //       ) : (
    //         <IonButton
    //           size="large"
    //           fill="clear"
    //           expand="full"
    //           type="button"
    //           onClick={firebaseContext.doSignOut}
    //         >
    //           <IonIcon size="large" icon={logOutOutline} />
    //           Log Out
    //         </IonButton>
    //       )}
    //     </IonCardContent>
    //   </div>
    // </IonCard>
  );
};
