/* eslint-disable */
import { useIonViewWillEnter, IonSpinner } from "@ionic/react";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import { RouteComponentProps, useLocation } from "react-router";
import { MovieCardStyleProps, MoviesStyles } from "../app/interfaces";
import { fetchData, generateYearsList } from "../util";
import { movieCardStyle } from "../styles/movieCardStyle";
import {
	Page,
	SortingToolbar,
	InfiniteScrolling,
	StyleChangeButtons,
	MoreMovieCard,
	MovieCard,
} from "../components";

const Movies: React.FC<RouteComponentProps> = (props) => {
	const location = useLocation();
	const [disableInfiniteScroll, setDisableInfiniteScroll] =
		useState<boolean>(false);
	const [items, setItems] = useState<string[]>([]);
	let state: any = location.state;
	var count = 0;
	const [counter, setCounter] = useState(0);
	const yearList: any = useMemo(() => generateYearsList(2025, 1920), []);
	const [page, setPage] = useState(1);
	const [sort, setSort] = useState("popularity.desc");
	const [year, setYear] = useState("");
	const [companyId, setCompanyId] = useState("");
	const [genre, setGenre] = useState("");
	let api_key = process.env.REACT_APP_TMDB_API_KEY;

	let style: any = localStorage.getItem("MovieCardStyle") || "grid";
	const [MovieCardStyle, setMovieCardStyle] =
		useState<MovieCardStyleProps>(style);

	const pages = (page: number) => (page > 0 ? "&page=" + page : "");
	const languages = (language: string | null) =>
		language !== "" ? "&language=" + language : "";
	const sorts = (sort: string) => (sort !== "" ? "&sort_by=" + sort : "");
	const realease_year = (year: string) =>
		year !== "" ? "&primary_release_year=" + year : "";
	const companies = (companyId: string) =>
		companyId !== "" ? "&with_companies=" + companyId : "";
	const genres = (genre: string) =>
		genre !== "" ? "&with_genres=" + genre : "";

	let url: string = `https://api.themoviedb.org/3/discover/movie?api_key=${api_key}${sorts(
		sort
	)}${pages(page)}${languages("en-US")}${realease_year(year)}${companies(
		companyId
	)}${genres(
		genre
	)}&append_to_response=videos,images&include_image_language=en,null`;

	const MoviePageStyle: MoviesStyles = useMemo(
		() => movieCardStyle(MovieCardStyle),
		[]
	);

	useIonViewWillEnter(() => {
		fetchMovies();
	});

	useEffect(() => {
		if (page === 1) fetchMovies();
		else fetchMoreMovies();
	}, [page]);

	useEffect(() => {
		setPage(1);
		fetchMovies();
	}, [sort, year, genre, companyId]);

	useEffect(() => {
		if (state?.id !== undefined && state.path.includes("/movie/"))
			setCompanyId(state?.id);
	}, [state]);

	async function fetchMovies() {
		await fetchData({
			url: url,
			more: false,
			items: items,
			setError: setDisableInfiniteScroll,
			setResults: setItems,
		});
	}

	async function fetchMoreMovies() {
		await fetchData({
			url: url,
			more: true,
			items: items,
			setError: setDisableInfiniteScroll,
			setResults: setItems,
		});
	}

	async function searchNext($event: CustomEvent<void>) {
		setPage(page + 1);
		($event.target as HTMLIonInfiniteScrollElement).complete();
		setDisableInfiniteScroll(true);
	}

	function clearFilter() {
		setPage(1);
		setSort("popularity.desc");
		setYear("");
		setCompanyId("");
	}

	const isMOVIES = items.length > 0;

	useEffect(() => {
		setCounter(counter + 1);
		if (count === 6) count = 0;
	}, [count]);

	const InMoviesAdBlock = () => {
		return (
			<>
				<div
					dangerouslySetInnerHTML={{
						__html: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1031845129279603"
     crossorigin="anonymous"></script>
<ins class="adsbygoogle"
     style="display:block"
     data-ad-format="fluid"
     data-ad-layout-key="+1o+rx+1+2-3"
     data-ad-client="ca-pub-1031845129279603"
     data-ad-slot="3804422839"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>`,
					}}
				></div>
			</>
		);
	};

	return (
		<Page title="Movies" center={true} showTabbar={true} isNavButtons={true}>
			<SortingToolbar
				isListAvailable={isMOVIES}
				yearList={yearList}
				setSort={setSort}
				setYear={setYear}
				setCompanyId={setCompanyId}
				setGenre={setGenre}
				sort={sort}
				year={year}
				companyId={companyId}
				genre={genre}
				resetFunction={clearFilter}
				isSticky={false}
				buttons={
					<StyleChangeButtons
						storageKey="MovieCardStyle"
						setCardStyle={setMovieCardStyle}
						CardStyle={MovieCardStyle}
					/>
				}
			/>

			<Suspense
				fallback={
					<div style={{ textAlign: "center" }}>
						<IonSpinner />
					</div>
				}
			>
				{items.flatMap(
					(
						{
							id,
							title,
							poster_path,
							release_date,
							vote_average,
							backdrop_path,
							overview,
						}: any,
						i: number
					) => {
						if (count === 7) {
							count = 0;
						}
						count++;

						return poster_path !== null ? (
							(count === 1 || count === 8) && MovieCardStyle === "grid" ? (
								<MovieCard
									MovieCardStyle={MovieCardStyle}
									key={i}
									style={movieCardStyle(MovieCardStyle, true)}
									i={i}
									id={id}
									overview={overview}
									title={title}
									backdrop_path={backdrop_path}
									poster_path={backdrop_path}
									release_date={release_date}
									vote_average={vote_average}
								/>
							) : (
								<MovieCard
									MovieCardStyle={MovieCardStyle}
									key={i}
									style={movieCardStyle(MovieCardStyle)}
									i={i}
									id={id}
									overview={overview}
									title={title}
									backdrop_path={backdrop_path}
									poster_path={poster_path}
									release_date={release_date}
									vote_average={vote_average}
								/>
							)
						) : (
							backdrop_path !== null && (
								<MovieCard
									MovieCardStyle={MovieCardStyle}
									key={i}
									style={movieCardStyle(MovieCardStyle)}
									i={i}
									id={id}
									overview={overview}
									title={title}
									backdrop_path={backdrop_path}
									poster_path={backdrop_path}
									release_date={release_date}
									vote_average={vote_average}
								/>
							)
						);
					}
				)}
			</Suspense>

			<div style={{ display: disableInfiniteScroll ? "none" : "auto" }}>
				<MoreMovieCard page={page} setPage={setPage} style={MoviePageStyle} />
			</div>
			<InfiniteScrolling
				disableInfiniteScroll={disableInfiniteScroll}
				searchNext={searchNext}
			/>
		</Page>
	);
};

export default Movies;
