/* eslint-disable */
import React from "react";
import { IonCard, IonCardSubtitle } from "@ionic/react";
import { useHistory, useLocation } from "react-router";
import ScrollContainer from "react-indiana-drag-scroll";

export const ProductionCompanies: any = ({ data }: { data: [] }) => {
  const history = useHistory();
  const location = useLocation();
  return data.length > 0 ? (
    <div style={{ marginBottom: "0vh" }}>
      <IonCardSubtitle className="ion-padding">
        Production Companies
      </IonCardSubtitle>
      <ScrollContainer
        horizontal={true}
        className="scroller"
        nativeMobileScroll={true}
      >
        {/* <div className=""> */}
        <div
          className="ion-padding"
          style={{
            marginTop: "-3vh",
            textAlign: "center",
            scrollbarWidth: "none",
            display: "flex",
          }}
        >
          {data.map(({ id, name, logo_path }: any) => {
            let state = {
              id: id,
              path: location.pathname,
            };
            return logo_path ? (
              <IonCard
                className={`ion-padding clickable_hover`}
                key={id}
                style={{
                  display: "block",
                  height: "160px",
                  width: "200px",
                }}
                onClick={() => history.push("/movies", state)}
              >
                <img
                  style={{
                    width: "auto",
                    height: "10vh",
                    objectFit: "fill",
                  }}
                  src={"https://image.tmdb.org/t/p/w300/" + logo_path}
                  alt=""
                />
                <IonCardSubtitle>{name}</IonCardSubtitle>
              </IonCard>
            ) : null;
          })}
        </div>
        {/* </div> */}
      </ScrollContainer>
    </div>
  ) : (
    "not good"
  );
};
