import { useState, useEffect } from "react";

export function useYoutubeTrailer(id: any) {
  const API_KEY = process.env.REACT_APP_TMDB_API_KEY;
  const getTrailerUrl = `https://api.themoviedb.org/3/movie/${id}/videos?api_key=${API_KEY}&language=en&append_to_response=videos,images&include_image_language=en,null`;
  const [info, setInfo] = useState<any>();
  const [key, setKey] = useState<string>();
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const trailer: any = `https://www.youtube.com/embed/${key}?autoplay=0&mute=0&playsinline=1&fullscreen=1`;
  const trailers = info?.map((r: any) => {
    return `https://www.youtube.com/embed/${r?.key}?autoplay=0&mute=0&playsinline=1&fullscreen=1`;
  });
  useEffect(() => fetchTrailers(), [getTrailerUrl]);

  const fetchTrailers: any = async () => {
    setLoading(true);

    try {
      const res = await fetch(getTrailerUrl);
      const data = await res.json();
      setInfo(data.results);
      setKey(data.results[0]?.key);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  return {
    trailer,
    trailers,
    loading,
    error,
  };
}
