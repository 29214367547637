import React, { CSSProperties, FC } from "react";
import {
  IonSelect,
  IonSelectOption,
  IonChip,
  IonLabel,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonToolbar,
} from "@ionic/react";
import ScrollContainer from "react-indiana-drag-scroll";
import { closeOutline } from "ionicons/icons";
import { ISortingChipsProps, SortingToolbarProps } from "../../app/interfaces";
import { sortsList, genresList, companiesList } from "../../app/constants";

export const SortingToolbar: FC<SortingToolbarProps> = ({
  isListAvailable,
  yearList,
  setSort,
  setYear,
  setCompanyId,
  setGenre,
  sort,
  year,
  companyId,
  genre,
  buttons,
  resetFunction,
  isSticky,
}): JSX.Element => {
  let styles: { header: CSSProperties; toolbar: CSSProperties } = {
    header: {
      WebkitTransition: isSticky ? "all" : "initial",
      msTransition: isSticky ? "all" : "initial",
      position: isSticky ? "sticky" : "initial",
      top: isSticky ? -0.1 : 0,
      backgroundColor: isSticky ? "rgba(0,0,0,1)" : "initial",
      opacity: isSticky ? ".95" : "inherit",
    },
    toolbar: {
      display: "flex",
      marginTop: "0px",
      marginLeft: "0px",
      width: "100%",
      marginBottom: "0px",
      textAlign: "center",
    },
  };
  return (
    <IonHeader
      style={styles.header}
      collapse="condense"
      className="ion-no-border"
      translucent={true}
    >
      <IonToolbar className="tranlucent" style={styles.toolbar}>
        {isListAvailable ? (
          <SortingChips
            yearList={yearList}
            actions={{
              setSort,
              setYear,
              setCompanyId,
              setGenre,
            }}
            state={{
              sort: sort,
              year: year,
              companyId: companyId,
              genre: genre,
            }}
          />
        ) : null}
        <IonButtons slot="secondary">
          <IonButton onClick={resetFunction}>
            <IonIcon icon={closeOutline} />
          </IonButton>
        </IonButtons>
        <IonButtons slot="end">{buttons}</IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export const SortingChips = ({
  yearList,
  state,
  actions,
}: ISortingChipsProps) => {
  let { sort, year, companyId, genre }: any = state;
  let { setSort, setYear, setCompanyId, setGenre }: any = actions;
  let styles: { chips: CSSProperties } = { chips: { minWidth: "min-content" } };
  return (
    <ScrollContainer nativeMobileScroll={true}>
      <div style={{ opacity: 1 }} className="scroller">
        <div
          style={{
            textAlign: "center",
            scrollbarWidth: "none",
            display: "flex",
            opacity: 1,
          }}
        >
          <IonChip mode="ios" style={styles.chips}>
            <IonLabel>Sort</IonLabel>
            <IonSelect
              value={sort}
              onIonChange={(e) => setSort(e.detail.value)}
              interface="popover"
            >
              <IonSelectOption key={0} value={""}>
                {"All"}
              </IonSelectOption>
              {sortsList.map(({ id, value, name }) => (
                <IonSelectOption key={id} value={value}>
                  {name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonChip>
          <IonChip mode="ios" style={styles.chips}>
            <IonLabel>Genres</IonLabel>
            <IonSelect
              value={genre}
              onIonChange={(e) => setGenre(e.detail.value)}
              interface="popover"
            >
              <IonSelectOption key={0} value={""}>
                {"All"}
              </IonSelectOption>
              {genresList.map(({ id, name }) => (
                <IonSelectOption key={id} value={id}>
                  {name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonChip>

          <IonChip mode="ios" style={styles.chips}>
            {/* <TranslatedText type="label" text={'Year'} /> */}
            <IonLabel>Year</IonLabel>
            <IonSelect
              value={year}
              onIonChange={(e) => setYear(e.detail.value)}
              interface="popover"
            >
              <IonSelectOption key={0} value={""}>
                {"All"}
              </IonSelectOption>
              {yearList.map((year: number) => (
                <IonSelectOption key={year} value={year}>
                  {year}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonChip>

          <IonChip mode="ios" style={styles.chips}>
            <IonLabel>Studios</IonLabel>
            <IonSelect
              value={companyId}
              onIonChange={(e) => setCompanyId(e.detail.value)}
              interface="popover"
            >
              <IonSelectOption key={0} value={""}>
                {"All"}
              </IonSelectOption>
              {companiesList.map(({ id, name }) => (
                <IonSelectOption key={id} value={id}>
                  {name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonChip>
        </div>
      </div>
    </ScrollContainer>
  );
};
