/* eslint-disable */
import {
  IonToolbar,
  IonTitle,
  IonSearchbar,
  IonModal,
  IonItem,
  IonLabel,
  IonAvatar,
  IonContent,
  IonButtons,
  IonButton,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonFooter,
} from "@ionic/react";
import {
  arrowBackOutline,
  arrowForwardOutline,
  closeOutline,
} from "ionicons/icons";
import React, { ReactElement, useEffect, useState } from "react";
import { MovieCard, SearchResultCard, StyleChangeButtons } from "..";
import { MovieCardStyleProps } from "../../app/interfaces";
// import AdMobFunctions from "../../services/AdMob";
import { movieCardStyle } from "../../styles/movieCardStyle";
import { getJson } from "../../util";
import ThumbSlider from "../Sliders/ThumbSlider";

export function SearchModal({
  openModal,
  setOpenModal,
}: {
  openModal: boolean;
  setOpenModal: any;
}): ReactElement | JSX.Element {
  let API_KEY = process.env.REACT_APP_TMDB_API_KEY;
  let style: any = localStorage.getItem("SearchCardStyle") || "grid";
  const [searchQuery, setSearchQuery] = useState<string | undefined>("");
  const [searchResults, setSearchResults] = useState<any>();
  const [searchType, setSearchType] = useState("multi");
  const [page, setPage] = useState(1);
  const [MovieCardStyle, setMovieCardStyle] = useState<MovieCardStyleProps>(
    style
  );
  // const ads = AdMobFunctions(false);
  const isMOVIES = searchResults?.length > 0;
  const url = `https://api.themoviedb.org/3/search/${searchType}?api_key=${API_KEY}&language=${"en-US"}&query=${searchQuery}&page=${page}&include_adult=false`;

  useEffect(() => {
    getJson(url).then((res) => {
      let results = res.results;
      setSearchResults(results);
      //   setSearchResults(res.data.results);
    });
  }, [searchQuery, url, searchType]);

  //   useEffect(() => {
  //     getJson(url).then((res) => {
  //       let results = res.data.results;
  //       setSearchResults(searchResults.concat(results));
  //     });
  //   }, [page]);

  useEffect(() => {
    if (searchQuery !== "" && searchResults?.length > 0) {
      if (searchResults[0].id) {
        setOpenModal(true);
      }
    }
  }, [searchResults]);

  useEffect(() => setOpenModal(false), [location.pathname]);
  let SearchPlaceholder =
    searchType === "multi"
      ? "All"
      : searchType === "movie"
      ? "Movie"
      : searchType === "tv"
      ? "TV"
      : searchType === "person"
      ? "Person"
      : "";

  return (
    <IonModal
      onDidDismiss={() => {
        // ads.resumeBanner();
        setOpenModal(false);
      }}
      className="search-modal"
      isOpen={openModal}
    >
      <IonToolbar>
        <IonButtons style={{ paddingLeft: "10px" }} slot="start">
          <IonLabel>Search Type</IonLabel>
          <IonSelect
            //    interfaceOptions={customPopoverOptions}
            interface="popover"
            value={searchType}
            placeholder={SearchPlaceholder}
            onIonChange={(e) => setSearchType(e.detail.value)}
          >
            <IonSelectOption value="multi">All</IonSelectOption>
            <IonSelectOption value="movie">Movies</IonSelectOption>
            <IonSelectOption value="tv">TV</IonSelectOption>
            <IonSelectOption value="person">Actors</IonSelectOption>
          </IonSelect>
        </IonButtons>

        <IonButtons slot="end">
          <IonButton
            onClick={() => {
              setOpenModal(false);
              setSearchQuery("");
            }}
          >
            <IonIcon size="large" icon={closeOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonToolbar>
        <IonButtons>
          <IonSearchbar
            id="searchBar"
            placeholder={`Search`}
            //   value={searchQuery}
            onIonChange={(e) => setSearchQuery(e.detail.value)}
            onIonClear={() => setPage(1)}
            style={{ minWidth: "100px", width: "80%", maxWidth: "100%" }}
          />
          <StyleChangeButtons
            storageKey="MovieCardStyle"
            setCardStyle={setMovieCardStyle}
            CardStyle={MovieCardStyle}
          />
        </IonButtons>
      </IonToolbar>
      <IonContent fullscreen scrollEvents style={{ textAlign: "center" }}>
        {isMOVIES &&
          searchResults?.map(
            (
              {
                id,
                overview,
                title,
                name,
                backdrop_path,
                profile_path,
                release_date,
                vote_average,
                poster_path,
                media_type,
              }: any,
              i: number
            ) => {
              return MovieCardStyle === "grid" ? (
                <SearchResultCard
                  path={media_type}
                  MovieCardStyle={MovieCardStyle}
                  key={i}
                  style={movieCardStyle(MovieCardStyle)}
                  i={i}
                  id={id}
                  overview={overview}
                  title={title || name}
                  backdrop_path={backdrop_path || profile_path}
                  poster_path={poster_path || profile_path}
                  release_date={release_date}
                  vote_average={vote_average}
                />
              ) : (
                backdrop_path !== null && (
                  <SearchResultCard
                    path={media_type}
                    MovieCardStyle={MovieCardStyle}
                    key={i}
                    style={movieCardStyle(MovieCardStyle)}
                    i={i}
                    id={id}
                    overview={overview}
                    title={title || name}
                    backdrop_path={backdrop_path || profile_path}
                    poster_path={backdrop_path || profile_path}
                    release_date={release_date}
                    vote_average={vote_average}
                  />
                )
              );
            }
          )}

        {!isMOVIES && (
          <>
            <ThumbSlider
              sessionName={"Trending Movies"}
              title="Trending Movies"
              path="/movie"
              url={`https://api.themoviedb.org/3/trending/movie/week?api_key=${API_KEY}`}
            />
            <ThumbSlider
              sessionName={"Trending TV Shows"}
              title="Trending TV Shows"
              path="/tv"
              url={`https://api.themoviedb.org/3/trending/tv/week?api_key=${API_KEY}`}
            />
          </>
        )}
      </IonContent>
      <IonFooter>
        {isMOVIES && (
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => setPage(page - 1)} fill="clear">
                <IonIcon icon={arrowBackOutline} />
              </IonButton>
            </IonButtons>
            <IonTitle>{page}</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setPage(page + 1)} fill="clear">
                <IonIcon icon={arrowForwardOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        )}
      </IonFooter>
    </IonModal>
  );
}
