/* eslint-disable */
import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCardSubtitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  IonToolbar,
} from "@ionic/react";
import {
  albumsOutline,
  gridOutline,
  informationOutline,
  listOutline,
} from "ionicons/icons";
import React, { CSSProperties, FC, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { MovieCardStyleProps } from "../app/interfaces";
import { MovieCard, PersonInfo, ShowCard } from "../components";
import Page from "../components/Page";
import { movieCardStyle } from "../styles/movieCardStyle";
import { showCardStyle } from "../styles/showCardStyle";
import { getJson } from "../util";

interface PersonDetailsProps {}

const PersonDetails: FC<PersonDetailsProps> = () => {
  const { id } = useParams<{ id: string }>();
  const [personDetails, setPersonDetails] = useState<any>();
  const [movies, setMovies] = useState<{ cast: any[]; crew: any[] }>({
    cast: [],
    crew: [],
  });
  const [shows, setShows] = useState<{ cast: any[]; crew: any[] }>({
    cast: [],
    crew: [],
  });
  // const [allWorkedIn, setAllWorkedIn] = useState<any[]>();
  // const [allPlayedIn, setAllPlayedIn] = useState<any[]>();
  const [whatTo, setWhatTo] = useState<any>("movies");
  const [role, setRole] = useState<any>("cast");
  var count = 0;

  // 'list' | 'grid' | 'card'
  const getViewStyle = localStorage.getItem("PersonDetailsMediaViewStyle");
  const [MediaViewStyle, setMediaViewStyle] = useState<
    "card" | "list" | "grid" | string | null
  >(getViewStyle);

  let image = `https://image.tmdb.org/t/p/w500/` + personDetails?.profile_path;
  const URL = {
    detailsURL: `https://api.themoviedb.org/3/person/${id}?api_key=046fdb0d753c6903e673934705cb553f&language=en-US`,
    moviesURL: `https://api.themoviedb.org/3/person/${id}/movie_credits?api_key=046fdb0d753c6903e673934705cb553f&language=en-US`,
    tvURL: `https://api.themoviedb.org/3/person/${id}/tv_credits?api_key=046fdb0d753c6903e673934705cb553f&language=en-US`,
  };

  useEffect(() => {
    if (MediaViewStyle) {
      localStorage.setItem("PersonDetailsMediaViewStyle", MediaViewStyle);
    } else {
      setMediaViewStyle("card");
    }
  }, [MediaViewStyle]);

  useEffect(() => {
    getJson(URL.detailsURL)
      .then((res) => res)
      .then((res) => setPersonDetails(res));

    getJson(URL.moviesURL)
      .then((res) => res)
      .then((res) => setMovies(res));

    getJson(URL.tvURL)
      .then((res) => res)
      .then((res) => setShows(res));
  }, [URL.detailsURL]);

  let style: {
    image: CSSProperties;
    movie_list: CSSProperties;
    show_list: CSSProperties;
    actorDiv: CSSProperties;
    ListDiv: CSSProperties;
    GridDiv: CSSProperties;
  } = {
    image: {
      width: "auto",
      height: "auto",
      maxHeight: "28vh",
      borderRadius: "10px",
      objectFit: "cover",
    },
    movie_list: { height: "25vh", overflowY: "scroll" },
    show_list: { height: "25vh", overflowY: "scroll" },
    actorDiv: { width: "100%", height: "max-content" },
    ListDiv: { width: "100%", height: "max-content", paddingBottom: 50 },
    GridDiv: {
      width: "100%",
      height: "max-content",
      textAlign: "center",
      paddingBottom: 50,
    },
  };

  return (
    <Page
      title={personDetails?.name}
      showTabbar={true}
      searchBar={false}
      center={true}
      contentOnly={false}
      backButton={true}
      isNavButtons={true}
    >
      <div style={style.actorDiv}>
        <IonGrid>
          <IonRow>
            <IonCol sizeLg="3" sizeMd="3" sizeSm="3" sizeXs="12">
              <img style={style.image} src={image} alt="" />
            </IonCol>
            <IonCol sizeLg="9" sizeSm="9" sizeMd="9" sizeXs="12">
              <PersonInfo personDetails={personDetails} />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <IonToolbar>
        <IonButtons slot="end">
          <IonButton onClick={() => setMediaViewStyle("list")}>
            <IonIcon icon={listOutline} />
          </IonButton>
          <IonButton onClick={() => setMediaViewStyle("grid")}>
            <IonIcon icon={gridOutline} />
          </IonButton>
          <IonButton onClick={() => setMediaViewStyle("card")}>
            <IonIcon icon={albumsOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      {MediaViewStyle === "list" ? (
        <div style={style.ListDiv}>
          <IonGrid>
            <IonRow>
              <MediaCastColumnList
                list={movies}
                listTitle="Movies played in"
                style={style}
              />
              <MediaCastColumnList
                list={shows}
                listTitle="Shows played in"
                style={style}
              />
              <MediaCrewColumnList
                list={movies}
                listTitle="Movies worked on"
                style={style}
              />
              <MediaCrewColumnList
                list={shows}
                listTitle="Shows worked on"
                style={style}
              />
            </IonRow>
          </IonGrid>{" "}
        </div>
      ) : MediaViewStyle === "grid" ? (
        <div style={style.GridDiv}>
          <IonSelect
            // interfaceOptions={customActionSheetOptions}
            interface="action-sheet"
            placeholder="Select One"
            onIonChange={(e) => setRole(e.detail.value)}
            value={role}
          >
            <IonSelectOption value="cast">As Actor/Actress</IonSelectOption>
            <IonSelectOption value="crew">As Crew</IonSelectOption>
          </IonSelect>
          <IonSegment
            mode="ios"
            value={whatTo}
            onIonChange={(e) => setWhatTo(e.detail.value)}
          >
            <IonSegmentButton value="movies">Movies</IonSegmentButton>
            <IonSegmentButton value="shows">Shows</IonSegmentButton>
          </IonSegment>
          <div>
            {whatTo === "movies" ? (
              role === "cast" ? (
                <PersonMedia
                  mediaType="movies"
                  list={movies}
                  style={MediaViewStyle}
                  involvementType="cast"
                />
              ) : (
                <PersonMedia
                  mediaType="movies"
                  list={movies}
                  style={MediaViewStyle}
                  involvementType="crew"
                />
              )
            ) : role === "cast" ? (
              <PersonMedia
                mediaType="shows"
                list={shows}
                style={MediaViewStyle}
                involvementType="cast"
              />
            ) : (
              <PersonMedia
                mediaType="shows"
                list={shows}
                style={MediaViewStyle}
                involvementType="crew"
              />
            )}
          </div>
        </div>
      ) : (
        MediaViewStyle === "card" && (
          <div style={style.GridDiv}>
            <IonSelect
              // interfaceOptions={customActionSheetOptions}
              interface="action-sheet"
              placeholder="Select One"
              onIonChange={(e) => setRole(e.detail.value)}
              value={role}
            >
              <IonSelectOption value="cast">As Actor/Actress</IonSelectOption>
              <IonSelectOption value="crew">As Crew</IonSelectOption>
            </IonSelect>
            <IonSegment
              mode="ios"
              value={whatTo}
              onIonChange={(e) => setWhatTo(e.detail.value)}
            >
              <IonSegmentButton value="movies">Movies</IonSegmentButton>
              <IonSegmentButton value="shows">Shows</IonSegmentButton>
            </IonSegment>
            <div>
              {whatTo === "movies" ? (
                role === "cast" ? (
                  <PersonMedia
                    mediaType="movies"
                    list={movies}
                    style={MediaViewStyle}
                    involvementType="cast"
                  />
                ) : (
                  <PersonMedia
                    mediaType="movies"
                    list={movies}
                    style={MediaViewStyle}
                    involvementType="crew"
                  />
                )
              ) : role === "cast" ? (
                <PersonMedia
                  mediaType="shows"
                  list={shows}
                  style={MediaViewStyle}
                  involvementType="cast"
                />
              ) : (
                <PersonMedia
                  mediaType="shows"
                  list={shows}
                  style={MediaViewStyle}
                  involvementType="crew"
                />
              )}
            </div>
          </div>
        )
      )}

      {/* <ThumbSlider title="All Movies" path="movie" url={URL.moviesURL} /> */}
      {/* <ThumbSlider title="All TV" path="show" url={URL.tvURL} /> */}
      {/* <ShareFab id={id} /> */}
    </Page>
  );
};

export default PersonDetails;

const PersonMedia = ({
  list,
  style,
  involvementType,
  mediaType,
}: {
  list: { cast: any[]; crew: any[] };
  style: any;
  involvementType: "crew" | "cast";
  mediaType: "movies" | "shows";
}) => {
  var count = 0;
  var newList: any = involvementType === "cast" ? list.cast : list.crew;
  // useEffect(() => console.log(newList), [newList]);
  return newList.map(
    (
      {
        overview,
        title,
        backdrop_path,
        poster_path,
        id,
        release_date,
        first_air_date,
        vote_average,
        name,
      }: any,
      i: number
    ) => {
      if (count === 7) {
        count = 0;
      }
      count++;

      return poster_path !== null ? (
        (count === 1 || count === 8) && style === "grid" ? (
          mediaType === "shows" ? (
            <ShowCard
              ShowCardStyle={style}
              key={i}
              style={showCardStyle(style, true)}
              i={i}
              id={id}
              overview={overview}
              name={name}
              backdrop_path={backdrop_path}
              poster_path={backdrop_path}
              first_air_date={first_air_date}
              vote_average={vote_average}
            />
          ) : (
            <MovieCard
              MovieCardStyle={style}
              key={i}
              style={movieCardStyle(style)}
              i={i}
              id={id}
              overview={overview}
              title={title}
              backdrop_path={backdrop_path}
              poster_path={backdrop_path}
              release_date={release_date}
              vote_average={vote_average}
            />
          )
        ) : mediaType === "shows" ? (
          <ShowCard
            ShowCardStyle={style}
            key={i}
            style={showCardStyle(style, true)}
            i={i}
            id={id}
            overview={overview}
            name={name}
            backdrop_path={backdrop_path}
            poster_path={poster_path}
            first_air_date={first_air_date}
            vote_average={vote_average}
          />
        ) : (
          <MovieCard
            MovieCardStyle={style}
            key={i}
            style={movieCardStyle(style)}
            i={i}
            id={id}
            overview={overview}
            title={title}
            backdrop_path={backdrop_path}
            poster_path={poster_path}
            release_date={release_date}
            vote_average={vote_average}
          />
        )
      ) : (
        backdrop_path !== null &&
          (mediaType === "shows" ? (
            <ShowCard
              ShowCardStyle={style}
              key={i}
              style={showCardStyle(style, true)}
              i={i}
              id={id}
              overview={overview}
              name={name}
              backdrop_path={backdrop_path}
              poster_path={backdrop_path}
              first_air_date={first_air_date}
              vote_average={vote_average}
            />
          ) : (
            <MovieCard
              MovieCardStyle={style}
              key={i}
              style={movieCardStyle(style)}
              i={i}
              id={id}
              overview={overview}
              title={title}
              backdrop_path={backdrop_path}
              poster_path={backdrop_path}
              release_date={release_date}
              vote_average={vote_average}
            />
          ))
      );
    }
  );
};

const MediaCastColumnList = ({
  list,
  style,
  listTitle,
}: {
  list?: { cast: any[]; crew: any[] };
  style: any;
  listTitle: string;
}) => {
  const history = useHistory();
  return (
    <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="3" size="3">
      <IonListHeader>
        <h5>{listTitle}</h5>
      </IonListHeader>
      <IonList style={style.movie_list}>
        {list?.cast.map((media, i) => {
          return (
            <IonItem key={i}>
              <IonAvatar slot="start">
                <img
                  src={`https://image.tmdb.org/t/p/w500/` + media.poster_path}
                />
              </IonAvatar>
              <IonLabel>
                <IonCardSubtitle>{media.name || media.title}</IonCardSubtitle>
              </IonLabel>
              <IonButton
                fill="outline"
                onClick={() =>
                  history.push(
                    media.title ? "/movie/" + media.id : "/tv/" + media.id
                  )
                }
                slot="end"
              >
                <IonIcon icon={informationOutline} />
              </IonButton>
            </IonItem>
          );
        })}
      </IonList>
    </IonCol>
  );
};

const MediaCrewColumnList = ({
  list,
  style,
  listTitle,
}: {
  list?: { cast: any[]; crew: any[] };
  style: any;
  listTitle: string;
}) => {
  const history = useHistory();
  return (
    <IonCol sizeXs="12" sizeSm="12" sizeMd="6" sizeLg="6" sizeXl="3" size="3">
      <IonListHeader>
        <h5>{listTitle}</h5>
      </IonListHeader>
      <IonList style={style.movie_list}>
        {list?.crew.map((media, i) => {
          return (
            <IonItem key={i}>
              <IonAvatar slot="start">
                <img
                  src={`https://image.tmdb.org/t/p/w500/` + media.poster_path}
                />
              </IonAvatar>
              <IonLabel>
                <IonCardSubtitle>{media.name || media.title}</IonCardSubtitle>
              </IonLabel>
              <IonButton
                fill="outline"
                onClick={() =>
                  history.push(
                    media.title ? "/movie/" + media.id : "/tv/" + media.id
                  )
                }
                slot="end"
              >
                <IonIcon icon={informationOutline} />
              </IonButton>
            </IonItem>
          );
        })}
      </IonList>
    </IonCol>
  );
};
