import React, { CSSProperties, useContext } from "react";
import { useHistory } from "react-router";
import { IonIcon } from "@ionic/react";
import { logOutOutline } from "ionicons/icons";
import { FirebaseContext } from "../../util";

function MobileRouteButton({
  href,
  icon,
  location,
}: MobileRouteButtonProps): JSX.Element {
  const firebaseContext = useContext(FirebaseContext);
  const history = useHistory();
  let style: { navButton?: CSSProperties } = {
    navButton: {
      cursor: "default",
      // color: location.pathname === href ? "#eb445a" : "#92949c",
      textDecoration: "none",
      fontSize: 16,
      paddingLeft: 5,
      paddingRight: 5,
    },
  };
  return (
    <div
      id={href === "/search" ? "searchBtn" : ""}
      style={style.navButton}
      onClick={() => {
        // if (href === "/login") {
        //   if (firebaseContext.auth?.currentUser) {
        //     firebaseContext.doSignOut();
        //   } else {
        //     history.push(href);
        //   }
        // } else {
        //   history.push(href || "/");
        // }
      }}
    >
      <IonIcon
        color={location.pathname === href ? "primary" : "dark"}
        size="large"
        // icon={
        //   href === "/login"
        //     ? firebaseContext.auth?.currentUser
        //       ? logOutOutline
        //       : icon
        //     : icon
        // }
      />
    </div>
  );
}

interface MobileRouteButtonProps {
  href?: string;
  location?: any;
  icon?: string | undefined;
}

export default MobileRouteButton;
