import { IonIcon, IonButton } from "@ionic/react";
import { albumsOutline, gridOutline } from "ionicons/icons";
import React from "react";
import { MovieCardStyleProps, ShowCardStyleProps } from "../../app/interfaces";
// import { MovieCardStyleProps, ShowCardStyleProps } from "../constants";

// ShowCardStyle
export function StyleChangeButtons({
  storageKey,
  setCardStyle,
  CardStyle,
}: {
  storageKey: string;
  setCardStyle: React.Dispatch<
    React.SetStateAction<ShowCardStyleProps | MovieCardStyleProps>
  >;
  CardStyle: string;
}): JSX.Element | null {
  return (
    <>
      <IonButton
        onClick={() => {
          setCardStyle("grid");
          localStorage.setItem(storageKey, "grid");
        }}
        color={CardStyle === "grid" ? "primary" : "dark"}
        fill="clear"
      >
        <IonIcon icon={gridOutline} />
      </IonButton>
      <IonButton
        onClick={() => {
          setCardStyle("card");
          localStorage.setItem(storageKey, "card");
        }}
        color={CardStyle === "card" ? "primary" : "dark"}
        fill="clear"
      >
        <IonIcon icon={albumsOutline} />
      </IonButton>
    </>
  );
}
