/* eslint-disable */
/* Icons Import */
import {
	filmOutline,
	tvOutline,
	happyOutline,
	basketballOutline,
	bookOutline,
	easelOutline,
	settingsOutline,
	searchOutline,
	chatbubbleEllipsesOutline,
	logInOutline,
	homeOutline,
	personOutline,
	baseballOutline,
	helpBuoy,
	helpBuoyOutline,
} from "ionicons/icons";
/* Pages Import */
import {
	Login,
	MovieDetails,
	Movies,
	Shows,
	Home,
	ShowDetails,
	Settings,
	PersonDetails,
	Test,
	Privacy,
  Terms,
} from "../pages";
import { TRoutesProps } from "./interfaces";
import Contact from "../pages/Contact";
/*
 * ROUTES
 */
let HOME: TRoutesProps = {
	label: "Home",
	tab: "home",
	href: "/home",
	icon: homeOutline,
	component: Home,
	exact: true,
	type: "tab",
};
let MOVIES: TRoutesProps = {
	label: "Movies",
	tab: "movies",
	href: "/movies",
	icon: filmOutline,
	component: Movies,
	exact: true,
	type: "tab",
};
let SHOWS: TRoutesProps = {
	label: "TV Shows",
	tab: "shows",
	href: "/shows",
	icon: tvOutline,
	component: Shows,
	exact: true,
	type: "tab",
};
let ANIME: TRoutesProps = {
	label: "Anime",
	tab: "anime",
	href: "/anime",
	icon: happyOutline,
	component: Movies,
	exact: true,
	type: "routeOnly",
};
let SPORTS: TRoutesProps = {
	label: "Sports",
	tab: "sports",
	href: "/sports",
	icon: basketballOutline,
	component: Movies,
	exact: true,
	type: "routeOnly",
};
let TEST: TRoutesProps = {
	label: "TEST",
	tab: "TEST",
	href: "/test",
	icon: basketballOutline,
	component: Test,
	exact: true,
	type: "routeOnly",
};
let DOCUMENTARIES: TRoutesProps = {
	label: "Documentaries",
	tab: "documentary",
	href: "/documentary",
	icon: bookOutline,
	component: Movies,
	exact: true,
	type: "routeOnly",
};
let LIVE: TRoutesProps = {
	label: "Live TV",
	tab: "live",
	href: "/live",
	icon: easelOutline,
	component: Movies,
	exact: true,
	type: "routeOnly",
};
let SETTINGS: TRoutesProps = {
	label: "Settings",
	href: "/settings",
	icon: settingsOutline,
	component: Settings,
	exact: true,
	type: "routeOnly",
};
let CONTACT_US: TRoutesProps = {
	label: "Contact Us",
	href: "/contact",
	icon: chatbubbleEllipsesOutline,
	component: Contact,
	exact: true,
	type: "tab",
};
let SEARCH: TRoutesProps = {
	label: "Search",
	href: "/search",
	icon: searchOutline,
	component: Movies,
	exact: true,
	type: "routeOnly",
};
let ACCOUNT: TRoutesProps = {
	label: "Account",
	href: "/account",
	component: Movies,
	icon: personOutline,
	exact: true,
	type: "routeOnly",
};
let MOVIE_DETAILS: TRoutesProps = {
	label: "Movies Details",
	href: "/movie/:id",
	component: MovieDetails,
	icon: filmOutline,
	exact: false,
	type: "routeOnly",
};
let SHOW_DETAILS: TRoutesProps = {
	label: "Show Details",
	href: "/tv/:id",
	component: ShowDetails,
	icon: tvOutline,
	exact: false,
	type: "routeOnly",
};
let PERSON_DETAILS: TRoutesProps = {
	label: "Person Details",
	href: "/person/:id",
	component: PersonDetails,
	icon: personOutline,
	exact: false,
	type: "routeOnly",
};
let LOGIN: TRoutesProps = {
	label: "Login",
	href: "/login",
	component: Login,
	icon: logInOutline,
	exact: true,
	type: "routeOnly",
};
let CAST_DETAILS: TRoutesProps = {
	label: "Cast Details",
	href: "/cast/:id",
	component: PersonDetails,
	icon: personOutline,
	exact: true,
	type: "routeOnly",
};
let PRIVACY: TRoutesProps = {
	label: "Privacy Policy",
	href: "/privacy",
	component: Privacy,
	icon: helpBuoyOutline,
	exact: true,
	type: "routeOnly",
};
let TERMS: TRoutesProps = {
	label: "Terms & Conditions",
	href: "/terms",
	component: Terms,
	icon: helpBuoyOutline,
	exact: true,
	type: "routeOnly",
};

export const Routes: TRoutesProps[] = [
	HOME,
	MOVIES,
	SHOWS,
	ANIME,
	SPORTS,
	DOCUMENTARIES,
	CAST_DETAILS,
	LOGIN,
	PERSON_DETAILS,
	SHOW_DETAILS,
	SEARCH,
	MOVIE_DETAILS,
	LIVE,
	SETTINGS,
	ACCOUNT,
	CONTACT_US,
	TEST,
	PRIVACY,
  TERMS
];
