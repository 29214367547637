/* eslint-disable */
import React, { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  isPlatform,
  IonButtons,
  IonImg,
  IonBackButton,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { DesktopRouteButton, MobileRouteButton } from ".";
import { Routes } from "../app/routes";
import { lightLogo, darkLogo } from "../assets";
import { SearchModal } from "./Modals/SearchModal";
import { searchOutline } from "ionicons/icons";
// import AdMobFunctions from "../services/AdMob";

interface ToolbarProps {
  title: string;
  backButton?: boolean;
  isNavButtons?: boolean;
}

const Toolbar = ({
  title,
  backButton,
  isNavButtons,
}: ToolbarProps): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  // const ads = AdMobFunctions(false);
  var isDesktop: boolean = useMemo(
    () => isPlatform("desktop") || isPlatform("electron"),
    []
  );
  var mq = useMemo(
    () => window.matchMedia("@media screen and (max-width: 920px)"),
    []
  );
  return (
    <IonHeader translucent={true} className="ion-no-border">
      <IonToolbar className="tranlucent">
        <IonButtons
          className="ToolbarLogo"
          slot="start"
          style={{ paddingLeft: 20 }}
        >
          <IonImg
            onClick={() => history.push("/")}
            className="logo-dark"
            style={{ width: 40 }}
            src={darkLogo}
          />
          <IonImg
            onClick={() => history.push("/")}
            className="logo-light"
            style={{ width: 40 }}
            src={lightLogo}
          />

          {backButton && <IonBackButton />}
        </IonButtons>
        <IonTitle class="MobileTopTitle">{title}</IonTitle>
        {isNavButtons && (
          <>
            <IonButtons className="DesktopToolbarButtons" slot="primary">
              {Routes.map(({ href, label, tab, icon, type }, i) =>
                type !== "routeOnly" ? (
                  <DesktopRouteButton
                    key={i}
                    location={location}
                    href={href}
                    tab={tab}
                    icon={icon}
                    label={label}
                  />
                ) : null
              )}
              <IonButton
                color={location.pathname === "/search" ? "primary" : "dark"}
                onClick={() => {
                  // ads.hideBanner();
                  setOpenModal(true);
                }}
              >
                <IonIcon icon={searchOutline} />
              </IonButton>
            </IonButtons>
            <IonButtons className="MobileToolbarButtons" slot="end">
              {Routes.map(({ href, tab, icon, type }, i) =>
                type === "link" && !tab ? (
                  <MobileRouteButton
                    key={i}
                    href={href}
                    location={location}
                    icon={icon}
                  />
                ) : null
              )}

              <IonButton
                color={location.pathname === "/search" ? "primary" : "dark"}
                onClick={() => {
                  // ads.hideBanner();
                  setOpenModal(true);
                }}
              >
                <IonIcon size="large" icon={searchOutline} />
              </IonButton>
            </IonButtons>
          </>
        )}
        <SearchModal openModal={openModal} setOpenModal={setOpenModal} />
      </IonToolbar>
    </IonHeader>
  );
};

export default Toolbar;
