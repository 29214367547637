import { CSSProperties } from "react";

export const showInfoStyle: {
  Info?: CSSProperties;
  BackDropBox?: CSSProperties;
  InfoOverview?: CSSProperties;
  Poster?: CSSProperties;
  BackDrop?: CSSProperties;
  InfoText?: CSSProperties;
} = {
  Info: {
    textAlign: "center",
  },
  BackDrop: {
    height: "40vh",
    width: "100%",
    objectFit: "cover",
    objectPosition: "top",
    position: "relative",
    borderRadius: "15px",
  },
  BackDropBox: {
    marginBottom: "5vh",
    height: "auto",
    width: "auto",
    objectFit: "fill",
  },
  Poster: {
    height: "25vh",
    position: "relative",
    marginTop: "-23vh",
    objectFit: "contain",
    borderRadius: "10px",
    width: "auto",
  },
  InfoText: {
    // paddingTop: '-40px'
  },
  InfoOverview: {
    scrollbarWidth: "none",
    width: "100%",
    maxHeight: "100px",
    marginBottom: "8%",
    overflowY: "scroll",
    overflowX: "hidden",
  },
};
