/* eslint-disable */
// import { DeviceInfo } from "@capacitor/core";
import {
  IonIcon,
  IonFab,
  IonFabButton,
  isPlatform,
  useIonViewDidEnter,
} from "@ionic/react";
import { closeOutline, logoYoutube } from "ionicons/icons";
import React, { CSSProperties, FC, useEffect, useMemo, useState } from "react";
// import { openYoutubeVideo } from "../../util/NativeVideoPlayers";
// import { getDeviceInfo } from "../../util/getDeviceInfo";
// import { usePlatform } from "@capacitor-community/react-hooks/platform/usePlatform";
interface YoutubeFABProps {
  playerConfig: {
    flipPlayer?: boolean;
    setFlipPlayer?: any;
    setPlayerSelected: any;
    playerSelected: string;
  };
  fabStyle?: CSSProperties;
  iframStyle?: CSSProperties;
  position: {
    vertical?: "bottom" | "center" | "top";
    horizontal?: "center" | "end" | "start";
  };
  edge?: boolean;
  activated?: boolean;
}

export const YoutubeFAB: FC<YoutubeFABProps> = ({
  fabStyle,
  position,
  edge,
  activated,
  iframStyle,
  playerConfig,
}): JSX.Element => {
  // const [deviceInfo, setDeviceInfo] = useState<any>();
  // const isMobile = deviceInfo?.platform === "android" || deviceInfo?.platform === "ios";
  // const { platform } = usePlatform();

  const isMobile =
    isPlatform("android") || isPlatform("ios") || isPlatform("ipad");

  // useEffect(() => getDeviceInfo(setDeviceInfo), []);

  let { vertical, horizontal } = position;
  let {
    flipPlayer,
    setFlipPlayer,
    setPlayerSelected,
    playerSelected,
  } = playerConfig;

  function play() {
    setFlipPlayer(!flipPlayer);
    setPlayerSelected("trailer");
  }

  return (
    <IonFab
      vertical={vertical}
      horizontal={horizontal}
      style={{
        width: fabStyle?.width,
        height: fabStyle?.height,
        top: fabStyle?.top,
      }}
      edge={edge}
      activated={activated}
    >
      <IonFabButton
        style={{ width: fabStyle?.width, height: fabStyle?.height }}
        onClick={play}
      >
        <IonIcon
          style={{ fontSize: fabStyle?.fontSize }}
          icon={!flipPlayer ? logoYoutube : closeOutline}
        />
      </IonFabButton>
    </IonFab>
  );
};
