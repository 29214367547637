import { IonIcon, IonCard, IonCardSubtitle } from "@ionic/react";
import { add } from "ionicons/icons";
import React from "react";
import { MoreMoviesCardProps, MoreShowsCardProps } from "../../app/interfaces";

export const MoreMovieCard: React.FC<MoreMoviesCardProps> = ({
  page,
  setPage,
  style,
}) => (
  <IonCard style={style?.movieCard} onClick={() => setPage(page + 1)}>
    <img
      style={style?.movieImg}
      src={
        "https://printables.space/files/uploads/download-and-print/large-printable-numbers/plus-a4-1200x1697.jpg"
      }
      alt={""}
    />
    <IonCardSubtitle style={style?.movieTitle}>Load </IonCardSubtitle>
    <IonCardSubtitle style={style?.movieDate}>More...</IonCardSubtitle>
    <IonCardSubtitle style={style?.movieRating}>
      <IonIcon icon={add} />
    </IonCardSubtitle>
  </IonCard>
);

export const MoreShowCard: React.FC<MoreShowsCardProps> = ({
  page,
  setPage,
  style,
}) => (
  <IonCard style={style?.showCard} onClick={() => setPage(page + 1)}>
    <img
      style={style?.showImg}
      src={
        "https://printables.space/files/uploads/download-and-print/large-printable-numbers/plus-a4-1200x1697.jpg"
      }
      alt={""}
    />
    <IonCardSubtitle style={style?.showTitle}>Load </IonCardSubtitle>
    <IonCardSubtitle style={style?.showDate}>More...</IonCardSubtitle>
    <IonCardSubtitle style={style?.showRating}>
      <IonIcon icon={add} />
    </IonCardSubtitle>
  </IonCard>
);
