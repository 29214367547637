import {
	IonFab,
	IonFabButton,
	IonFabList,
	IonIcon,
	IonNote,
	IonSelect,
	IonSelectOption,
} from "@ionic/react";
import { play } from "ionicons/icons";
import React, { CSSProperties, FC } from "react";
// import AdMobFunctions from "../../services/AdMob";

export const PlayButton: FC<{
	isMobile: boolean;
	play720: () => void;
	play1080: () => void;
	quality: string;
	setQuality: React.Dispatch<React.SetStateAction<string>>;
	fabStyle?: CSSProperties;
	position?: {
		vertical: "top" | "center" | "bottom";
		horizontal: "center" | "end" | "start";
	};
}> = ({
	// isMobile,
	play1080,
	// play720,
	// quality,
	// setQuality,
	fabStyle,
	position,
}): JSX.Element => {
	// const ads = AdMobFunctions(false);
	return (
		<IonFab
			style={{ top: fabStyle?.top }}
			vertical={position?.vertical}
			horizontal={position?.horizontal}
		>
			<IonFabButton
				onClick={() => {
					// play720();
					play1080();
					// setQuality("HD");
				}}
			>
				<IonIcon icon={play} />
			</IonFabButton>
			{/* {!isMobile ? (
        <IonFabList side="bottom">
          <IonFabButton onClick={play720}>
            <IonNote color="primary">SD</IonNote>
          </IonFabButton>
          <IonFabButton onClick={play1080}>
            <IonNote color="primary">HD</IonNote>
          </IonFabButton>
        </IonFabList>
      ) : (
        <IonSelect
          style={{ marginTop: "-50px", opacity: 0 }}
          interfaceOptions={{
            header: "Video Quality",
            translucent: true,
          }}
          interface="action-sheet"
          value={""}
          multiple={false}
          cancelText="Cancel"
          okText="OK"
          onIonChange={(e) => {
            setQuality(e.detail.value);
            // ads.resumeBanner();
          }}
        >
          <IonSelectOption value="SD">SD</IonSelectOption>
          <IonSelectOption value="HD">HD</IonSelectOption>
        </IonSelect>
      )} */}
		</IonFab>
	);
};
