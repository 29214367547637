// create Contact page with this snippet:
import React from "react";
import Page from "../components/Page";
import { IonButton, IonIcon } from "@ionic/react";
import { mail } from "ionicons/icons";

const Contact = () => {
	return (
		<Page title="Contact" center={true}>
			<div className="contact-us-form">
		
			</div>
		</Page>
	);
};

export default Contact;