import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSpinner
} from "@ionic/react";
import React, { CSSProperties } from "react";

export const InfiniteScrolling = ({
  disableInfiniteScroll,
  searchNext,
}: {
  disableInfiniteScroll: boolean;
  searchNext: any;
}) => {
  let style: { loader: CSSProperties; } = {
    loader: {
      display: disableInfiniteScroll ? "none" : "auto",
      textAlign: "center",
    },
  };
  return (
    <IonInfiniteScroll
      threshold="200px"
      disabled={disableInfiniteScroll}
      onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}
    >
      <IonInfiniteScrollContent
        loadingText="Loading more..."
        loadingSpinner="bubbles"
      >
        <div style={style.loader}>
          <IonSpinner />
        </div>
      </IonInfiniteScrollContent>
    </IonInfiniteScroll>
  );
};
