import { useYoutubeTrailer } from "./../util/useYoutubeTrailer";
/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import { IonToast, isPlatform } from "@ionic/react";
import { RouteComponentProps, useParams } from "react-router";
import {
	ProductionCompanies,
	MovieInfoandPlayers,
	Page,
	ThumbSlider,
	Loader,
	YoutubeFAB,
	PlayButton,
	CastAvatarSlider,
} from "../components";
import { getJson, getMovieDetails, getWatchProviders } from "../util";
import { useMovieDetails } from "../util/getMovieDetails";

const MovieDetails: React.FC<RouteComponentProps> = (props) => {
	let API_KEY = process.env.REACT_APP_TMDB_API_KEY;
	let CLIENT_ID = process.env.REACT_APP_PUTIO_CLIENT_ID;
	let TOKEN = process.env.REACT_APP_PUTIO_TOKEN;
	const { id, movieName } = useParams<{ id: string; movieName?: string }>();
	const { trailer, trailers } = useYoutubeTrailer(id);
	const [movie, setMovieDetails] = useState<any>();
	const [companies, setCompanies] = useState([] as Array<any>);
	const [genres, setGenres] = useState([] as Array<any>);
	const [src, setSrc] = useState<string>("");
	const [openLoading, setOpenLoading] = useState<boolean>(false);
	const [playerSelected, setPlayerSelected] = useState<string>("");
	const [quality, setQuality] = useState<string>("");
	const [flipPlayer, setFlipPlayer] = useState<boolean>(false);
	const [key, setKey] = useState<string>("");
	const [error, setError] = useState<any>(null);
	const [youtubeVideo, setYoutubeVideo] = useState<string>("");
	const [providers, setProviders] = useState<any>();

	const title = movie?.title;

	const stream_720 = `https://iwatchtvapi.deno.dev/stream`;
	const stream_1080 = `https://iwatchtvapi.deno.dev/stream`;

	const isPlayingMovie = flipPlayer && playerSelected === "movie" && src !== "";
	const isPlayingTrailer = flipPlayer && playerSelected === "trailer";

	const play720 = () => setSDSource(stream_720, setSrc, setOpenLoading);
	const play1080 = () => setHDSource(stream_1080, setSrc, setOpenLoading);
	const playerCleanUp = () => setQuality("");

	const isMobile = useMemo(
		() => isPlatform("ipad") || isPlatform("ios") || isPlatform("android"),
		[]
	);

	function FlipPlayer() {
		if (flipPlayer && src !== "") setFlipPlayer(!flipPlayer);
	}

	useEffect(() => {
		FlipPlayer();
	}, [id]);

	// initial
	useEffect(() => {
		const delayedCloseLoader = setTimeout(() => setOpenLoading(false), 500);
		() => clearTimeout(delayedCloseLoader);
	}, []);

	//  movie information retrieval
	const info = useMovieDetails(id);
	useEffect(() => {
		// console.log(info);
		getMovieDetails(id).then((r) => (r.status === 200 ? SetMovie(r) : null));
		getWatchProviders(id, "movie").then((r) =>
			r.status === 200 ? setProviders(r.data.results) : null
		);
	}, [id]);

	useEffect(() => {
		playerCleanUp();
	}, [location.pathname]);

	useEffect(() => {
		if (src) {
			setOpenLoading(true);
			setFlipPlayer(true);
			setPlayerSelected("movie");
		}
	}, [src]);

	function SetMovie({ data }: { data: any }) {
		setCompanies(data.production_companies);
		setGenres(data.genres);
		setMovieDetails(data);
	}

	async function getYoutubeTrailers({
		url,
		setKey,
	}: {
		url: string;
		setKey?: any;
	}) {
		await getJson(url)
			.then((res) => {
				if (res.results[0]) {
					return res.results[0];
				}
			})
			.then((res) => {
				setKey(res.key);
			})
			.catch((err) => setError(err));
	}

	useEffect(() => {
		setOpenLoading(true);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setOpenLoading(false);
		}, 1200);
	}, [id]);
	const [showToast, setShowToast] = useState(true);
	return (
		<Page title={title} searchBar={false} backButton={true} isNavButtons={true}>
			<Loader
				openLoading={openLoading}
				setOpenLoading={setOpenLoading}
				time={9000}
			/>
			<IonToast
				color="warning"
				isOpen={showToast}
				onDidDismiss={() => setShowToast(false)}
				message="If movie doesn't play at first click, just click play again."
				duration={8000}
				keyboardClose={true}
				// position="top"
			/>
			<div>
				<MovieInfoandPlayers
					details={movie}
					movieSrc={src}
					setMovieSrc={setSrc}
					trailerSrc={trailer}
					trailers={trailers}
					extraObjects={{
						flipPlayer,
						youtubeVideo,
						playerSelected,
						setOpenLoading,
					}}
					watchProviders={providers}
				/>
				{/* {collection.length > 0 ? (
          <CollectionList Collection={collection} />
        ) : null} */}

				<CastAvatarSlider
					size={150}
					label="Cast"
					type="cast"
					url={`https://api.themoviedb.org/3/movie/${id}/credits?api_key=${API_KEY}&append_to_response=videos,images&include_image_language=en,null`}
				/>

				<CastAvatarSlider
					size={120}
					label="Crew"
					type="crew"
					url={`https://api.themoviedb.org/3/movie/${id}/credits?api_key=${API_KEY}&append_to_response=videos,images&include_image_language=en,null`}
				/>

				{/* <Cast id={id} type={"movie"} /> */}
				<ThumbSlider
					sessionName={id + "similar"}
					title="Similar"
					path="/movie"
					url={`https://api.themoviedb.org/3/movie/${id}/similar?api_key=${API_KEY}&language=en-US&page=1`}
				/>
				<ThumbSlider
					sessionName={id + "recommendations"}
					title="Recommendations"
					path="/movie"
					url={`https://api.themoviedb.org/3/movie/${id}/recommendations?api_key=${API_KEY}&language=en-US&page=1`}
				/>
				{companies.length > 0 ? <ProductionCompanies data={companies} /> : null}
			</div>
			{!isPlayingTrailer && !isPlayingMovie && (
				<PlayButton
					fabStyle={{ top: 200 }}
					position={{ vertical: "top", horizontal: "end" }}
					isMobile={isMobile}
					play720={play720}
					play1080={play1080}
					quality={quality}
					setQuality={setQuality}
				/>
			)}
			<YoutubeFAB
				playerConfig={{
					flipPlayer,
					setFlipPlayer,
					setPlayerSelected,
					playerSelected,
				}}
				fabStyle={{
					top: 120,
				}}
				iframStyle={{ width: "100%", height: "40vh" }}
				position={{ vertical: "top", horizontal: "end" }}
			/>
			{/* <ModalVideoPlayer
        title={title}
        id={id}
        src={src}
        openModal={openModal}
        setOpenModal={setOpenModal}
        setOpenLoading={setOpenLoading}
        otherCallbacks={playerCleanUp}
        onDismiss={() => {}}
        onPresent={() => {}}
        extraObject={{ youtubeVideo, flipPlayer }}
      /> */}
		</Page>
	);

	async function setHDSource(
		stream_1080: string,
		setSrc: React.Dispatch<React.SetStateAction<string>>,
		setOpenLoading: React.Dispatch<React.SetStateAction<boolean>>
	) {
		setOpenLoading(true);
		const headers = new Headers({
			"Content-Type": "application/json",
		});
		const body: RequestInit["body"] = JSON.stringify({
			type: "movies",
			tmdb_id: id,
		});

		let url: any = await fetchMp4Url(stream_1080, headers, body);
		if (url !== "" || url !== undefined || url !== null) {
			setSrc(url);
			console.log(url);
		} else {
			console.log("retry");
			// await setTimeout(async () => {
			// 	let url: any = await fetchMp4Url(stream_1080, headers, body);
			// 	if (url !== "" || url !== undefined || url !== null) {
			// 		setSrc(url);
			// 		console.log(url);
			// 	} else {
			// 		console.log("retry 2");
			// 		await setTimeout(async () => {
			// 			let url: any = fetchMp4Url(stream_1080, headers, body);
			// 			if (url !== "") setSrc(url);
			// 		}, 10000);
			// 	}
			// }, 10000);
		}
	}

	async function fetchMp4Url(
		stream_1080: string,
		headers: Headers,
		body: BodyInit | null | undefined
	) {
		return await fetch(stream_1080, {
			method: "POST",
			headers,
			body,
		})
			.then((r) => r.json())
			.then((r) => r.url)
			.catch((err) => error);
	}

	function setSDSource(
		stream_720: string,
		setSrc: React.Dispatch<React.SetStateAction<string>>,
		setOpenLoading: React.Dispatch<React.SetStateAction<boolean>>
	) {
		// setFlipPlayer(true);
		setOpenLoading(true);
		const headers = new Headers({
			"Content-Type": "application/json",
		});
		const body = JSON.stringify({
			type: "movies",
			tmdb_id: id,
		});
		fetch(stream_720, {
			method: "POST",
			headers,
			body,
		})
			.then((r) => r.json())
			.then((r) => {
				setSrc(r?.url /* .replace("/download/", "/stream/") */);
				console.log(r);
			});

		// getJson(stream_720)
		//   .then((r) => r)
		//   .then((r) =>
		//     setSrc(r?.stream_url /* .replace("/download/", "/stream/") */)
		//   );
	}
};

export default MovieDetails;
