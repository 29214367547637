/* eslint-disable */
import React, { FC, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import {
  IonIcon,
  IonCard,
  IonCardSubtitle,
  IonCardTitle,
  IonFab,
  IonFabButton,
  isPlatform,
} from "@ionic/react";
import { closeOutline, logoYoutube, star } from "ionicons/icons";
import moment from "moment";
import { ShowCardStyleProps, ShowsStyles } from "../../app/interfaces";
import { openYoutubeVideo, createMarkUp, doGetPromise } from "../../util";

export const ShowCard: FC<{
  i: number;
  id: string;
  name: string;
  backdrop_path?: string;
  poster_path: string;
  first_air_date: string;
  vote_average: number;
  overview?: string;
  style: ShowsStyles;
  ShowCardStyle?: ShowCardStyleProps;
}> = ({
  i,
  id,
  name,
  poster_path,
  first_air_date,
  vote_average,
  style,
  overview,
  backdrop_path,
  ShowCardStyle,
}): JSX.Element | null => {
  const history = useHistory();

  return poster_path !== "" || backdrop_path !== "" ? (
    ShowCardStyle === "grid" ? (
      /* CARD SHOW GRID TEMPLATE */
      <GridCard
        i={i}
        history={history}
        id={id}
        style={style}
        ShowCardStyle={ShowCardStyle}
        poster_path={poster_path}
        name={name}
        first_air_date={first_air_date}
        vote_average={vote_average}
      />
    ) : ShowCardStyle === "card" ? (
      /* CARD SHOW CARD TEMPLATE */
      <CardCard
        i={i}
        history={history}
        id={id}
        style={style}
        name={name}
        ShowCardStyle={ShowCardStyle}
        backdrop_path={backdrop_path}
        first_air_date={first_air_date}
        vote_average={vote_average}
        overview={overview}
      />
    ) : null
  ) : null;
};

function CardCard({
  i,
  history,
  id,
  style,
  name,
  ShowCardStyle,
  backdrop_path,
  first_air_date,
  vote_average,
  overview,
}: {
  i: number;
  history: any;
  id: string;
  style: ShowsStyles;
  name: string;
  ShowCardStyle: ShowCardStyleProps;
  backdrop_path: string | undefined;
  first_air_date: string;
  vote_average: number;
  overview: string | undefined;
}): JSX.Element | null {
  const [changePlayer, setChangePlayer] = useState(false);
  const [error, setError] = useState("");
  const [youtubeVideo, setYoutubeVideo] = useState("");
  const [key, setKey] = useState("");
  let api_key = process.env.REACT_APP_TMDB_API_KEY;

  let isMobile = useMemo(
    () => isPlatform("android") || isPlatform("ios") || isPlatform("ipad"),
    []
  );

  let url = `https://api.themoviedb.org/3/tv/${id}/videos?api_key=${api_key}&language=en-US`;

  let html = `<iframe src="${youtubeVideo}" allowfullscreen style="width:100%;height:26vmax;max-height: 35vh;border-radius: 15px" frameborder="0" />`;

  useEffect(() => {
    getYoutubeTrailers(url, setKey);
  }, [id]);

  useEffect(() => {
    if (key) {
      if (isPlatform("desktop") || isPlatform("mobileweb")) {
        setYoutubeVideo(
          `https://www.youtube.com/embed/${key}?autoplay=1&mute=0`
        );
      }
    }
  }, [key]);

  // function openYoutubeVideo(key: string) {
  //   let youtube: YoutubeVideoPlayerOriginal = YoutubeVideoPlayer;
  //   youtube.openVideo(key);
  // }

  async function getYoutubeTrailers(url: string, setKey?: any) {
    await doGetPromise({
      url: url,
    })
      .then((res) => {
        return res.results[0] || res.results[1];
      })
      .then((res) => {
        setKey(res.key);
      })
      .catch((err) => setError(err));
  }

  return (
    <IonCard className="clickable_hover" key={i} style={style.showCard}>
      <IonFab
        vertical="top"
        horizontal="end"
        style={{ width: "60px", height: "60px" }}
      >
        <IonFabButton
          style={{ width: "60px", height: "60px" }}
          onClick={() => {
            if (!isMobile) {
              setChangePlayer(!changePlayer);
            } else {
              openYoutubeVideo(key);
            }
          }}
        >
          <IonIcon
            style={{ fontSize: "30px" }}
            icon={!changePlayer ? logoYoutube : closeOutline}
          />
        </IonFabButton>
      </IonFab>
      <div
        onClick={(e) => {
          e.preventDefault();
          history.push(`/tv/${id}`);
        }}
      >
        <IonCardTitle className="movieCardTitle" style={style.showTitle}>
          {name}
        </IonCardTitle>

        {!changePlayer ? (
          <img
            style={style.showImg}
            src={
              `https://image.tmdb.org/t/p/${
                ShowCardStyle === "grid" ? "w500" : "w780"
              }/` + backdrop_path
            }
            alt={id.toString()}
          />
        ) : youtubeVideo !== "" ? (
          <div id="html" dangerouslySetInnerHTML={createMarkUp(html)} />
        ) : null}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h6 style={{ marginRight: "15%" }}>
            {moment(first_air_date).format("MMM DD, YYYY")}
          </h6>
          <h6 style={{ color: "gold", marginLeft: "15%" }}>
            <IonIcon icon={star} />
            {vote_average}
          </h6>
        </div>
        <div
          style={{
            // color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          {overview}
        </div>
        {/* </div>
      </div> */}
      </div>
    </IonCard>
  );
}

function GridCard({
  i,
  history,
  id,
  style,
  ShowCardStyle,
  poster_path,
  name,
  first_air_date,
  vote_average,
}: {
  i: number;
  history: any;
  id: string;
  style: any;
  ShowCardStyle: any;
  poster_path: string;
  name: string;
  first_air_date: string;
  vote_average: number;
}): JSX.Element | null {
  return (
    <IonCard
      className="clickable_hover"
      key={i}
      onClick={(e) => {
        e.preventDefault();
        history.push(`/tv/${id}`);
      }}
      style={style.showCard}
    >
      <img
        style={style.showImg}
        src={
          `https://image.tmdb.org/t/p/${
            ShowCardStyle === "grid" ? "w780" : "w1280"
          }/` + poster_path
        }
        alt={id.toString()}
      />
      <div style={style.showTitle}>
        <IonCardSubtitle>{name}</IonCardSubtitle>
      </div>
      <IonCardSubtitle style={style.showDate}>
        {moment(first_air_date).format("MMM DD, YYYY")}
      </IonCardSubtitle>

      <IonCardSubtitle style={style.showRating}>
        <IonIcon icon={star} />
        {vote_average}
      </IonCardSubtitle>
    </IonCard>
  );
}
