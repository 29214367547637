import React from "react";
import { Page } from "../components";

type Props = {};

const Terms = (props: Props) => {
	return (
		<Page title="Terms and Conditions">
			<div
				style={{
					padding: "2%",
				}}
			>
				<h1>Terms and Conditions</h1>

				<p>Effective Date: August 1, 2019</p>

				<h2>Welcome to the iWatchTV App</h2>
				<p>
					These Terms and Conditions ("Terms") govern your use of the iWatchTV
					App (the "App"). By accessing or using the App, you agree to comply
					with and be bound by these Terms. If you do not agree to these Terms,
					please do not use the App.
				</p>

				<h3>1. Use of the App</h3>
				<p>
					<strong>a. Age Restriction:</strong> You must be at least 18 years old
					to use the App. If you are under 18, you may only use the App with the
					consent and supervision of a parent or legal guardian.
				</p>
				<p>
					<strong>b. Acceptable Use:</strong> You agree to use the App in a
					lawful and responsible manner. You may not use the App for any illegal
					or unauthorized purpose. You must not violate any applicable laws,
					rules, or regulations while using the App.
				</p>
				<p>
					<strong>c. User Accounts:</strong> Some features of the App may
					require you to create a user account. You are responsible for
					maintaining the security and confidentiality of your account
					credentials. You must notify us immediately of any unauthorized use of
					your account.
				</p>

				<h3>2. Content</h3>
				<p>
					<strong>a. Ownership:</strong> All content provided through the App,
					including text, graphics, images, audio, and video, is owned by
					iWatchTV or its licensors and is protected by copyright and other
					intellectual property laws.
				</p>
				<p>
					<strong>b. User-Generated Content:</strong> You may have the
					opportunity to submit user-generated content to the App, such as
					comments or feedback. By submitting such content, you grant iWatchTV a
					non-exclusive, worldwide, royalty-free license to use, reproduce,
					modify, adapt, publish, and distribute the content in any media.
				</p>

				<h3>3. Privacy</h3>
				<p>
					Our collection and use of your personal information are governed by
					our <a href="privacy">Privacy Policy</a>. By using the
					App, you consent to the practices described in the Privacy Policy.
				</p>

				<h3>4. Changes to the App</h3>
				<p>
					We may update or change the App's features or functionality at any
					time without notice. We are not responsible for any loss of data or
					disruption in service resulting from such updates or changes.
				</p>

				<h3>5. Termination</h3>
				<p>
					We reserve the right to terminate or suspend your access to the App,
					in whole or in part, at our sole discretion and without notice, for
					any violation of these Terms or for any other reason.
				</p>

				<h3>6. Disclaimers</h3>
				<p>
					<strong>a. No Warranty:</strong> The App is provided "as is" and "as
					available" without any warranties of any kind, whether express or
					implied. We do not guarantee that the App will be error-free or
					uninterrupted.
				</p>
				<p>
					<strong>b. Limitation of Liability:</strong> To the fullest extent
					permitted by law, iWatchTV shall not be liable for any direct,
					indirect, incidental, special, consequential, or punitive damages
					arising out of or in connection with your use of the App.
				</p>

				<h3>7. Governing Law</h3>
				<p>
					These Terms are governed by and construed in accordance with the laws
					of [Your Jurisdiction]. Any disputes arising from or related to these
					Terms or your use of the App shall be subject to the exclusive
					jurisdiction of the courts located within [Your Jurisdiction].
				</p>

				<h3>8. Contact Us</h3>
				<p>
					If you have any questions or concerns about these Terms, please
					contact us at <a href="mailto:thrifts-ingot0j@icloud.com" type="email">thrifts-ingot0j@icloud.com.</a>
				</p>

				<p>
					Thank you for using the iWatchTV App. We hope you enjoy your
					experience!
				</p>
			</div>
		</Page>
	);
};

export default Terms;
