/* eslint-disable */
import {
  ICompanyList,
  IGenreList,
  ISortList,
  MovieCardStyleProps,
} from "./interfaces";

const coinImpKeys: { public: string; private: string } = {
  public: "1e7fc5138a1c4a23f14303ed143233fdd628243fdd34a9214ca18cfc2dade1e8",
  private: "d2195d4d8203d69005b10d4d1e93d5eab4e09e5b8f1916c01e3f130789259d67",
};

export const MoviePageStyle = (CardStyle: MovieCardStyleProps) => {
  return CardStyle === "grid"
    ? {
        movieCard: {
          display: "inline-block",
          textAlign: "center",
          // width: "auto",
          // padding: '-20px',
          marginBottom: "5px",
          marginTop: "10px",
          marginRight: "5px",
          marginLeft: "5px",
          borderRadius: 10,
        },
        movieTitle: {
          width: "13vmax",
          clear: "both",
          // display: 'inline-block',
          overflowX: "hidden",
          overflowY: "hidden",
          whiteSpace: "nowrap",
        },
        movieImg: {
          borderRadius: 10,
          // minHeight: "15vmax",
          // width: "26vw",
          height: "20vmax",
          objectFit: "cover",
        },
        movieDate: {
          // maxWidth: 'max-content'
        },
        movieRating: {
          color: "gold",
        },
      }
    : CardStyle === "card"
    ? {
        movieCard: {
          display: "inline-block",
          height: "auto",
        },
        movieImg: {
          borderRadius: 10,
          // minHeight: "50vh",
          width: "100vw",
          // height: "50vh",
          objectFit: "cover",
        },
        movieDate: {
          // display: "none",
        },
        movieRating: {
          // display: "none",
          color: "gold",
        },
      }
    : {
        movieCard: {},
        movieDate: {},
        movieImg: {},
        movieRating: {},
      };
};

export const companiesList: ICompanyList[] = [
  { id: 1, name: "Lucasfilm" },
  { id: 2, name: "Walt Disney Pictures" },
  { id: 3, name: "Pixar" },
  { id: 13252, name: "Marvel Animation" },
  { id: 7505, name: "Marvel Entertainment" },
  { id: 420, name: "Marvel Studios" },
  { id: 429, name: "DC Comics" },
  { id: 9993, name: "DC Entertainment" },
  { id: 4, name: "Paramount" },
  { id: 33, name: "Universal Pictures" },
  { id: 5, name: "Columbia Pictures" },
  { id: 14, name: "Miramax" },
  { id: 7, name: "DreamWorks Pictures" },
  { id: 21, name: "MGM" },
  { id: 35, name: "Lions Gate Films" },
  { id: 128, name: "Time Warner" },
  { id: 174, name: "Warner Bros. Pictures" },
  { id: 17, name: "Warner Bros. Entertainment" },
  { id: 32, name: "Buena Vista Pictures" },
  { id: 25, name: "20th Century Fox" },
  { id: 34, name: "Sony Pictures" },
  { id: 12, name: "New Line Cinema" },
  { id: 58, name: "Sony Pictures Classics" },
  { id: 177, name: "Columbia TriStar" },
  { id: 134, name: "Rogue Pictures" },
  { id: 288, name: "BBC Films" },
  { id: 307, name: "IFC Films" },
];

export const genresList: IGenreList[] = [
  {
    id: 28,
    name: "Action",
  },
  {
    id: 12,
    name: "Adventure",
  },
  {
    id: 16,
    name: "Animation",
  },
  {
    id: 35,
    name: "Comedy",
  },
  {
    id: 80,
    name: "Crime",
  },
  {
    id: 99,
    name: "Documentary",
  },
  {
    id: 18,
    name: "Drama",
  },
  {
    id: 10751,
    name: "Family",
  },
  {
    id: 14,
    name: "Fantasy",
  },
  {
    id: 36,
    name: "History",
  },
  {
    id: 27,
    name: "Horror",
  },
  {
    id: 10402,
    name: "Music",
  },
  {
    id: 9648,
    name: "Mystery",
  },
  {
    id: 10749,
    name: "Romance",
  },
  {
    id: 878,
    name: "Science Fiction",
  },
  {
    id: 10770,
    name: "TV Movie",
  },
  {
    id: 53,
    name: "Thriller",
  },
  {
    id: 10752,
    name: "War",
  },
  {
    id: 37,
    name: "Western",
  },
];

export const sortsList: ISortList[] = [
  { id: 2, name: "Popular 🔻", value: "popularity.desc" },
  { id: 1, name: "Popular 🔺", value: "popularity.asc" },
  { id: 6, name: "Revenue 🔻", value: "revenue.desc" },
  { id: 5, name: "Revenue 🔺", value: "revenue.asc" },
  { id: 12, name: "IMDB Rating 🔻", value: "vote_average.desc" },
  { id: 11, name: "IMDB Rating 🔺", value: "vote_average.asc" },
  { id: 14, name: "Vote Count 🔻", value: "vote_count.desc" },
  { id: 13, name: "Vote Count 🔺", value: "vote_count.asc" },
  { id: 4, name: "Date 🔻", value: "release_date.desc" },
  { id: 3, name: "Date 🔺", value: "release_date.asc" },
  { id: 9, name: "A-Z", value: "original_title.asc" },
  { id: 10, name: "Z-A", value: "original_title.desc" },
];
