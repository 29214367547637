/* eslint-disable */
import React, { Suspense, useState } from "react";
import Page from "../components/Page";
import ThumbSlider from "../components/Sliders/ThumbSlider";
import { Slider } from "../components/Sliders/Slider";
import { randomNum } from "../util";
import { CastAvatarSlider } from "../components/Sliders/CastAvatarSlider";
import { Spinner } from "../components/Spinner";
import { IonToast } from "@ionic/react";

interface HomeProps {
	title?: string;
}

const Home = ({}: HomeProps) => {
	let API_KEY = process.env.REACT_APP_TMDB_API_KEY;
	const [showToast, setShowToast] = useState(true);
	return (
		<Page title="Home" center={true} showTabbar={true} isNavButtons={true}>
			{/* thrifts-ingot0j@icloud.com */}
			<IonToast
				color="warning"
				isOpen={showToast}
				onDidDismiss={() => setShowToast(false)}
				message="If you have any requests or suggestions, please email us at thrifts-ingot0j@icloud.com. Thank you!"
				duration={10000}
				keyboardClose={true}
				// position="top"
			/>
			<Suspense fallback={<Spinner />}>
				<Slider
					url={`https://api.themoviedb.org/3/movie/popular?api_key=${API_KEY}&page=${randomNum(
						1,
						1
					)}`}
					path="/movie"
				/>
				<CastAvatarSlider
					size={100}
					label="Popular Today"
					type="cast"
					url={`https://api.themoviedb.org/3/person/popular?api_key=${API_KEY}&language=en-US&page=1`}
				/>
				<ThumbSlider
					title="Streaming Movies"
					path="/movie"
					url={`https://api.themoviedb.org/3/movie/upcoming?api_key=${API_KEY}&page=${randomNum(
						1,
						1
					)}`}
				/>
				<ThumbSlider
					title="Now Playing Movies"
					path="/movie"
					url={`https://api.themoviedb.org/3/movie/now_playing?api_key=${API_KEY}&page=${randomNum(
						1,
						1
					)}`}
				/>
				<Slider
					url={`https://api.themoviedb.org/3/tv/popular?api_key=${API_KEY}&page=${randomNum(
						1,
						3
					)}`}
					path="/tv"
				/>
				<ThumbSlider
					title="Streaming Now"
					path="/tv"
					url={`https://api.themoviedb.org/3/tv/on_the_air?api_key=${API_KEY}&page=${randomNum(
						1,
						1
					)}`}
				/>
				<ThumbSlider
					title="New Episodes Today"
					path="/tv"
					url={`https://api.themoviedb.org/3/tv/airing_today?api_key=${API_KEY}&page=${randomNum(
						1,
						1
					)}`}
				/>
			</Suspense>
		</Page>
	);
};

export default Home;
