/* eslint-disable */
import { IonCard, IonCardSubtitle, IonSpinner } from "@ionic/react";
import React, { Suspense, useEffect, useState } from "react";
import { randomNum, getCast } from "../../util";
import { useHistory } from "react-router";
import ScrollContainer from "react-indiana-drag-scroll";
// import { getCast } from "../../util/getCast";
import { castSliderStyle } from "../../styles/castSliderStyle";
// const ScrollContainer = React.lazy(() => import("react-indiana-drag-scroll"));

const CastMembers: any = (props: { id: string; type: string }) => {
  let api_key = process.env.REACT_APP_TMDB_API_KEY;
  const [state, setState] = useState({
    cast: [],
    crew: [],
    error: false,
  });
  let id = props.id;
  let type: "tv" | "movie" | string = props.type;
  // const url = `https://api.themoviedb.org/3/${type}/${id}/credits?api_key=${api_key}`;
  const history = useHistory();
  let style = castSliderStyle();

  useEffect(() => {
    getCast(id, type).then((res: any) =>
      setState({ ...state, cast: res.data.cast, crew: res.data.crew })
    );
  }, [id]);

  return state.cast
    ? state.cast.map(({ name, id, character, profile_path }) => {
        return profile_path !== "" &&
          profile_path !== null &&
          profile_path !== undefined ? (
          <div key={id}>
            <IonCard>
              <div
                key={id + randomNum(100, 99999)}
                onClick={() => {
                  history.push(`/person/` + id);
                }}
                style={style.thumbCard}
              >
                <div style={style.thumbTitleBox}>
                  <h6 style={style.thumbTitleText}>{name}</h6>
                </div>
                <img
                  style={style.thumbImg}
                  src={"https://image.tmdb.org/t/p/w200/" + profile_path}
                  alt={id}
                />
                <div style={style.thumbCharacter}>
                  <p>{character}</p>
                </div>
              </div>
            </IonCard>
          </div>
        ) : null;
      })
    : null;
};

const Cast = ({ id, type }: { id: string; type: string }) => {
  return (
    <div
      style={
        {
          /* marginBottom: "-3vh", marginTop: "1vh" */
        }
      }
    >
      <IonCardSubtitle className="ion-padding">Cast</IonCardSubtitle>
      {id !== "" ? (
        <Suspense
          fallback={
            <div style={{ textAlign: "center" }}>
              <IonSpinner />
            </div>
          }
        >
          <ScrollContainer
            nativeMobileScroll={true}
            style={{ height: "max-content" }}
            className="ion-padding"
          >
            <div className="scroller" style={{ marginTop: "-3vh" }}>
              <CastMembers id={id} type={type} />
            </div>
          </ScrollContainer>
        </Suspense>
      ) : (
        <p>No Cast Available</p>
      )}
    </div>
  );
};

export default Cast;
