/* eslint-disable */
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { IonIcon, IonCard, IonCardSubtitle, IonCardTitle } from "@ionic/react";
import { star } from "ionicons/icons";
import moment from "moment";
import { MovieCardStyleProps, MoviesStyles } from "../../app/interfaces";
import { doGetPromise, createMarkUp } from "../../util";
import { YoutubeFAB } from "../Buttons/YoutubeFAB";
import { useYoutubeTrailer } from "../../util/useYoutubeTrailer";

export const MovieCard: FC<MovieCardProps> = ({
  i,
  id,
  title,
  poster_path,
  release_date,
  vote_average,
  style,
  overview,
  backdrop_path,
  MovieCardStyle,
}): JSX.Element | null => {
  const history = useHistory();
  return MovieCardStyle === "grid" ? (
    poster_path ? (
      /* CARD MOVIE GRID TEMPLATE */
      <GridCard
        i={i}
        history={history}
        id={id}
        style={style}
        MovieCardStyle={MovieCardStyle}
        poster_path={poster_path}
        title={title}
        release_date={release_date}
        vote_average={vote_average}
      />
    ) : null
  ) : MovieCardStyle === "card" ? (
    backdrop_path ? (
      /* CARD MOVIE CARD TEMPLATE */
      <CardCard
        i={i}
        history={history}
        id={id}
        style={style}
        title={title}
        MovieCardStyle={MovieCardStyle}
        backdrop_path={backdrop_path}
        release_date={release_date}
        vote_average={vote_average}
        overview={overview}
      />
    ) : null
  ) : null;
};

const CardCard: FC<CardCardProps> = ({
  i,
  history,
  id,
  style,
  title,
  MovieCardStyle,
  backdrop_path,
  release_date,
  vote_average,
  overview,
}): JSX.Element | null => {
  const [flipPlayer, setFlipPlayer] = useState(false);
  const [error, setError] = useState(null);
  const { trailer, trailers } = useYoutubeTrailer(id);
  // const [key, setKey] = useState("");
  const [youtubeVideo, setYoutubeVideo] = useState("");
  const [playerSelected, setPlayerSelected] = useState("");
  // let api_key = process.env.REACT_APP_TMDB_API_KEY;
  // let url = `https://api.themoviedb.org/3/movie/${id}/videos?api_key=${api_key}&language=en-US`;

  // useEffect(() => {
  //   getYoutubeTrailers(url, setKey);
  // }, [id]);

  // useEffect(() => {
  //   if (key) {
  //     // if (isPlatform("desktop") || isPlatform("mobileweb")) {
  //     setYoutubeVideo(
  //       `https://www.youtube.com/embed/${key}?autoplay=1&mute=0&playsinline=1`
  //     );
  //     // }
  //   }
  // }, [key]);

  // async function getYoutubeTrailers(url: string, setKey?: any) {
  //   await doGetPromise({
  //     url: url,
  //   })
  //     .then((res) => {
  //       if (res.results[0]) {
  //         return res.results[0];
  //       }
  //     })
  //     .then((res) => {
  //       setKey(res.key);
  //     })
  //     .catch((err) => setError(err));
  // }

  let TrailerHtml = `<iframe src="${trailer}" allowfullscreen style="width:100%;height:26vmax;max-height: 35vh;border-radius: 15px" frameborder="0"  />`;

  return (
    <IonCard className="clickable_hover" key={i} style={style.movieCard}>
      <YoutubeFAB
        // videoKey={key}
        playerConfig={{
          setFlipPlayer: setFlipPlayer,
          flipPlayer: flipPlayer,
          setPlayerSelected: setPlayerSelected,
          playerSelected: playerSelected,
        }}
        // openYoutubeVideo={openYoutubeVideo}
        fabStyle={{
          width: "60px",
          height: "60px",
          fontSize: "30px",
          top: "60px",
        }}
        iframStyle={{ width: "100%", height: "26vmax" }}
        position={{ vertical: "top", horizontal: "end" }}
      />
      <div
        onClick={(e) => {
          e.preventDefault();
          history.push(`/movie/${id}`);
        }}
      >
        <IonCardTitle style={style.movieTitle}>{title}</IonCardTitle>
        <div>
          {!flipPlayer ? (
            <img
              style={style.movieImg}
              src={`https://image.tmdb.org/t/p/original/` + backdrop_path}
              alt={id.toString()}
            />
          ) : youtubeVideo !== "" ? (
            <div
              id="html"
              dangerouslySetInnerHTML={createMarkUp(TrailerHtml)}
            />
          ) : // <div id="youtube-player"></div>
          null}
        </div>
        <div
          style={{
            padding: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IonCardSubtitle style={{ marginRight: "15%" }}>
            {moment(release_date).format("MMM DD, YYYY")}
          </IonCardSubtitle>
          <IonCardSubtitle style={{ color: "gold", marginLeft: "15%" }}>
            <IonIcon icon={star} />
            {vote_average}
          </IonCardSubtitle>
        </div>
        <div
          style={{
            width: "inherit",
            // width:'calc(-100vw / 2 + 100% / 2)',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            marginRight: "2vw",
            marginLeft: "2vw",
          }}
        >
          <p>{overview}</p>
        </div>
      </div>
    </IonCard>
  );
};

const GridCard: FC<GridCardProps> = ({
  i,
  history,
  id,
  style,
  MovieCardStyle,
  poster_path,
  title,
  release_date,
  vote_average,
}): JSX.Element | null => {
  return (
    <IonCard
      className="clickable_hover"
      key={i}
      onClick={(e) => {
        e.preventDefault();
        history.push(`/movie/${id}`);
      }}
      style={style.movieCard}
    >
      <img
        style={style.movieImg}
        src={
          `https://image.tmdb.org/t/p/${
            MovieCardStyle === "grid" ? "w780" : "w1280"
          }/` + poster_path
        }
        alt={id.toString()}
      />
      <div style={style.movieTitle}>
        <IonCardSubtitle>{title}</IonCardSubtitle>
      </div>
      <IonCardSubtitle style={style.movieDate}>
        {moment(release_date).format("MMM DD, YYYY")}
      </IonCardSubtitle>

      <IonCardSubtitle style={style.movieRating}>
        <IonIcon icon={star} />
        {vote_average}
      </IonCardSubtitle>
    </IonCard>
  );
};

interface GridCardProps {
  i: number;
  history: any;
  id: string;
  style: any;
  MovieCardStyle: any;
  poster_path: string;
  title: string;
  release_date: string;
  vote_average: number;
}
interface CardCardProps {
  i: number;
  history: any;
  id: string;
  style: MoviesStyles;
  title: string;
  MovieCardStyle: MovieCardStyleProps;
  backdrop_path: string | undefined;
  release_date: string;
  vote_average: number;
  overview: string | undefined;
}

interface MovieCardProps {
  i: number;
  id: string;
  title: string;
  backdrop_path?: string;
  poster_path: string;
  release_date: string;
  vote_average: number;
  overview?: string;
  style: MoviesStyles;
  MovieCardStyle?: MovieCardStyleProps;
}
