import { Plugins } from "@capacitor/core";
import "@capacitor-community/http";

export const getWatchProviders = async (id: string, type: "tv" | "movie") => {
  let api_key = process.env.REACT_APP_TMDB_API_KEY;
  const { Http } = Plugins;
  const ret = await Http.request({
    method: "GET",
    url: ` https://api.themoviedb.org/3/${type}/${id}/watch/providers?api_key=${api_key}`,
  });
  return ret;
};
