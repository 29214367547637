import { CSSProperties } from "react";

export const movieInfoStyle: {
  movieInfo?: CSSProperties;
  BackDropBox?: CSSProperties;
  movieInfoOverview?: CSSProperties;
  moviePoster?: CSSProperties;
  movieBackDrop?: CSSProperties;
  movieInfoText?: CSSProperties;
} = {
  movieInfo: {
    textAlign: "center",
  },
  movieBackDrop: {
    height: "40vh",
    width: "100%",
    objectFit: "cover",
    position: "relative",
    borderRadius: "15px",
  },
  BackDropBox: {
    marginBottom: "5vh",
    height: "auto",
    width: "auto",
    objectFit: "fill",
  },
  moviePoster: {
    height: "25vh",
    position: "relative",
    marginTop: "-23vh",
    objectFit: "contain",
    borderRadius: "10px",
    width: "auto",
  },
  movieInfoText: {
    // paddingTop: '-40px'
  },
  movieInfoOverview: {
    scrollbarWidth: "none",
    width: "100%",
    maxHeight: "150px",
    marginBottom: "8%",
  },
};
